import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import FavoriteSearch from 'types/resources/favoriteSearch';
import { FavoriteSearchParams } from 'store/sellerFavoriteSearchesSlice';

export default {
  index(): Promise<{ searches: FavoriteSearch[] }> {
    const url = apiRoutes.apiWebV1UserSellerFavoriteSearchesPath();

    return Fetcher.get(url);
  },
  create(data: FavoriteSearchParams): Promise<void> {
    const url = apiRoutes.apiWebV1UserSellerFavoriteSearchesPath();

    return Fetcher.post(url, data);
  },
  delete(id: ID): Promise<void> {
    const url = apiRoutes.apiWebV1UserSellerFavoriteSearchPath(id);

    return Fetcher.delete(url);
  },
};
