import { FC } from 'react';
import { SxProps } from '@material-ui/system';
import { authRoutes } from 'routes';
import { Button, Theme } from '@material-ui/core';
import { UserType } from 'enums/UserType';
import { SocialButton } from 'enums/SocialButton';
import Google from 'components/Icons/Google';
import FacebookRounded from 'components/Icons/FacebookRounded';
import Box from 'components/Box';

import styles from './styles';

type SocialAuthButtonProps = {
  sx?: SxProps<Theme>;
  buttonText: string;
  buttonType: SocialButton;
  type?: UserType;
  fullWidth?: boolean;
};

const SocialAuthButton: FC<SocialAuthButtonProps> = (props): JSX.Element => {
  const { buttonText, buttonType, type, fullWidth, ...rest } = props;

  const authenticityToken = () => {
    const token = document.querySelector('meta[name="csrf-token"]');
    return token ? token.content : null;
  };

  const renderIcon = () => {
    switch (buttonType) {
      case SocialButton.google: {
        return <Google />;
      }
      case SocialButton.facebook: {
        return <FacebookRounded />;
      }
      default: {
        return null;
      }
    }
  };

  const getAction = () => {
    switch (buttonType) {
      case SocialButton.google: {
        return authRoutes.authGoogleOauth2Path();
      }
      case SocialButton.facebook: {
        return authRoutes.authFacebookPath();
      }
      default: {
        return null;
      }
    }
  };

  return (
    <form method="post" action={getAction()}>
      <Button type="submit" fullWidth={fullWidth} variant="social" {...rest}>
        {renderIcon()}
        <Box sx={styles.text}>{buttonText}</Box>
      </Button>
      <input type="hidden" name="authenticity_token" value={authenticityToken()} />
      <input type="hidden" name="type" value={type || ''} />
    </form>
  );
};

export default SocialAuthButton;
