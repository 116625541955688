import { ClientsImportRecordType } from 'types/importRecord';

export const fullName = (record: ClientsImportRecordType): string => {
  return `${record.firstName} ${record.lastName}`;
};

export const formatPhoneNumber = (user: ClientsImportRecordType): string => {
  const { phoneNumber } = user;

  if (phoneNumber) {
    const cleaned = `${phoneNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    return match ? `+1 (${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
  }

  return '–';
};
