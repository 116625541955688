import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTabType = {
  defaultProps: ComponentsProps['MuiTab'];
  styleOverrides: ComponentsOverrides['MuiTab'];
};

const MuiTab: MuiTabType = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      fontSize: 21,
      lineHeight: '24px',
      padding: '16px 32px',
      color: '#868985',
      borderBottom: '2px solid #868985',
    },
  },
  defaultProps: {},
};

export default MuiTab;
