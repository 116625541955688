/* eslint-disable no-param-reassign */
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import Match from 'types/resources/match';
import { Meta } from 'types/meta';
import SearchMatchesRepository from 'repositories/Seller/Search/MatchesRepository';
import PropertyMatchesRepository from 'repositories/Seller/Property/MatchesRepository';
import MatchesRepository from 'repositories/Seller/MatchesRepository';
import { SearchMatchesFiltersQueryParams } from 'forms/searchMatchesFiltersForm';

export type SellerMatchesSliceStateType = {
  matches: {
    data: Match[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
  match: {
    data: Match | null;
    fetchStatus: FetchStatus;
  };
};

export type SellerMatchesSliceActionsType = {
  loadSearchMatches: (id: ID, params: SearchMatchesFiltersQueryParams) => void;
  loadPropertyMatches: (id: ID, params: SearchMatchesFiltersQueryParams) => void;
  loadMatch: (id: ID) => void;
  resetMatches: () => void;
  createMatchesForSearch: (id: ID) => void;
  createMatchesForProperty: (id: ID) => void;
};

const initialState: SellerMatchesSliceStateType = {
  matches: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
  match: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const SellerMatchesSlice = createSlice({
  name: 'sellerMatches',
  initialState,
  reducers: {
    loadMatchesStart: (state): void => {
      state.matches.fetchStatus = FetchStatus.pending;
    },
    loadMatchesFinish: (state, { payload }): void => {
      const { matches, meta } = payload;

      state.matches.fetchStatus = FetchStatus.fulfilled;
      state.matches.data = matches;
      state.matches.meta = meta;
    },
    loadMatchesFail: (state): void => {
      state.matches.fetchStatus = FetchStatus.failed;
    },
    loadMatchStart: (state): void => {
      state.match.fetchStatus = FetchStatus.pending;
    },
    loadMatchFinish: (state, { payload }): void => {
      const { match } = payload;

      state.match.fetchStatus = FetchStatus.fulfilled;
      state.match.data = match;
    },
    loadMatchFail: (state): void => {
      state.match.fetchStatus = FetchStatus.failed;
    },
    resetMatches: (): SellerMatchesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = SellerMatchesSlice;

export const useSellerMatchesActions = (): SellerMatchesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadSearchMatches = async (id: ID, params: SearchMatchesFiltersQueryParams) => {
    dispatch(actions.loadMatchesStart());

    try {
      const matches = await SearchMatchesRepository.index(id, params);
      dispatch(actions.loadMatchesFinish(matches));
    } catch (error: unknown) {
      dispatch(actions.loadMatchesFail());
    }
  };

  const loadPropertyMatches = async (id: ID, params: SearchMatchesFiltersQueryParams) => {
    dispatch(actions.loadMatchesStart());

    try {
      const matches = await PropertyMatchesRepository.index(id, params);
      dispatch(actions.loadMatchesFinish(matches));
    } catch (error: unknown) {
      dispatch(actions.loadMatchesFail());
    }
  };

  const loadMatch = async (matchId: ID) => {
    dispatch(actions.loadMatchStart());

    try {
      const match = await MatchesRepository.show(matchId);
      dispatch(actions.loadMatchFinish(match));
    } catch (error: unknown) {
      dispatch(actions.loadMatchFail());
    }
  };

  const resetMatches = () => dispatch(actions.resetMatches());

  const createMatchesForSearch = async (id: ID) => {
    await SearchMatchesRepository.create(id);
  };

  const createMatchesForProperty = async (id: ID) => {
    await PropertyMatchesRepository.create(id);
  };

  return {
    loadSearchMatches,
    loadPropertyMatches,
    loadMatch,
    resetMatches,
    createMatchesForSearch,
    createMatchesForProperty,
  };
};

export default SellerMatchesSlice.reducer;
