import { createSlice } from '@reduxjs/toolkit';
import AgentConnectionSearchesRepository from 'repositories/Agent/Connection/SearchRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import ConnectionSearch from 'types/resources/connection/search';

export type AgentConnectionSearchesSliceStateType = {
  agentConnectionSearch: ConnectionSearch | null;
  showAgentConnectionSearch: { fetchStatus: FetchStatus };
};

export type AgentConnectionSearchesSliceActionsType = {
  loadAgentConnectionSearch: (connectionId: ID) => void;
  resetAgentConnectionSearch: () => void;
};

const initialState: AgentConnectionSearchesSliceStateType = {
  agentConnectionSearch: null,
  showAgentConnectionSearch: { fetchStatus: FetchStatus.idle },
};

const AgentConnectionSearchesSlice = createSlice({
  name: 'agentConnectionSearches',
  initialState,
  reducers: {
    loadAgentConnectionSearchStart: (state): void => {
      state.showAgentConnectionSearch.fetchStatus = FetchStatus.pending;
    },
    loadAgentConnectionSearchFinish: (state, { payload }): void => {
      state.showAgentConnectionSearch.fetchStatus = FetchStatus.fulfilled;
      state.agentConnectionSearch = payload;
    },
    loadAgentConnectionSearchFail: (state): void => {
      state.showAgentConnectionSearch.fetchStatus = FetchStatus.failed;
    },
    resetAgentConnectionSearch: (): AgentConnectionSearchesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AgentConnectionSearchesSlice;

export const useAgentConnectionSearchesActions = (): AgentConnectionSearchesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAgentConnectionSearch = async (connectionId: ID) => {
    dispatch(actions.loadAgentConnectionSearchStart());
    try {
      const connectionSearch = await AgentConnectionSearchesRepository.show(connectionId);
      dispatch(actions.loadAgentConnectionSearchFinish(connectionSearch));
    } catch (error: unknown) {
      dispatch(actions.loadAgentConnectionSearchFail());
    }
  };

  const resetAgentConnectionSearch = () => dispatch(actions.resetAgentConnectionSearch());

  return {
    loadAgentConnectionSearch,
    resetAgentConnectionSearch,
  };
};

export default AgentConnectionSearchesSlice.reducer;
