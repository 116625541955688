import Fetcher from 'utils/fetcher';
import { apiRoutes } from 'routes';
import { BudgetRejectParams } from 'forms/budgetRejectForm';

const ProfileRepository = {
  approveBudget(id: ID): Promise<void> {
    const url = apiRoutes.approveApiWebV1AdminUserBuyerBudgetPath(id);

    return Fetcher.put(url, {});
  },
  declineBudget(id: ID, params: BudgetRejectParams): Promise<void> {
    const url = apiRoutes.declineApiWebV1AdminUserBuyerBudgetPath(id);

    return Fetcher.put(url, params);
  },
};

export default ProfileRepository;
