import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTabsType = {
  defaultProps: ComponentsProps['MuiTabs'];
  styleOverrides: ComponentsOverrides['MuiTabs'];
};

const MuiTabs: MuiTabsType = {
  styleOverrides: {
    root: {
      '&.MuiTabs-vertical': {
        '& .MuiTab-root': {
          borderBottom: 'none',
          borderLeft: '2px solid #868985',
          alignItems: 'baseline',
        },
        '& .MuiTabs-indicator': {
          left: 0,
          right: 'unset',
        },
      },
    },
  },
  defaultProps: {},
};

export default MuiTabs;
