import { apiRoutes } from 'routes';
import ConnectionsDashboard from 'types/resources/connectionsDashboard';
import Fetcher from 'utils/fetcher';

export default {
  show(): Promise<{ adminConnectionsDashboard: ConnectionsDashboard }> {
    const url = apiRoutes.apiWebV1AdminConnectionsDashboardPath();
    return Fetcher.get(url);
  },
};
