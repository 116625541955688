import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Search from 'types/resources/search';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';
import { Meta } from 'types/meta';
import { SearchEvent } from 'enums/SearchEvent';

export default {
  index(params: SearchFiltersFormDataParams): Promise<{ searches: Search[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1AdminSearchesPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<Search> {
    const url = apiRoutes.apiWebV1AdminSearchPath(id);

    return Fetcher.get(url);
  },
  archive(id: ID): Promise<void> {
    const url = apiRoutes.apiWebV1AdminSearchPath(id);

    return Fetcher.delete(url);
  },
  changePhase(id: ID, event: SearchEvent): Promise<Search> {
    const params = {
      search: {
        event,
      },
    };
    const url = apiRoutes.changePhaseApiWebV1AdminSearchPath(id);

    return Fetcher.put(url, params);
  },
};
