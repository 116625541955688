import { MatchesStatus } from 'enums/MatchesStatus';
import Property from 'types/resources/property';
import Search from 'types/resources/search';

type MatchEntity = Search | Property;

export const SCORE_SORTING = 'score';
export const CREATION_SORTING = 'created_at';
export const AGENT_MATCH_SORTING = 'is_agent_match';

export const SORTING_OPTIONS = [
  { id: 1, name: 'In my database', value: AGENT_MATCH_SORTING },
  { id: 2, name: 'Score', value: SCORE_SORTING },
];
export const SORTING_OPTIONS_FOR_SELLER = [
  { id: 1, name: 'Creation date', value: CREATION_SORTING },
  { id: 2, name: 'Score', value: SCORE_SORTING },
];

export const isMatchesIdle = (object: MatchEntity): boolean => {
  return object.matchesStatus === MatchesStatus.idle;
};

export const isMatchesInProgress = (object: MatchEntity): boolean => {
  return object.matchesStatus === MatchesStatus.inProgress;
};

export const isMatchesFailed = (object: MatchEntity): boolean => {
  return object.matchesStatus === MatchesStatus.failed;
};

export const isMatchesSuccess = (object: MatchEntity): boolean => {
  return object.matchesStatus === MatchesStatus.success;
};

export const isMatchesFinished = (object: MatchEntity): boolean => {
  return isMatchesSuccess(object) || isMatchesFailed(object);
};

export const isStatusFailed = (status: MatchesStatus): boolean => {
  return status === MatchesStatus.failed;
};

export const isStatusPending = (status: MatchesStatus): boolean => {
  return status === MatchesStatus.inProgress || status === MatchesStatus.idle;
};
