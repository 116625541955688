import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import User from 'types/resources/user';
import { Meta } from 'types/meta';
import BuyersRepository from 'repositories/Agent/BuyersRepository';
import ProfileRepository from 'repositories/Agent/Buyer/ProfileRepository';
import BudgetRepository from 'repositories/Agent/Buyer/BudgetRepository';
import { BuyerProfileFormDataToSubmit } from 'forms/buyerProfileForm';

export type AgentBuyersSliceStateType = {
  buyers: {
    data: User[];
    meta: Meta;
    preQualifiedBuyersCount: number;
    preQualifiedAgentBuyersCount: number;
    fetchStatus: FetchStatus;
  };
  buyer: {
    data: User;
    fetchStatus: FetchStatus;
  };
};

export type AgentBuyersSliceActionsType = {
  loadBuyers: (params?: QueryParams) => void;
  resetBuyers: () => void;
  loadBuyer: (id: ID) => void;
  updateBuyer: (id: ID, params: BuyerProfileFormDataToSubmit) => void;
  removeBuyerFile: (id: ID, fileId: ID) => void;
};

const initialState: AgentBuyersSliceStateType = {
  buyers: {
    data: [],
    meta: null,
    preQualifiedBuyersCount: 0,
    preQualifiedAgentBuyersCount: 0,
    fetchStatus: FetchStatus.idle,
  },
  buyer: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AgentBuyersSlice = createSlice({
  name: 'agentBuyers',
  initialState,
  reducers: {
    loadBuyersStart: (state): void => {
      state.buyers.fetchStatus = FetchStatus.pending;
    },
    loadBuyersFinish: (state, { payload }): void => {
      const { users, meta, preQualifiedBuyersCount, preQualifiedAgentBuyersCount } = payload;
      state.buyers.fetchStatus = FetchStatus.fulfilled;
      state.buyers.data = users;
      state.buyers.meta = meta;
      state.buyers.preQualifiedBuyersCount = preQualifiedBuyersCount;
      state.buyers.preQualifiedAgentBuyersCount = preQualifiedAgentBuyersCount;
    },
    loadBuyersFail: (state): void => {
      state.buyers.fetchStatus = FetchStatus.failed;
    },
    resetBuyers: (): AgentBuyersSliceStateType => {
      return initialState;
    },

    loadBuyerStart: (state): void => {
      state.buyer.fetchStatus = FetchStatus.pending;
    },
    loadBuyerFinish: (state, { payload }): void => {
      const { user } = payload;

      state.buyer.fetchStatus = FetchStatus.fulfilled;
      state.buyer.data = user;
    },
    loadBuyerFail: (state): void => {
      state.buyer.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = AgentBuyersSlice;

export const useAgentBuyersActions = (): AgentBuyersSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadBuyers = async (params?: QueryParams) => {
    dispatch(actions.loadBuyersStart());
    try {
      const buyers = await BuyersRepository.index(params);
      dispatch(actions.loadBuyersFinish(buyers));
    } catch (error: unknown) {
      dispatch(actions.loadBuyersFail());
    }
  };

  const resetBuyers = () => dispatch(actions.resetBuyers());

  const loadBuyer = async (id: ID) => {
    dispatch(actions.loadBuyerStart());
    try {
      const user = await ProfileRepository.show(id);
      dispatch(actions.loadBuyerFinish(user));
    } catch (error: unknown) {
      dispatch(actions.loadBuyerFail());
    }
  };

  const updateBuyer = (id: ID, params: BuyerProfileFormDataToSubmit) => ProfileRepository.update(id, params);

  const removeBuyerFile = (id: ID, fileId: ID) => BudgetRepository.removeFile(id, fileId);

  return {
    loadBuyers,
    resetBuyers,
    loadBuyer,
    updateBuyer,
    removeBuyerFile,
  };
};

export default AgentBuyersSlice.reducer;
