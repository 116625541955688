import { isNil } from 'ramda';
import { appRoutes } from 'routes';
import { withComma, withCurrency } from 'presenters/NumberPresenter';
import { SxProps, Theme } from '@material-ui/system';

export const AVATAR_KEY = 'avatar';

export type AgentDashboardType = {
  key: string;
  title?: string;
  link?: string;
  value?: (value: number) => string;
  clickable?: boolean;
  sx?: SxProps<Theme>;
};

const TOP_RATING_DASHBOARD_KEY = {
  key: 'topRating',
  title: 'Your Top Rating',
  link: appRoutes.profilePath(),
  value: (value: number): string => {
    const formatValue = isNil(value) ? 0 : value;
    return `${formatValue}`;
  },
  clickable: true,
};

const BUYING_POWER_DASHBOARD_KEY = {
  key: 'buyingPower',
  title: 'Buying Power',
  link: appRoutes.browseBuyersPath(),
  value: (value: number): string => withCurrency(value),
  clickable: true,
  sx: { fontSize: '26px' },
};

const CONNECTIONS_DASHBOARD_KEY = {
  key: 'connections',
  title: 'Your Connections',
  link: appRoutes.connectionsPath(),
  value: (value: number): string => withComma(value),
  clickable: true,
};

const PRE_QUILIFIED_BUYERS_DASHBOARD_KEY = {
  key: 'preQualifiedBuyersCount',
  title: 'Pre-qualified buyers with active searches',
  value: (value: number): string => withComma(value),
};

const AVATAR_DASHBOARD_KEY = {
  key: AVATAR_KEY,
};

const SEARCHES_DASHBOARD_KEY = {
  key: 'searches',
  title: 'Active clients searches',
  value: (value: number): string => withComma(value),
};

export const DASHBOARD_KEYS: AgentDashboardType[] = [
  TOP_RATING_DASHBOARD_KEY,
  BUYING_POWER_DASHBOARD_KEY,
  CONNECTIONS_DASHBOARD_KEY,
  PRE_QUILIFIED_BUYERS_DASHBOARD_KEY,
  AVATAR_DASHBOARD_KEY,
  SEARCHES_DASHBOARD_KEY,
];
