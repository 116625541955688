import { Status } from 'enums/Status';
import { ConnectionPhase } from 'enums/ConnectionPhase';
import { UserType } from 'enums/UserType';
import { find, pathOr } from 'ramda';
import Connection from 'types/resources/connection';
import { CloseConnectionStatus } from 'enums/CloseConnectionStatus';

export const CONNECTION_PHASES = [
  { id: ConnectionPhase.browsing, name: 'Browsing' },
  { id: ConnectionPhase.contacted, name: 'Contacted' },
  { id: ConnectionPhase.communicating, name: 'Communicating' },
  { id: ConnectionPhase.waitingForAgentResponse, name: "Waiting for an Agent's response" },
  { id: ConnectionPhase.negotiating, name: 'Negotiating' },
  { id: ConnectionPhase.closed, name: 'Closed' },
];

export const CONNECTION_PHASES_TO_SHOW = [
  { id: ConnectionPhase.browsing, name: 'Browsing' },
  { id: ConnectionPhase.contacted, name: 'Contacted' },
  { id: ConnectionPhase.communicating, name: 'Communicating' },
  { id: ConnectionPhase.waitingForAgentResponse, name: "Waiting for an Agent's response" },
  { id: ConnectionPhase.negotiating, name: 'Negotiating' },
  { id: ConnectionPhase.closed, name: 'Closed' },
];

export const PHASES_FOR_ADMIN = [
  { id: ConnectionPhase.contacted, name: 'Needs an Action' },
  { id: ConnectionPhase.communicating, name: 'Waiting for an Agent' },
  { id: ConnectionPhase.waitingForAgentResponse, name: "Waiting for an Agent's response" },
  { id: ConnectionPhase.rejected, name: 'Rejected' },
  { id: ConnectionPhase.negotiating, name: 'Negotiating' },
  { id: ConnectionPhase.closed, name: 'Closed' },
];

export const CLOSE_OPTIONS = [
  { id: 'reason1', name: 'The Property was sold using other resources' },
  { id: 'reason2', name: 'The Property was taken off the market by a Homeowner (no reason provided)' },
  { id: 'reason3', name: 'The Buyer is no longer interested in this Property (no reason provided)' },
];

export const CONNECTION_COMPLETION_STATUSES = [
  { id: CloseConnectionStatus.success, name: 'Success' },
  { id: CloseConnectionStatus.failed, name: 'Failed' },
];

export const SUCCESSFULL_CLOSING = 'The Property is successfully sold using ZigZago';
export const AGENT_CONTACT_REJECT_REASON = 'Agent rejected connection';

export const SCORE_SORTING = 'score';

export const CREATED_AT_SORTING = 'created_at';

export const SEARCH_PRICE_SORTING = 'search_price_max';

export const AFFILIATION_SORTING = 'affiliation';

export const SORTING_OPTIONS = [{ id: 1, name: 'Match', value: SCORE_SORTING }];

export const SORTING_OPTIONS_FOR_ADMIN = [
  { id: 1, name: 'First contact', value: CREATED_AT_SORTING },
  { id: 2, name: 'Budget', value: SEARCH_PRICE_SORTING },
];

export const isClosed = (connection: Connection): boolean => {
  return connection.phase === ConnectionPhase.closed;
};

export const isContacted = (connection: Connection): boolean => {
  return connection.phase === ConnectionPhase.contacted;
};

export const isCommunicating = (connection: Connection): boolean => {
  return connection.phase === ConnectionPhase.communicating;
};

export const isWaitingForAgentResponse = (connection: Connection): boolean => {
  return connection.phase === ConnectionPhase.waitingForAgentResponse;
};

export const isNegotiating = (connection: Connection): boolean => {
  return connection.phase === ConnectionPhase.negotiating;
};

export const isRejected = (connection: Connection): boolean => {
  return connection.phase === ConnectionPhase.rejected;
};

export const isArchiveShowing = (connection: Connection): boolean => {
  return isClosed(connection) || isRejected(connection);
};

export const isArchived = (connection: Connection): boolean => {
  return connection.status === Status.archived;
};

export const isCloseFailed = (connection: Connection): boolean => {
  return connection.completionStatus === CloseConnectionStatus.failed;
};

const COMUNICATING_PHASE_HELPER_TEXT =
  'Your Connection is sent to Admin. Please, wait for the Agent to be Assigned to the Connection.';

const WAITING_FOR_AGENT_PHASE_HELPER_TEXT =
  'Your Connection is sent to Agent. Please, wait for the Agent to reply to the Connection.';

const NEGOTIATING_PHASE_HELPER_TEXT =
  'Your Connection was assigned to an Agent. Your negotiation will be led by your Agent.';

export const phaseHelperText = (connection: Connection): string => {
  const { phase } = connection;
  switch (phase) {
    case ConnectionPhase.communicating: {
      return COMUNICATING_PHASE_HELPER_TEXT;
    }
    case ConnectionPhase.waitingForAgentResponse: {
      return WAITING_FOR_AGENT_PHASE_HELPER_TEXT;
    }
    case ConnectionPhase.negotiating: {
      return NEGOTIATING_PHASE_HELPER_TEXT;
    }
    default: {
      return null;
    }
  }
};

export const phaseLabel = (connection: Connection, role: UserType): string => {
  const { phase } = connection;
  switch (role) {
    case UserType.admin: {
      const currentType = find((item) => item.id === phase, PHASES_FOR_ADMIN);
      const label = pathOr('–', ['name'], currentType);

      return label;
    }
    default: {
      const currentType = find((item) => item.id === phase, CONNECTION_PHASES);
      const label = pathOr('–', ['name'], currentType);

      return label;
    }
  }
};

export const statusLabel = (connection: Connection): string => {
  const { completionStatus } = connection;
  const currentStatus = find((item) => item.id === completionStatus, CONNECTION_COMPLETION_STATUSES);
  const label = pathOr('–', ['name'], currentStatus);

  return label;
};
