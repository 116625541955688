export default {
  facebookPath: (): string => 'https://www.facebook.com/search/top?q=zigzago',
  instagramPath: (): string => 'https://www.instagram.com/ZigZagoApp/',
  twitterPath: (): string => 'https://twitter.com/zig_zago',
  tiktokPath: (): string => 'https://www.tiktok.com/@zigzagoapp?lang=en',
  helpEmailPath: (): string => 'mailto:help@zigzago.com',
  kellerMortgagePath: (): string =>
    'https://preapproval.kellermortgage.com/?campaign_id=61&partnerid=102100&loan_manager_id=5&aba=off',
  movementMorgagePath: (): string => 'https://www.thekimwintersteam.com/apply',
  facebookPrivacyPolicyPath: (): string => 'https://www.facebook.com/about/privacy/previous',
  googlePrivacyPolicyPath: (): string => 'https://policies.google.com/privacy?hl=en',
  googleAnalyticsPath: (): string => 'https://tools.google.com/dlpage/gaoptout',
  advertisersPracticesPath: (): string => 'http://optout.aboutads.info/#!/',
  helloEmailPath: (): string => 'mailto:hello@zigzago.com',
  zigzagoWebEmailPath: (): string => 'mailto:zigzagoweb@gmail.com',
};
