import { ComponentsOverrides } from '@material-ui/core';

type MuiTabPanelType = {
  styleOverrides: ComponentsOverrides['MuiTabPanel'];
};

const MuiTabPanel: MuiTabPanelType = {
  styleOverrides: {
    root: {
      padding: '32px 0',
    },
  },
};

export default MuiTabPanel;
