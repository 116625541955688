import * as yup from 'yup';

import { REQUIRED_ERROR_TEXT, EMAIL_ERROR_TEXT } from './errorTexts';

export type RecoveryPasswordFormData = {
  email: string;
};

export type RecoveryPasswordFormDataToSubmit = {
  account: RecoveryPasswordFormData;
};

const validationFields = {
  email: yup.string().email(EMAIL_ERROR_TEXT).required(REQUIRED_ERROR_TEXT),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: RecoveryPasswordFormData = {
  email: '',
};

export const attributesToSubmit = (values: RecoveryPasswordFormData): RecoveryPasswordFormDataToSubmit => ({
  account: values,
});
