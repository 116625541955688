import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  logo: {
    '& a': {
      display: 'block',
      height: 64,
    },
  },
};

export default styles;
