import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import Connection from 'types/resources/connection';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';
import { CloseConnectionFormData } from 'forms/closeConnectionForm';

export default {
  index(params: ConnectionFiltersParams): Promise<{ connections: Connection[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1AdminConnectionsPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<Connection> {
    const url = apiRoutes.apiWebV1AdminConnectionPath(id);

    return Fetcher.get(url);
  },
  assignAgent(connectionId: ID, params: { connection: { agentId: ID } }): Promise<void> {
    const url = apiRoutes.assignAgentApiWebV1AdminConnectionPath(connectionId);

    return Fetcher.put(url, params);
  },
  unassignAgent(connectionId: ID): Promise<void> {
    const url = apiRoutes.unassignAgentApiWebV1AdminConnectionPath(connectionId);

    return Fetcher.put(url, {});
  },
  close(id: ID, params: CloseConnectionFormData): Promise<void> {
    const url = apiRoutes.closeApiWebV1AdminConnectionPath(id);

    return Fetcher.put(url, params);
  },
};
