import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import HeatMapDashboardRepository from 'repositories/HeatMapDashboardRepository';
import HeatMapDashboard from 'types/resources/heatMapDashboard';
import { HeatMapFilterParams } from 'forms/heatMapFiltersForm';

export type HeatMapDashboardSliceStateType = {
  dashboard: {
    data: HeatMapDashboard;
    fetchStatus: FetchStatus;
  };
};

export type HeatMapDashboardSliceActionsType = {
  loadHeatMapDashboard: (params: HeatMapFilterParams) => void;
  resetHeatMapDashboard: () => void;
};

const initialState: HeatMapDashboardSliceStateType = {
  dashboard: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const HeatMapDashboardSlice = createSlice({
  name: 'heatMapDashboard',
  initialState,
  reducers: {
    loadHeatMapDashboardStart: (state): void => {
      state.dashboard.fetchStatus = FetchStatus.pending;
    },
    loadHeatMapDashboardFinish: (state, { payload }): void => {
      state.dashboard.fetchStatus = FetchStatus.fulfilled;
      state.dashboard.data = payload;
    },
    loadHeatMapDashboardFail: (state): void => {
      state.dashboard.fetchStatus = FetchStatus.failed;
    },

    resetHeatMapDashboard: (): HeatMapDashboardSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = HeatMapDashboardSlice;

export const useHeatMapDashboardActions = (): HeatMapDashboardSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadHeatMapDashboard = async (params: HeatMapFilterParams) => {
    dispatch(actions.loadHeatMapDashboardStart());

    try {
      const dashboard = await HeatMapDashboardRepository.show(params);
      dispatch(actions.loadHeatMapDashboardFinish(dashboard));
    } catch (error: unknown) {
      dispatch(actions.loadHeatMapDashboardFail());
    }
  };

  const resetHeatMapDashboard = () => dispatch(actions.resetHeatMapDashboard);

  return {
    loadHeatMapDashboard,
    resetHeatMapDashboard,
  };
};

export default HeatMapDashboardSlice.reducer;
