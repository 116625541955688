import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import DashboardInfo from 'types/resources/connection/dashboard';

export default {
  show(): Promise<{ agentConnectionsDashboard: DashboardInfo }> {
    const url = apiRoutes.apiWebV1UserAgentConnectionsDashboardPath();

    return Fetcher.get(url);
  },
};
