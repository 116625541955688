import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { Meta } from 'types/meta';
import Review from 'types/resources/review';
import ReviewsRepository from 'repositories/Admin/ReviewsRepository';
import { AdminReviewFiltersParams } from 'forms/adminReviewFiltersForm';

export type AdminReviewsSliceStateType = {
  adminReviews: {
    data: Review[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
};

export type AdminReviewsSliceActionsType = {
  loadAdminReviews: (params: AdminReviewFiltersParams) => void;
  resetAdminReviews: () => void;
};

const initialState: AdminReviewsSliceStateType = {
  adminReviews: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminReviewsSlice = createSlice({
  name: 'adminReviews',
  initialState,
  reducers: {
    loadAdminReviewsStart: (state): void => {
      state.adminReviews.fetchStatus = FetchStatus.pending;
    },
    loadAdminReviewsFinish: (state, { payload }): void => {
      const { reviews, meta } = payload;

      state.adminReviews.fetchStatus = FetchStatus.fulfilled;
      state.adminReviews.data = reviews;
      state.adminReviews.meta = meta;
    },
    loadAdminReviewsFail: (state): void => {
      state.adminReviews.fetchStatus = FetchStatus.failed;
    },

    resetReviews: (): AdminReviewsSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminReviewsSlice;

export const useAdminReviewsActions = (): AdminReviewsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminReviews = async (params: AdminReviewFiltersParams) => {
    dispatch(actions.loadAdminReviewsStart());
    try {
      const users = await ReviewsRepository.index(params);
      dispatch(actions.loadAdminReviewsFinish(users));
    } catch (error: unknown) {
      dispatch(actions.loadAdminReviewsFail());
    }
  };

  const resetAdminReviews = () => dispatch(actions.resetReviews());

  return {
    loadAdminReviews,
    resetAdminReviews,
  };
};

export default AdminReviewsSlice.reducer;
