/* eslint-disable no-param-reassign */
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import AdminFeedbackDashboard from 'types/resources/adminFeedbackDashboard';
import FeedbackDashboardRepository from 'repositories/Admin/FeedbackDashboardRepository';

export type AdminFeedbackDashboardSliceStateType = {
  adminFeedbackDashboard: {
    data: AdminFeedbackDashboard;
    fetchStatus: FetchStatus;
  };
};

export type AdminFeedbackDashboardSliceActionsType = {
  loadAdminFeedbackDashboard: () => void;
  resetAdminFeedbackDashboard: () => void;
};

const initialState: AdminFeedbackDashboardSliceStateType = {
  adminFeedbackDashboard: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminFeedbackDashboardSlice = createSlice({
  name: 'adminFeedbackDashboard',
  initialState,
  reducers: {
    loadAdminFeedbackDashboardStart: (state): void => {
      state.adminFeedbackDashboard.fetchStatus = FetchStatus.pending;
    },
    loadAdminFeedbackDashboardFinish: (state, { payload }): void => {
      const { adminFeedbackDashboard } = payload;

      state.adminFeedbackDashboard.fetchStatus = FetchStatus.fulfilled;
      state.adminFeedbackDashboard.data = adminFeedbackDashboard;
    },
    loadAdminFeedbackDashboardFail: (state): void => {
      state.adminFeedbackDashboard.fetchStatus = FetchStatus.failed;
    },

    resetAdminFeedbackDashboard: (): AdminFeedbackDashboardSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminFeedbackDashboardSlice;

export const useAdminFeedbackDashboardActions = (): AdminFeedbackDashboardSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminFeedbackDashboard = async () => {
    dispatch(actions.loadAdminFeedbackDashboardStart());
    try {
      const adminFeedbackDashboard = await FeedbackDashboardRepository.show();
      dispatch(actions.loadAdminFeedbackDashboardFinish(adminFeedbackDashboard));
    } catch (error: unknown) {
      dispatch(actions.loadAdminFeedbackDashboardFail());
    }
  };

  const resetAdminFeedbackDashboard = () => dispatch(actions.resetAdminFeedbackDashboard());

  return {
    loadAdminFeedbackDashboard,
    resetAdminFeedbackDashboard,
  };
};

export default AdminFeedbackDashboardSlice.reducer;
