import { find, keys, pathOr, head } from 'ramda';
import { UserType } from 'enums/UserType';
import User from 'types/resources/user';
import { AccountStatus } from 'enums/AccountStatus';
import { UserConfirmationStatus } from 'enums/UserConfirmationStatus';
import { isBlank } from 'utils';

const BUYER_LABEL = 'Buyer';
const SELLER_LABEL = 'Homeowner';
const AGENT_LABEL = 'Agent';
const ADMIN_LABEL = 'Admin';

export const typeLabel = (userType: string): string => {
  if (userType === UserType.seller) return SELLER_LABEL;
  if (userType === UserType.buyer) return BUYER_LABEL;
  if (userType === UserType.agent) return AGENT_LABEL;
  if (userType === UserType.admin) return ADMIN_LABEL;
  return null;
};

export const USER_TYPES_OPTIONS = keys(UserType).map((user) => {
  return {
    id: UserType[user],
    name: typeLabel(UserType[user]),
  };
});

export const REGULAR_USER_TYPES = [
  { id: UserType.buyer, name: UserType.buyer },
  { id: UserType.seller, name: SELLER_LABEL },
  { id: UserType.agent, name: UserType.agent },
];

export const CLIENTS_TYPES: UserType[] = [UserType.buyer, UserType.seller];
export const CLIENTS_TYPES_OPTIONS = [
  { id: UserType.buyer, name: UserType.buyer },
  { id: UserType.seller, name: SELLER_LABEL },
];

export const USER_STATUSES_OPTIONS = [
  { id: AccountStatus.active, name: 'Active' },
  { id: AccountStatus.inactive, name: 'Inactive' },
];

export const CREATED_AT_SORTING = 'created_at';

export const BUDGET_SORTING = 'budget_amount';

export const SELLERS_SORTING_OPTION = {
  id: 1,
  name: 'Date Created',
  value: CREATED_AT_SORTING,
};

export const BUYERS_SORTING_OPTIONS = [
  SELLERS_SORTING_OPTION,
  {
    id: 2,
    name: 'Budget',
    value: BUDGET_SORTING,
  },
];

export const fullName = (user: User): string => {
  return `${user.firstName} ${user.lastName}`;
};

export const shortName = (user: User): string => {
  return `${user.firstName} ${head(user.lastName)}.`;
};

export const email = (user: User): string => {
  return user.contactEmail || user.email;
};

export const initials = (user: User): string => {
  const { firstName, lastName } = user;

  const firstLetter = firstName === null ? '' : firstName.charAt(0);
  const secondLetter = lastName === null ? '' : lastName.charAt(0);

  return `${firstLetter} ${secondLetter}`;
};

export const isAdmin = (user: User): boolean => {
  return user.type === UserType.admin;
};

export const isAgent = (user: User): boolean => {
  return user.type === UserType.agent;
};

export const isBuyer = (user: User): boolean => {
  return user.type === UserType.buyer;
};

export const isAgentOrSeller = (user: User): boolean => {
  return user.type === UserType.agent || user.type === UserType.seller;
};

export const isSeller = (user: User): boolean => {
  return user.type === UserType.seller;
};

export const isClient = (user: User): boolean => {
  return CLIENTS_TYPES.includes(user.type);
};

export const userStatusLabel = (user: User): string => {
  const { status } = user;

  const currentStatus = find((item) => item.id === status, USER_STATUSES_OPTIONS);
  const label = pathOr('–', ['name'], currentStatus);

  return label;
};

export const isActive = (user: User): boolean => {
  return user.status === AccountStatus.active;
};

export const isInactive = (user: User): boolean => {
  return user.status === AccountStatus.inactive;
};

export const isConfirmed = (user: User): boolean => {
  return user.confirmationStatus === UserConfirmationStatus.confirmed;
};

export const isUnconfirmed = (user: User): boolean => {
  return user.confirmationStatus === UserConfirmationStatus.unconfirmed;
};

export const isTermAccepted = (user: User): boolean => {
  return user.isPolicyAndTermsAccepted;
};

export const formatPhoneNumber = (user: User): string => {
  const { phoneNumber } = user;

  if (phoneNumber) {
    const cleaned = `${phoneNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    return match ? `+1 (${match[1]}) ${match[2]}-${match[3]}` : phoneNumber;
  }

  return '–';
};

export const hasNrdsNumber = (user: User): boolean => {
  const { nrdsNumber } = user;

  return !isBlank(nrdsNumber);
};

export const formatNrdsNumber = (user: User): string => {
  const { nrdsNumber } = user;

  if (nrdsNumber) {
    const cleaned = `${nrdsNumber}`.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})$/);

    return match ? `${match[1]}-${match[2]}-${match[3]}` : nrdsNumber;
  }

  return '–';
};
