import humps from 'humps';
import { is, split, join, toLower } from 'ramda';

const isObject = <T>(object: T): boolean => is(Object, object);

export const isFile = <T>(object: T): boolean => object instanceof File;

const isArray = <T>(object: T): boolean => object instanceof Array;

const decamelize = (string: string): string => toLower(join('_', split(/(?=[A-Z])/, string)));

export const camelizeKeys = <T>(object: T): T => humps.camelizeKeys(object);

export const decamelizeKeys = (object: unknown): unknown => {
  if (isArray(object)) {
    return object.map((item: unknown) => decamelizeKeys(item));
  }
  if (isObject(object) && !isFile(object)) {
    return Object.keys(object).reduce(
      (acc, next) => ({
        ...acc,
        [decamelize(next)]: decamelizeKeys(object[next]),
      }),
      {},
    );
  }
  return object;
};
