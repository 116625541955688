import { ReactElement, useState, SyntheticEvent } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowDropDown from 'components/Icons/ArrowDropDown';
import { ButtonBase, Menu as MuiMenu, MenuItem, Typography, Avatar } from '@material-ui/core';
import { appRoutes } from 'routes';
import SessionRepository from 'repositories/SessionRepository';
import { UsersPresenter } from 'presenters';
import useUsers from 'hooks/useUsers';
import useAccounts from 'hooks/useAccounts';
import useAdminAccounts from 'hooks/useAdminAccounts';
import { reloadPage } from 'utils/locationHelper';
import clsx from 'utils/clsx';
import ZigZagoCircle from 'components/Icons/ZigZagoCircle';
import Box from 'components/Box';
import { UserType } from 'enums/UserType';

import styles from './styles';

const Menu = (): ReactElement => {
  const history = useHistory();
  const { destroyCurrentUser, currentUser, currentUserMeta } = useUsers();
  const { switchType, addType } = useAccounts();
  const { stopImpersonatingAccount } = useAdminAccounts();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStopImpersonate = async () => {
    await stopImpersonatingAccount();
    reloadPage();
  };

  const handleUserTypeSwitch = async (type: UserType) => {
    const params = { account: { type } };
    await switchType(params);
    reloadPage();
  };

  const handleUserTypeAdd = async (type: UserType) => {
    const params = { user: { type } };
    await addType(params);
    handleUserTypeSwitch(type);
  };

  const handleOpenProfile = () => {
    history.push(appRoutes.profilePath());
    handleClose();
  };

  const handleLogOut = async () => {
    await SessionRepository.delete();
    destroyCurrentUser();
    history.push(appRoutes.rootPath());
  };

  const isImpersonated = currentUserMeta && currentUserMeta.impersonated;

  const renderAvatar = () => {
    if (UsersPresenter.isAgent(currentUser)) {
      return (
        <Avatar alt="Avatar" src={currentUser.avatarUrl} sx={styles.avatar}>
          {UsersPresenter.initials(currentUser)}
        </Avatar>
      );
    }

    return <ZigZagoCircle sx={styles.zigZagoIcon} />;
  };

  const { type: currentType, accountUserTypes } = currentUser;

  const renderUserTypeMenu = () => {
    const typeMenus = accountUserTypes.map((userType: UserType) => {
      const isTypeMatches = userType === currentType;
      const style = clsx(styles.menuItem, [[styles.activeMenuItem, isTypeMatches]]);

      return (
        <MenuItem key={userType} sx={style} onClick={() => handleUserTypeSwitch(userType)}>
          <Typography sx={styles.text} variant="body2" align="center">
            {isTypeMatches ? UsersPresenter.typeLabel(userType) : `Switch to ${UsersPresenter.typeLabel(userType)}`}
          </Typography>
        </MenuItem>
      );
    });

    const lackingUserTypes = UsersPresenter.CLIENTS_TYPES.filter(
      (userType: UserType) => !accountUserTypes.includes(userType),
    );

    const lackingTypeMenus = lackingUserTypes.map((userType: UserType) => {
      return (
        <MenuItem key={userType} sx={styles.menuItem} onClick={() => handleUserTypeAdd(userType)}>
          <Typography sx={styles.text} variant="body2" align="center">
            Add {UsersPresenter.typeLabel(userType)}
          </Typography>
        </MenuItem>
      );
    });

    return [...typeMenus, ...lackingTypeMenus];
  };

  return (
    <>
      <ButtonBase
        id="button"
        aria-controls="menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={styles.root}
        disableRipple
      >
        <Box sx={styles.container}>
          <Box sx={styles.userInfo}>
            <Typography variant="h4" align="right">
              {UsersPresenter.fullName(currentUser)}
            </Typography>
            <Typography sx={styles.userType} variant="body1" align="right">
              {UsersPresenter.typeLabel(currentType)}
            </Typography>
          </Box>

          <Box sx={styles.icons}>
            {renderAvatar()}
            <ArrowDropDown sx={styles.icon} />
          </Box>
        </Box>
      </ButtonBase>

      <MuiMenu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
        sx={styles.menu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {!UsersPresenter.isAdmin(currentUser) && (
          <MenuItem sx={styles.menuItem} onClick={handleOpenProfile}>
            <Typography sx={styles.text} variant="body2" align="center">
              {isImpersonated ? `User's Profile` : 'My Profile'}
            </Typography>
          </MenuItem>
        )}

        {UsersPresenter.isClient(currentUser) && renderUserTypeMenu()}

        {isImpersonated ? (
          <MenuItem sx={styles.menuItem} onClick={handleStopImpersonate}>
            <Typography sx={styles.text} variant="body2" align="center">
              Stop Impersonate
            </Typography>
          </MenuItem>
        ) : (
          <MenuItem sx={{ ...styles.menuItem, ...styles.logout }} onClick={handleLogOut}>
            <Typography sx={styles.text} variant="body1" align="center" color="primary">
              Logout
            </Typography>
          </MenuItem>
        )}
      </MuiMenu>
    </>
  );
};

export default Menu;
