import { FC, FormEvent, MouseEvent, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { TextField, InputLabel, Typography, Alert, InputAdornment, IconButton } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import useAuthModals from 'hooks/useAuthModals';
import useRouter from 'hooks/useRouter';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'utils/responseErrors';
import { ResetPasswordFormData, initialValues, validationSchema, attributesToSubmit } from 'forms/resetPasswordForm';
import AccountsRepository from 'repositories/AccountsRepository';
import Box from 'components/Box';

import styles from './styles';

const ResetPassword: FC = (): JSX.Element => {
  const [formError, setFormError] = useState(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { openConfirmNewPasswordModal } = useAuthModals();
  const router = useRouter();
  const passwordResetToken = router.getUrlParam('password_reset_token');

  const handleSubmit = async (
    formData: ResetPasswordFormData,
    { setSubmitting, setErrors }: FormikHelpers<ResetPasswordFormData>,
  ) => {
    const params = attributesToSubmit(formData, passwordResetToken);
    try {
      await AccountsRepository.passwordUpdate(params);
      openConfirmNewPasswordModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setErrors(parseToFormikErrors(errors));
      } else {
        setFormError(true);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { values, errors, submitForm, touched, handleChange, isSubmitting } = useFormik<ResetPasswordFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitForm();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.title} variant="h3" align="center">
        Reset your Password
      </Typography>
      <Typography sx={styles.description} variant="body1" align="center">
        Password must be at least 8 characters long.
      </Typography>
      {formError && (
        <Alert sx={styles.alert} severity="error">
          <Typography variant="subtitle2" component="p">
            Password reset link has expired.
          </Typography>
        </Alert>
      )}
      <form onSubmit={handleFormSubmit}>
        <InputLabel>New Password</InputLabel>
        <TextField
          sx={styles.input}
          name="password"
          fullWidth
          value={values.password}
          onChange={handleChange}
          error={touched.password && !!errors.password}
          helperText={touched.password && errors.password}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <InputLabel>Repeat new Password</InputLabel>
        <TextField
          sx={styles.input}
          name="passwordConfirmation"
          fullWidth
          value={values.passwordConfirmation}
          onChange={handleChange}
          error={touched.passwordConfirmation && !!errors.passwordConfirmation}
          helperText={touched.passwordConfirmation && errors.passwordConfirmation}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          sx={styles.button}
          type="submit"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Change password
        </LoadingButton>
      </form>
    </Box>
  );
};

export default ResetPassword;
