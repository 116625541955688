import { SxProps, Theme } from '@material-ui/system';
import { withComma, withCurrency } from 'presenters/NumberPresenter';
import { appRoutes } from 'routes';

export type AdminDashboardType = {
  key: string;
  title: string;
  value: (value: number) => string;
  notification?: {
    key: string;
    value: (value: number) => string;
  };
  link?: string;
  sx?: SxProps<Theme>;
  clickable?: boolean;
};

export const DASHBOARD_KEYS: AdminDashboardType[] = [
  {
    key: 'users',
    title: 'Users',
    value: (value: number): string => withComma(value),
    notification: {
      key: 'waitingUsers',
      value: (value: number): string => withComma(value),
    },
    link: appRoutes.adminUsersPath(),
    clickable: true,
  },
  {
    key: 'buyingPower',
    title: 'Buying Power',
    value: (value: number): string => withCurrency(value),
    sx: { fontSize: '26px' },
  },
  {
    key: 'matches',
    title: 'Matches',
    value: (value: number): string => withComma(value),
  },
  {
    key: 'connections',
    title: 'Connections',
    value: (value: number): string => withComma(value),
    notification: {
      key: 'waitingConnections',
      value: (value: number): string => withComma(value),
    },
    link: appRoutes.adminConnectionsPath(),
    clickable: true,
  },
  {
    key: 'searches',
    title: 'Searches',
    value: (value: number): string => withComma(value),
    link: appRoutes.adminSearchesPath(),
    clickable: true,
  },
  {
    key: 'properties',
    title: 'Properties',
    value: (value: number): string => withComma(value),
    notification: {
      key: 'waitingProperties',
      value: (value: number): string => withComma(value),
    },
    link: appRoutes.adminPropertiesPath(),
    clickable: true,
  },
];
