import { AccountImpersonateParams } from 'forms/accountForm';
import AccountsRepository from 'repositories/Admin/AccountsRepository';

type UseAdminAccountsType = {
  activateAccount: (accountId: ID) => void;
  deactivateAccount: (accountId: ID) => void;
  impersonateAccount: (accountId: ID, params: AccountImpersonateParams) => void;
  stopImpersonatingAccount: () => void;
};

const useAdminAccounts = (): UseAdminAccountsType => {
  const activateAccount = (accountId: ID) => AccountsRepository.activate(accountId);
  const deactivateAccount = (accountId: ID) => AccountsRepository.deactivate(accountId);
  const impersonateAccount = (accountId: ID, params: AccountImpersonateParams) =>
    AccountsRepository.impersonate(accountId, params);
  const stopImpersonatingAccount = () => AccountsRepository.stopImpersonating();

  return {
    activateAccount,
    deactivateAccount,
    impersonateAccount,
    stopImpersonatingAccount,
  };
};

export default useAdminAccounts;
