export default {
  rootPath: (): string => '/',
  adminPath: (): string => '/admin',

  signInPath: (): string => '/sign-in',
  signUpPath: (): string => '/sign-up',
  recoveryPasswordPath: (): string => '/password-recovery',
  resetPasswordPath: (): string => '/password-reset',
  confirmNewPasswordPath: (): string => '/confirm-new-password',
  confirmPath: (): string => '/confirm',
  termsAndConditionsPath: (): string => '/terms-conditions',
  privacyPolicyPath: (): string => '/privacy-policy',
  faqPath: (): string => '/faq',
  howItWorksAgent: (): string => '/how-it-works-agent',
  howItWorksSeller: (): string => '/how-it-works-homeowner',

  heatMapPath: (): string => '/heat-map',

  searchesPath: (): string => '/searches',
  searchPath: (id: ID): string => `/searches/${id}`,
  newSearchPath: (): string => '/searches/new',
  editSearchPath: (id: ID): string => `/searches/${id}/edit`,

  connectionsPath: (): string => '/connections',
  connectionPath: (id: ID): string => `/connections/${id}`,
  connectionPropertyPath: (id: ID): string => `/connections/${id}/property`,
  connectionSearchPath: (id: ID): string => `/connections/${id}/search`,

  profilePath: (): string => '/profile',
  editProfilePath: (): string => '/profile/edit',

  propertiesPath: (): string => '/properties',

  browseBuyersPath: (query?: string): string => (query ? `/browsebuyers?${query}` : '/browsebuyers'),
  browseBuyerPath: (id: ID, query?: string): string => (query ? `/browsebuyers/${id}?${query}` : `/browsebuyers/${id}`),
  newPropertyPath: (): string => '/properties/new',
  editPropertyPath: (id: ID, fropPreview = false): string =>
    `/properties/${id}/edit${fropPreview ? '?from-preview=1' : ''}`,
  previewPropertyPath: (id: ID): string => `/properties/${id}/preview`,
  propertyPath: (id: ID): string => `/properties/${id}`,
  dashboardPath: (): string => '/dashboard',

  favoriteSearchesPath: (): string => '/favorite-searches',

  allSearchesPath: (query?: string): string => (query ? `/all-searches?${query}` : '/all-searches'),

  adminRootPath: (): string => '/admin',
  adminDashboardPath: (): string => '/admin/dashboard',

  adminUsersPath: (): string => '/admin/users',
  adminBuyerProfilePath: (id: ID): string => `/admin/users/buyer/${id}`,
  adminSellerProfilePath: (id: ID): string => `/admin/users/seller/${id}`,
  adminAgentProfilePath: (id: ID): string => `/admin/users/agent/${id}`,

  adminConnectionsPath: (): string => '/admin/connections',
  adminConnectionPath: (id: ID): string => `/admin/connections/${id}`,

  adminPropertiesPath: (): string => '/admin/properties',
  adminPropertyPath: (id: ID): string => `/admin/properties/${id}`,

  adminSearchesPath: (): string => '/admin/searches',
  adminSearchPath: (id: ID): string => `/admin/searches/${id}`,

  adminFeedbackPath: (): string => '/admin/feedback',
  adminAgentScorecardPath: (): string => '/admin/agent-scorecard',
  adminAgentScorecardStatesPath: (): string => '/admin/agent-scorecard/states',

  adminGeoData: (): string => '/admin/geodata',

  clientsPath: (): string => '/clients',
  clientsImportPath: (): string => '/clients/import',
  propertiesImportPath: (): string => '/properties/import',

  matchPath: (id: ID): string => `/matches/${id}`,
  searchMatchesPath: (id: ID): string => `/searches/${id}/matches`,
  propertyMatchesPath: (id: ID, params?: string): string => `/properties/${id}/matches${params ? `?${params}` : ''}`,

  buyersPath: (): string => '/buyers',
  buyerProfilePath: (id: ID): string => `/buyers/${id}`,
  editBuyerProfilePath: (id: ID): string => `/buyers/${id}/edit`,

  sellersPath: (): string => '/sellers',
  sellerProfilePath: (id: ID): string => `/sellers/${id}`,
  editSellerProfilePath: (id: ID): string => `/sellers/${id}/edit`,
};
