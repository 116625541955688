import { FC } from 'react';
import { SvgIcon, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type ZigZagoCircleProps = {
  sx?: SxProps<Theme>;
};

const ZigZagoCircle: FC<ZigZagoCircleProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} width="305" height="305" viewBox="0 0 305 305" fill="none">
      <path
        d="M183.08 146.72C183.08 167.7 200.54 185.15 221.51 185.15C242.49 185.15 259.59 167.69 259.59 146.72C259.59 125.74 242.49 108.11 221.51 108.11C200.53 108.11 183.08 125.74 183.08 146.72Z"
        fill="white"
      />
      <path
        d="M152.87 0.52002C68.99 0.52002 0.98999 68.5199 0.98999 152.4C0.98999 236.28 68.99 304.28 152.87 304.28C236.75 304.28 304.75 236.28 304.75 152.4C304.75 68.5199 236.75 0.52002 152.87 0.52002ZM22.68 148.03C21.92 112.09 51.48 81.8298 87.43 81.7798C110.11 81.7498 130.18 93.5498 141.91 111.01C143.25 113 142.51 115.72 140.37 116.81C133.61 120.25 131.23 121.51 123.26 125.5C121.5 126.38 119.34 125.88 118.2 124.27C110.44 113.28 97.05 107.01 81.84 109.05C66.25 111.15 53.52 122.9 50.4 138.31C45.33 163.37 63.74 184.59 87.51 184.59C101.57 184.59 115.63 177.56 120.73 165.61H117.92C112.3 165.61 99.47 165.79 92.44 165.79C89.1 165.79 87.52 165.79 87.52 165.61V146.44C87.52 144.22 89.32 142.41 91.54 142.42C107.71 142.44 128.56 142.57 147.53 142.59C149.83 142.59 151.65 144.51 151.54 146.8C151.16 154.87 149.68 171.15 144.46 177.92C133.74 198.13 111.95 211.66 87.52 211.66C52.31 211.65 23.43 183.24 22.68 148.03ZM286.74 146.72C286.74 182.68 257.3 211.77 221.51 211.77C185.55 211.77 156.46 182.68 156.46 146.72C156.46 110.58 185.55 81.4902 221.51 81.4902C257.3 81.4902 286.74 110.57 286.74 146.72Z"
        fill="white"
      />
      <path
        d="M144.45 177.91C149.67 171.14 151.15 154.86 151.53 146.79C151.64 144.5 149.81 142.58 147.52 142.58C128.55 142.56 107.7 142.43 91.53 142.41C89.31 142.41 87.51 144.21 87.51 146.43V165.6C87.51 165.78 89.09 165.78 92.43 165.78C99.46 165.78 112.29 165.6 117.91 165.6H120.72C115.62 177.55 101.56 184.58 87.5 184.58C63.73 184.58 45.32 163.36 50.39 138.3C53.51 122.89 66.24 111.14 81.83 109.04C97.04 107 110.43 113.27 118.19 124.26C119.33 125.87 121.49 126.37 123.25 125.49C131.21 121.5 133.6 120.24 140.36 116.8C142.5 115.71 143.23 112.99 141.9 111C130.17 93.5401 110.1 81.7401 87.42 81.7701C51.47 81.8201 21.91 112.08 22.67 148.02C23.42 183.23 52.3 211.64 87.5 211.64C111.94 211.65 133.73 198.12 144.45 177.91Z"
        fill="#00829B"
      />
      <path
        opacity="0.95"
        d="M144.45 177.91C149.67 171.14 151.15 154.86 151.53 146.79C151.64 144.5 149.81 142.58 147.52 142.58C128.55 142.56 107.7 142.43 91.53 142.41C89.31 142.41 87.51 144.21 87.51 146.43V165.6C87.51 165.78 89.09 165.78 92.43 165.78C99.46 165.78 112.29 165.6 117.91 165.6H120.72C115.62 177.55 101.56 184.58 87.5 184.58C63.73 184.58 45.32 163.36 50.39 138.3C53.51 122.89 66.24 111.14 81.83 109.04C97.04 107 110.43 113.27 118.19 124.26C119.33 125.87 121.49 126.37 123.25 125.49C131.21 121.5 133.6 120.24 140.36 116.8C142.5 115.71 143.23 112.99 141.9 111C130.17 93.5401 110.1 81.7401 87.42 81.7701C51.47 81.8201 21.91 112.08 22.67 148.02C23.42 183.23 52.3 211.64 87.5 211.64C111.94 211.65 133.73 198.12 144.45 177.91Z"
        fill="#00829B"
      />
      <path
        d="M156.46 146.72C156.46 182.68 185.55 211.77 221.51 211.77C257.3 211.77 286.74 182.68 286.74 146.72C286.74 110.58 257.3 81.4902 221.51 81.4902C185.55 81.4902 156.46 110.57 156.46 146.72ZM259.59 146.72C259.59 167.7 242.49 185.15 221.51 185.15C200.53 185.15 183.08 167.69 183.08 146.72C183.08 125.74 200.54 108.11 221.51 108.11C242.49 108.11 259.59 125.74 259.59 146.72Z"
        fill="#00829B"
      />
      <path
        d="M154 38.1299C181.72 38.1299 207.89 48.45 228.08 67.27C229.53 68.62 231.78 68.5699 233.17 67.1699C234.64 65.6899 234.58 63.2899 233.06 61.8799C211.5 41.7999 183.58 30.79 154 30.79C124.42 30.79 96.5 41.7999 74.94 61.8799C73.42 63.2999 73.36 65.6999 74.83 67.1699C76.22 68.5699 78.48 68.62 79.92 67.27C100.12 48.46 126.28 38.1299 154 38.1299Z"
        fill="#51A9BA"
      />
      <path
        d="M154 255.65C126.15 255.65 99.89 245.25 79.67 226.28C78.23 224.93 75.9699 224.97 74.5799 226.37C73.1099 227.84 73.16 230.24 74.67 231.66C96.26 251.89 124.28 262.99 154 262.99C183.73 262.99 211.75 251.89 233.33 231.66C234.85 230.24 234.89 227.84 233.42 226.37C232.02 224.97 229.77 224.93 228.33 226.28C208.11 245.25 181.85 255.65 154 255.65Z"
        fill="#95CC31"
      />
    </SvgIcon>
  );
};

export default ZigZagoCircle;
