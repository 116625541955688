import { FC } from 'react';
import { SvgIcon, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type InstagramProps = {
  sx?: SxProps<Theme>;
};

const Instagram: FC<InstagramProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M27.7844 11.2933C27.6992 7.43007 24.6351 4.41011 20.7876 4.38962C17.5533 4.37228 14.3174 4.37385 11.0814 4.38962C7.15512 4.41011 4.04213 7.50889 4.02006 11.4273C4.00114 14.6916 3.99799 17.9559 4.02164 21.2202C4.04843 24.9763 7.06527 28.0829 10.8135 28.146C14.2212 28.2027 17.6305 28.2012 21.0382 28.1413C24.6509 28.0798 27.6835 25.0346 27.7781 21.4314C27.8238 19.6991 27.7859 17.9653 27.7859 16.2331C27.7875 16.2331 27.7891 16.2331 27.7907 16.2331C27.7907 14.586 27.8206 12.9389 27.7844 11.2933ZM26.2208 16.241C26.2208 17.803 26.2224 19.3634 26.2208 20.9254C26.2145 24.2102 23.8234 26.5856 20.5165 26.5887C17.4225 26.5919 14.33 26.5919 11.2359 26.5887C8.11817 26.584 5.70817 24.3946 5.62306 21.3005C5.53164 17.9496 5.53164 14.5923 5.62306 11.2413C5.70344 8.29696 7.96371 6.06033 10.9128 5.98782C14.2354 5.90429 17.5627 5.90432 20.8853 5.98628C23.9857 6.06194 26.2082 8.45935 26.2192 11.5582C26.2255 13.1186 26.2208 14.6806 26.2208 16.241Z"
        fill="white"
      />
      <path
        d="M15.9425 10.2625C12.5584 10.2452 9.88677 12.9027 9.89308 16.2805C9.89938 19.6173 12.5537 22.2669 15.8889 22.2669C19.232 22.2669 21.8864 19.6267 21.8958 16.2915C21.9068 12.9452 19.2746 10.2799 15.9425 10.2625ZM15.8275 20.6922C13.378 20.6686 11.4299 18.64 11.4693 16.1528C11.5071 13.7459 13.5199 11.8135 15.9646 11.8372C18.414 11.8608 20.3622 13.8894 20.3228 16.3766C20.2849 18.7819 18.2706 20.7159 15.8275 20.6922Z"
        fill="white"
      />
      <path
        d="M22.7231 8.1959C21.8562 8.17857 21.1753 8.81221 21.1359 9.67282C21.0965 10.5602 21.7837 11.2994 22.6585 11.312C23.5222 11.3247 24.2788 10.587 24.2757 9.73585C24.2725 8.92569 23.5522 8.21324 22.7231 8.1959Z"
        fill="white"
      />
      <path
        d="M31.4649 6.98559C31.4476 3.34773 28.8106 0.702846 25.1727 0.694965C18.9877 0.68078 12.8028 0.68078 6.61776 0.694965C2.9799 0.702846 0.335038 3.34773 0.325581 6.98559C0.311395 13.1706 0.311395 19.3556 0.325581 25.5406C0.333462 29.1785 2.93891 31.8721 6.57204 31.8989C12.7854 31.9431 19.0003 31.9431 25.2137 31.8989C28.8437 31.8737 31.457 29.1627 31.4665 25.5421C31.4744 22.4496 31.4681 19.3572 31.4681 16.2647C31.4681 13.1706 31.4807 10.0781 31.4649 6.98559ZM27.7782 21.4314C27.6836 25.033 24.651 28.0783 21.0384 28.1413C17.6306 28.1996 14.2213 28.2028 10.8136 28.146C7.0654 28.083 4.04856 24.9747 4.02176 21.2202C3.99969 17.9559 4.00284 14.6916 4.02018 11.4273C4.04225 7.51049 7.15367 4.41013 11.0815 4.38964C14.3175 4.37387 17.5518 4.3723 20.7878 4.38964C24.6352 4.41013 27.6994 7.43008 27.7845 11.2933C27.8207 12.9389 27.7908 14.586 27.7908 16.2331C27.7892 16.2331 27.7876 16.2331 27.7861 16.2331C27.7861 17.9653 27.8239 19.6992 27.7782 21.4314Z"
        fill="currentColor"
      />
      <path
        d="M20.8852 5.98634C17.5625 5.90438 14.2352 5.90434 10.9126 5.98788C7.96352 6.06196 5.70325 8.29702 5.62287 11.2414C5.53145 14.5923 5.52987 17.9496 5.62287 21.3006C5.70798 24.3931 8.11798 26.5841 11.2357 26.5888C14.3298 26.5935 17.4223 26.5919 20.5163 26.5888C23.8232 26.5856 26.2159 24.2087 26.2206 20.9255C26.2237 19.3635 26.2206 17.8031 26.2206 16.2411C26.2206 14.6806 26.2253 13.1186 26.219 11.5582C26.2095 8.45941 23.9871 6.062 20.8852 5.98634ZM15.8902 22.2669C12.555 22.2669 9.90066 19.6173 9.89435 16.2805C9.88805 12.9027 12.5597 10.2452 15.9438 10.2625C19.2743 10.2799 21.9081 12.9453 21.8987 16.29C21.8876 19.6252 19.2317 22.2669 15.8902 22.2669ZM22.6584 11.3123C21.7836 11.2997 21.0964 10.5589 21.1358 9.67309C21.1736 8.81248 21.8561 8.17883 22.723 8.19617C23.5537 8.21351 24.2724 8.92595 24.2756 9.73612C24.2787 10.5857 23.5221 11.3233 22.6584 11.3123Z"
        fill="currentColor"
      />
      <path
        d="M15.9646 11.8369C13.52 11.8132 11.5072 13.7456 11.4693 16.1525C11.4299 18.6397 13.3781 20.6683 15.8275 20.6919C18.2722 20.7155 20.285 18.7831 20.3228 16.3763C20.3607 13.8875 18.4141 11.8605 15.9646 11.8369Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Instagram;
