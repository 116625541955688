import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { Meta } from 'types/meta';
import User from 'types/resources/user';
import UsersRepository from 'repositories/Admin/UsersRepository';
import { AdminUsersFiltersParams } from 'forms/adminUsersFiltersForm';

export type AdminUsersSliceStateType = {
  adminUsers: {
    data: User[];
    meta: Meta;
    preQualifiedBuyersCount: number;
    fetchStatus: FetchStatus;
  };
};

export type AdminUsersSliceActionsType = {
  loadAdminUsers: (params: AdminUsersFiltersParams) => void;
  resetAdminUsers: () => void;
};

const initialState: AdminUsersSliceStateType = {
  adminUsers: {
    data: [],
    meta: null,
    preQualifiedBuyersCount: 0,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminUsersSlice = createSlice({
  name: 'adminUsers',
  initialState,
  reducers: {
    loadAdminUsersStart: (state): void => {
      state.adminUsers.fetchStatus = FetchStatus.pending;
    },
    loadAdminUsersFinish: (state, { payload }): void => {
      const { users, meta, preQualifiedBuyersCount } = payload;

      state.adminUsers.fetchStatus = FetchStatus.fulfilled;
      state.adminUsers.data = users;
      state.adminUsers.meta = meta;
      state.adminUsers.preQualifiedBuyersCount = preQualifiedBuyersCount;
    },
    loadAdminUsersFail: (state): void => {
      state.adminUsers.fetchStatus = FetchStatus.failed;
    },

    resetUsers: (): AdminUsersSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminUsersSlice;

export const useAdminUsersActions = (): AdminUsersSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminUsers = async (params: AdminUsersFiltersParams) => {
    dispatch(actions.loadAdminUsersStart());
    try {
      const users = await UsersRepository.index(params);
      dispatch(actions.loadAdminUsersFinish(users));
    } catch (error: unknown) {
      dispatch(actions.loadAdminUsersFail());
    }
  };

  const resetAdminUsers = () => dispatch(actions.resetUsers());

  return {
    loadAdminUsers,
    resetAdminUsers,
  };
};

export default AdminUsersSlice.reducer;
