import useAuthModals from 'hooks/useAuthModals';
import { FC, useEffect } from 'react';
import { isNil } from 'ramda';
import { appRoutes } from 'routes';
import useRouter from 'hooks/useRouter';
import { AuthModal as AuthModalEnum } from 'enums/AuthModal';
import Modal from 'components/Modal';
import Box from 'components/Box';

import SignIn from './components/SignIn';
import RecoveryPassword from './components/RecoveryPassword';
import ResetPassword from './components/ResetPassword';
import ConfirmNewPassword from './components/ConfirmNewPassword';
import ConfirmSuccess from './components/ConfirmSuccess';
import ConfirmFail from './components/ConfirmFail';
import styles from './styles';

const AuthModal: FC = (): JSX.Element => {
  const {
    activeModal,
    closeAuthModal,
    openSignInModal,
    openRecoveryPasswordModal,
    openResetPasswordModal,
    openConfirmNewPasswordModal,
  } = useAuthModals();

  const { match } = useRouter();

  const isSignInPath = match(appRoutes.signInPath());
  const isRecoveryPasswordPath = match(appRoutes.recoveryPasswordPath());
  const isResetPasswordPath = match(appRoutes.resetPasswordPath());
  const isConfirmNewPasswordPath = match(appRoutes.confirmNewPasswordPath());

  useEffect(() => {
    if (isSignInPath) {
      openSignInModal();
    }
    if (isRecoveryPasswordPath) {
      openRecoveryPasswordModal();
    }
    if (isResetPasswordPath) {
      openResetPasswordModal();
    }
    if (isConfirmNewPasswordPath) {
      openConfirmNewPasswordModal();
    }
  }, []);

  const renderModalContent = () => {
    switch (activeModal) {
      case AuthModalEnum.signIn: {
        return <SignIn onClose={closeAuthModal} />;
      }
      case AuthModalEnum.recoveryPassword: {
        return <RecoveryPassword />;
      }
      case AuthModalEnum.resetPassword: {
        return <ResetPassword />;
      }
      case AuthModalEnum.confirmNewPassword: {
        return <ConfirmNewPassword />;
      }
      case AuthModalEnum.confirmSuccess: {
        return <ConfirmSuccess />;
      }
      case AuthModalEnum.confirmFail: {
        return <ConfirmFail />;
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal open={!isNil(activeModal)} onClose={closeAuthModal}>
      <Box sx={styles.content}>{renderModalContent()}</Box>
    </Modal>
  );
};

export default AuthModal;
