import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  progress: {
    textAlign: 'center',
    paddingY: 4,
  },
};

export default styles;
