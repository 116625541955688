import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 'auto',
    paddingBottom: 12,
  },
};

export default styles;
