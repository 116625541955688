import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import Connection from 'types/resources/connection';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';

export default {
  index(params: ConnectionFiltersParams): Promise<{ connections: Connection[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1UserBuyerConnectionsPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<Connection> {
    const url = apiRoutes.apiWebV1UserBuyerConnectionPath(id);

    return Fetcher.get(url);
  },
  acceptContact(id: ID): Promise<void> {
    const url = apiRoutes.acceptContactApiWebV1UserBuyerConnectionPath(id);

    return Fetcher.put(url, {});
  },
  rejectContact(id: ID): Promise<void> {
    const url = apiRoutes.rejectContactApiWebV1UserBuyerConnectionPath(id);

    return Fetcher.put(url, {});
  },
  archive(id: ID): Promise<void> {
    const url = apiRoutes.apiWebV1UserBuyerConnectionPath(id);

    return Fetcher.delete(url);
  },
};
