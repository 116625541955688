import MuiContainer from './MuiContainer';
import MuiButton from './MuiButton';
import MuiInputLabel from './MuiInputLabel';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiLink from './MuiLink';
import MuiFormHelperText from './MuiFormHelperText';
import MuiSvgIcon from './MuiSvgIcon';
import MuiPaper from './MuiPaper';
import MuiTooltip from './MuiTooltip';
import MuiTab from './MuiTab';
import MuiTabPanel from './MuiTabPanel';
import MuiTabs from './MuiTabs';
import MuiTableContainer from './MuiTableContainer';
import MuiTableHead from './MuiTableHead';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiDivider from './MuiDivider';

export default {
  components: {
    MuiContainer,
    MuiButton,
    MuiInputLabel,
    MuiOutlinedInput,
    MuiLink,
    MuiFormHelperText,
    MuiSvgIcon,
    MuiPaper,
    MuiTooltip,
    MuiTab,
    MuiTabPanel,
    MuiTabs,
    MuiTableContainer,
    MuiTableHead,
    MuiFormControlLabel,
    MuiDivider,
  },
};
