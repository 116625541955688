import { SearchMatchesFiltersQueryParams } from 'forms/searchMatchesFiltersForm';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import Match from 'types/resources/match';

export default {
  index(propertyId: ID, params: SearchMatchesFiltersQueryParams): Promise<{ matches: Match[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1UserSellerPropertyMatchesPath(propertyId);

    return Fetcher.get(url, params);
  },
  create(propertyId: ID): Promise<void> {
    const url = apiRoutes.apiWebV1UserSellerPropertyMatchesPath(propertyId);

    return Fetcher.post(url, {});
  },
};
