import rollbar from 'rollbar';

const rollbarConfig = {
  accessToken: Settings.rollbarClientToken,
  enabled: ['staging', 'production'].includes(Settings.env),
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: Settings.env,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: Settings.version,
        guess_uncaught_frames: true,
      },
    },
  },
};

export default rollbar.init(rollbarConfig);
