import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import Search from 'types/resources/search';
import { Meta } from 'types/meta';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';
import SearchesRepository from 'repositories/Agent/SearchesRepository';
import { SearchEvent } from 'enums/SearchEvent';

export type AgentSearchesSliceStateType = {
  searches: {
    data: Search[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
  search: {
    data: Search;
    fetchStatus: FetchStatus;
  };
};

export type AgentSearchesSliceActionsType = {
  loadSearches: (params: SearchFiltersFormDataParams) => void;
  resetSearches: () => void;
  loadSearch: (id: ID) => void;
  loadMatchesStatus: (id: ID) => Promise<Search>;
  changeSearchPhase: (id: ID, phase: SearchEvent) => void;
  archiveSearch: (id: ID) => void;
};

const initialState: AgentSearchesSliceStateType = {
  searches: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
  search: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AgentSearchesSlice = createSlice({
  name: 'agentSearches',
  initialState,
  reducers: {
    loadSearchesStart: (state): void => {
      state.searches.fetchStatus = FetchStatus.pending;
    },
    loadSearchesFinish: (state, { payload }): void => {
      const { searches, meta } = payload;
      state.searches.fetchStatus = FetchStatus.fulfilled;
      state.searches.data = searches;
      state.searches.meta = meta;
    },
    loadSearchesFail: (state): void => {
      state.searches.fetchStatus = FetchStatus.failed;
    },

    loadSearchStart: (state): void => {
      state.search.fetchStatus = FetchStatus.pending;
    },
    loadSearchFinish: (state, { payload }): void => {
      const { search } = payload;

      state.search.fetchStatus = FetchStatus.fulfilled;
      state.search.data = search;
    },
    loadSearchFail: (state): void => {
      state.search.fetchStatus = FetchStatus.failed;
    },

    resetSearches: (): AgentSearchesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AgentSearchesSlice;

export const useAgentSearchesActions = (): AgentSearchesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadSearches = async (params: SearchFiltersFormDataParams) => {
    dispatch(actions.loadSearchesStart());
    try {
      const searches = await SearchesRepository.index(params);
      dispatch(actions.loadSearchesFinish(searches));
    } catch (error: unknown) {
      dispatch(actions.loadSearchesFail());
    }
  };

  const loadSearch = async (id: ID) => {
    dispatch(actions.loadSearchStart());
    try {
      const search = await SearchesRepository.show(id);
      dispatch(actions.loadSearchFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadSearchFail());
    }
  };

  const loadMatchesStatus = async (id: ID) => {
    const { search } = await SearchesRepository.showMatchesStatus(id);
    return search;
  };

  const changeSearchPhase = async (id: ID, phase: SearchEvent) => SearchesRepository.changePhase(id, phase);

  const archiveSearch = async (id: ID) => SearchesRepository.archive(id);

  const resetSearches = () => dispatch(actions.resetSearches());

  return {
    loadSearches,
    resetSearches,
    loadSearch,
    loadMatchesStatus,
    changeSearchPhase,
    archiveSearch,
  };
};

export default AgentSearchesSlice.reducer;
