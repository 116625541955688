import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import { PropertyRejectParams } from 'forms/propertyRejectForm';
import Property from 'types/resources/property';
import PropertiesRepository from 'repositories/Admin/PropertiesRepository';
import { Meta } from 'types/meta';

export type AdminPropertiesSliceStateType = {
  adminProperties: {
    data: Property[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
  };
  adminProperty: {
    fetchStatus: FetchStatus;
    data: Property | null;
  };
  propertyApproved: { fetchStatus: FetchStatus };
  propertyRejected: { fetchStatus: FetchStatus };
};

export type AdminPropertiesSliceActionsType = {
  loadAdminProperties: (params: PropertyFiltersParams) => void;
  resetAdminProperties: () => void;
  loadAdminProperty: (id: ID) => void;
  approveProperty: (id: ID) => void;
  rejectProperty: (id: ID, params: PropertyRejectParams) => void;
};

const initialState: AdminPropertiesSliceStateType = {
  adminProperties: {
    fetchStatus: FetchStatus.idle,
    data: [],
    meta: null,
  },
  adminProperty: {
    fetchStatus: FetchStatus.idle,
    data: null,
  },
  propertyApproved: { fetchStatus: FetchStatus.idle },
  propertyRejected: { fetchStatus: FetchStatus.idle },
};

const AdminPropertiesSlice = createSlice({
  name: 'adminProperties',
  initialState,
  reducers: {
    loadPropertiesStart: (state): void => {
      state.adminProperties.fetchStatus = FetchStatus.pending;
    },
    loadPropertiesFinish: (state, { payload }): void => {
      const { properties, meta } = payload;

      state.adminProperties.fetchStatus = FetchStatus.fulfilled;
      state.adminProperties.data = properties;
      state.adminProperties.meta = meta;
    },
    loadPropertiesFail: (state): void => {
      state.adminProperties.fetchStatus = FetchStatus.failed;
    },
    resetAdminProperties: (): AdminPropertiesSliceStateType => {
      return initialState;
    },
    loadAdminPropertyStart: (state): void => {
      state.adminProperty.fetchStatus = FetchStatus.pending;
    },
    loadAdminPropertyFinish: (state, { payload }): void => {
      const { property } = payload;

      state.adminProperty.fetchStatus = FetchStatus.fulfilled;
      state.adminProperty.data = property;
    },
    loadAdminPropertyFail: (state): void => {
      state.adminProperty.fetchStatus = FetchStatus.failed;
    },
    approvePropertyStart: (state): void => {
      state.propertyApproved.fetchStatus = FetchStatus.pending;
    },
    approvePropertyFinish: (state): void => {
      state.propertyApproved.fetchStatus = FetchStatus.fulfilled;
    },
    approvePropertyFail: (state): void => {
      state.propertyApproved.fetchStatus = FetchStatus.failed;
    },
    rejectPropertyStart: (state): void => {
      state.propertyRejected.fetchStatus = FetchStatus.pending;
    },
    rejectPropertyFinish: (state): void => {
      state.propertyRejected.fetchStatus = FetchStatus.fulfilled;
    },
    rejectPropertyFail: (state): void => {
      state.propertyRejected.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = AdminPropertiesSlice;

export const useAdminPropertiesActions = (): AdminPropertiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminProperties = async (params: PropertyFiltersParams = {}) => {
    dispatch(actions.loadPropertiesStart());

    try {
      const properties = await PropertiesRepository.index(params);
      dispatch(actions.loadPropertiesFinish(properties));
    } catch (error: unknown) {
      dispatch(actions.loadPropertiesFail());
    }
  };

  const loadAdminProperty = async (id: ID) => {
    dispatch(actions.loadAdminPropertyStart());

    try {
      const search = await PropertiesRepository.show(id);
      dispatch(actions.loadAdminPropertyFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadAdminPropertyFail());
    }
  };

  const approveProperty = async (id: ID) => {
    dispatch(actions.approvePropertyStart());

    try {
      await PropertiesRepository.approve(id);
      dispatch(actions.approvePropertyFinish());
    } catch (error: unknown) {
      dispatch(actions.approvePropertyFail());
    }
  };

  const rejectProperty = async (id: ID, params: PropertyRejectParams) => {
    dispatch(actions.rejectPropertyStart());

    try {
      await PropertiesRepository.reject(id, params);
      dispatch(actions.rejectPropertyFinish());
    } catch (error: unknown) {
      dispatch(actions.rejectPropertyFail());
    }
  };

  const resetAdminProperties = () => dispatch(actions.resetAdminProperties());

  return {
    loadAdminProperties,
    resetAdminProperties,
    loadAdminProperty,
    approveProperty,
    rejectProperty,
  };
};

export default AdminPropertiesSlice.reducer;
