import { FC } from 'react';
import { SvgIcon, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type TwitterProps = {
  sx?: SxProps<Theme>;
};

const Twitter: FC<TwitterProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M31.3072 6.98559C31.2898 3.34773 28.6529 0.702846 25.015 0.694965C18.83 0.68078 12.645 0.68078 6.46003 0.694965C2.82218 0.702846 0.177323 3.34773 0.167866 6.98559C0.15368 13.1706 0.15368 19.3556 0.167866 25.5406C0.175747 29.1785 2.78119 31.8721 6.41432 31.8989C12.6277 31.9431 18.8426 31.9431 25.056 31.8989C28.686 31.8737 31.2993 29.1627 31.3088 25.5421C31.3166 22.4496 31.3103 19.3572 31.3103 16.2647C31.3103 13.1706 31.3229 10.0781 31.3072 6.98559ZM27.7308 10.666C27.1192 11.1531 26.9663 11.6811 26.9222 12.4329C26.5739 18.2995 24.0472 22.9651 18.8174 25.788C13.69 28.5558 8.47441 28.2438 3.36754 25.383C6.05495 25.2979 8.5879 24.7824 10.8308 23.025C7.90698 22.2353 6.43797 21.0295 6.12273 19.2232C6.73114 19.1854 7.34113 19.1491 7.94954 19.1128C5.56476 18.0363 4.15879 16.3624 4.00747 13.6781C4.76405 13.8893 5.42605 14.0738 6.42536 14.3528C4.07525 12.1492 3.45266 9.83697 4.74829 6.95411C7.79507 10.3366 11.4203 12.3494 16.0212 12.6268C15.4838 7.99593 18.9293 5.73725 21.5379 5.96737C22.6286 6.06352 23.7635 6.51112 24.7124 7.0754C25.387 7.47733 25.9056 7.65391 26.6054 7.36232C27.3005 7.0723 28.005 6.80277 28.9098 6.69244C28.2982 7.52782 27.6851 8.36317 26.9947 9.30416C27.8364 9.08665 28.6103 8.88494 29.3858 8.68477C29.4252 8.73205 29.4646 8.78086 29.5024 8.82815C28.9161 9.44759 28.3896 10.1396 27.7308 10.666Z"
        fill="currentColor"
      />
      <path
        d="M26.9934 9.30272C27.6838 8.36174 28.2953 7.5263 28.9085 6.69092C28.0037 6.80125 27.2992 7.07078 26.6041 7.3608C25.9042 7.6524 25.3872 7.47589 24.711 7.07396C23.7622 6.50968 22.6273 6.06208 21.5366 5.96593C18.928 5.73581 15.4824 7.99292 16.0199 12.6254C11.419 12.3479 7.79375 10.3335 4.74696 6.95259C3.45133 9.83545 4.07393 12.1477 6.42403 14.3512C5.42473 14.0723 4.7643 13.8879 4.00615 13.6767C4.15746 16.3594 5.565 18.0348 7.94821 19.1114C7.3398 19.1476 6.72981 19.1854 6.1214 19.2217C6.43664 21.028 7.90565 22.2354 10.8295 23.0235C8.58499 24.7809 6.05362 25.2963 3.36621 25.3815C8.47308 28.2422 13.6887 28.556 18.8161 25.7866C24.0459 22.962 26.571 18.2981 26.9209 12.4315C26.965 11.6812 27.1179 11.1531 27.7295 10.6645C28.3883 10.1381 28.9148 9.44611 29.4995 8.82509C29.4601 8.77781 29.4207 8.729 29.3829 8.68171C28.609 8.88347 27.8335 9.08521 26.9934 9.30272Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Twitter;
