/* eslint-disable no-param-reassign */
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import Dashboard from 'types/resources/dashboard';
import DashboardRepository from 'repositories/Agent/DashboardRepository';

export type AgentDashboardSliceStateType = {
  dashboard: {
    data: Dashboard;
    fetchStatus: FetchStatus;
  };
};

export type AgentDashboardSliceActionsType = {
  loadAgentDashboard: () => void;
  resetAgentDashboard: () => void;
};

const initialState: AgentDashboardSliceStateType = {
  dashboard: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AgentDashboardSlice = createSlice({
  name: 'agentDashboard',
  initialState,
  reducers: {
    loadAgentDashboardStart: (state): void => {
      state.dashboard.fetchStatus = FetchStatus.pending;
    },
    loadAgentDashboardFinish: (state, { payload }): void => {
      const { agentDashboard } = payload;

      state.dashboard.fetchStatus = FetchStatus.fulfilled;
      state.dashboard.data = agentDashboard;
    },
    loadAgentDashboardFail: (state): void => {
      state.dashboard.fetchStatus = FetchStatus.failed;
    },

    resetAgentDashboard: (): AgentDashboardSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AgentDashboardSlice;

export const useAgentDashboardActions = (): AgentDashboardSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAgentDashboard = async () => {
    dispatch(actions.loadAgentDashboardStart());
    try {
      const dashboard = await DashboardRepository.show();
      dispatch(actions.loadAgentDashboardFinish(dashboard));
    } catch (error: unknown) {
      dispatch(actions.loadAgentDashboardFail());
    }
  };

  const resetAgentDashboard = () => dispatch(actions.resetAgentDashboard());

  return {
    loadAgentDashboard,
    resetAgentDashboard,
  };
};

export default AgentDashboardSlice.reducer;
