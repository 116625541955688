import FavoriteSearchesRepository from 'repositories/Seller/FavoriteSearchesRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import FavoriteSearch from 'types/resources/favoriteSearch';

export type FavoriteSearchParams = {
  searchId: ID;
};

export type FavoriteSearchesSliceStateType = {
  favoriteSearches: { data: FavoriteSearch[]; fetchStatus: FetchStatus };
  creatingFavoriteSearch: { fetchStatus: FetchStatus };
  destroyingFavoriteSearch: { fetchStatus: FetchStatus };
};

export type FavoriteSearchesSliceActionsType = {
  loadFavoriteSearches: () => void;
  createFavoriteSearch: (data: FavoriteSearchParams) => void;
  destroyFavoriteSearch: (id: ID) => void;
  resetFavoriteSearches: () => void;
};

const initialState: FavoriteSearchesSliceStateType = {
  favoriteSearches: {
    data: [],
    fetchStatus: FetchStatus.idle,
  },
  creatingFavoriteSearch: {
    fetchStatus: FetchStatus.idle,
  },
  destroyingFavoriteSearch: {
    fetchStatus: FetchStatus.idle,
  },
};

const FavoriteSearchesSlice = createSlice({
  name: 'favoriteSearches',
  initialState,
  reducers: {
    loadFavoriteSearchesStart: (state): void => {
      state.favoriteSearches.fetchStatus = FetchStatus.pending;
    },
    loadFavoriteSearchesFinish: (state, { payload }): void => {
      const { favoriteSearches } = payload;
      state.favoriteSearches.fetchStatus = FetchStatus.fulfilled;
      state.favoriteSearches.data = favoriteSearches;
    },
    loadFavoriteSearchesFail: (state): void => {
      state.favoriteSearches.fetchStatus = FetchStatus.failed;
    },
    createFavoriteSearchStart: (state): void => {
      state.creatingFavoriteSearch.fetchStatus = FetchStatus.pending;
    },
    createFavoriteSearchFinish: (state): void => {
      state.creatingFavoriteSearch.fetchStatus = FetchStatus.fulfilled;
    },
    createFavoriteSearchFail: (state): void => {
      state.creatingFavoriteSearch.fetchStatus = FetchStatus.failed;
    },
    destroyFavoriteSearchStart: (state): void => {
      state.destroyingFavoriteSearch.fetchStatus = FetchStatus.pending;
    },
    destroyFavoriteSearchFinish: (state): void => {
      state.destroyingFavoriteSearch.fetchStatus = FetchStatus.fulfilled;
    },
    destroyFavoriteSearchFail: (state): void => {
      state.destroyingFavoriteSearch.fetchStatus = FetchStatus.failed;
    },
    resetSearches: (): FavoriteSearchesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = FavoriteSearchesSlice;

export const useFavoriteSearchesActions = (): FavoriteSearchesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadFavoriteSearches = async () => {
    dispatch(actions.loadFavoriteSearchesStart());
    try {
      const favoriteSearches = await FavoriteSearchesRepository.index();
      dispatch(actions.loadFavoriteSearchesFinish(favoriteSearches));
    } catch (error: unknown) {
      dispatch(actions.loadFavoriteSearchesFail());
    }
  };

  const createFavoriteSearch = async (data: FavoriteSearchParams) => {
    dispatch(actions.createFavoriteSearchStart());
    try {
      await FavoriteSearchesRepository.create(data);
      dispatch(actions.createFavoriteSearchFinish());
    } catch (error: unknown) {
      dispatch(actions.createFavoriteSearchFail());
    }
  };

  const destroyFavoriteSearch = async (id: ID) => {
    dispatch(actions.destroyFavoriteSearchStart());
    try {
      await FavoriteSearchesRepository.delete(id);
      dispatch(actions.destroyFavoriteSearchFinish());
    } catch (error: unknown) {
      dispatch(actions.destroyFavoriteSearchFail());
    }
  };

  const resetFavoriteSearches = () => dispatch(actions.resetSearches());

  return {
    loadFavoriteSearches,
    createFavoriteSearch,
    destroyFavoriteSearch,
    resetFavoriteSearches,
  };
};

export default FavoriteSearchesSlice.reducer;
