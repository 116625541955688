import { ComponentsOverrides } from '@material-ui/core';

type MuiTooltipType = {
  styleOverrides: ComponentsOverrides['MuiTooltip'];
};

const MuiTooltip: MuiTooltipType = {
  styleOverrides: {
    tooltip: {
      fontSize: 12,
      backgroundColor: '#FFF',
      color: '#313430',
      boxShadow: '0 1px 3px 0 rgba(0,0,0,0.5)',
      maxWidth: '25vw',
      padding: '10px',
    },
  },
};

export default MuiTooltip;
