import { configureStore } from '@reduxjs/toolkit';

import adminConnectionsReducer from './adminConnectionsSlice';
import adminAgentsReducer from './adminAgentsSlice';
import adminUsersReducer from './adminUsersSlice';
import adminBuyerReducer from './adminBuyerSlice';
import adminBuyerSearchesReducer from './adminBuyerSearchesSlice';
import adminBuyerConnectionsReducer from './adminBuyerConnectionsSlice';
import adminAgentSearchesReducer from './adminAgentSearchesSlice';
import adminAgentConnectionsReducer from './adminAgentConnectionsSlice';
import adminSellerReducer from './adminSellerSlice';
import adminSellerConnectionsReducer from './adminSellerConnectionsSlice';
import adminSellerPropertiesReducer from './adminSellerPropertiesSlice';
import adminDashboardReducer from './dashboardSlice';
import adminPropertiesReducer from './adminPropertiesSlice';
import adminSearchesReducer from './adminSearchesSlice';
import adminReviewsReducer from './adminReviewsSlice';
import adminFeedbackDashboardReducer from './adminFeedbackDashboardSlice';
import authModalsReducer from './authModalsSlice';
import usersReducer from './usersSlice';
import searchesReducer from './searchesSlice';
import buyerSearchesReducer from './buyerSearchesSlice';
import citiesReducer from './citiesSlice';
import searchFiltersReducer from './searchFiltersSlice';
import connectionsReducer from './connectionsSlice';
import connectionPropertiesReducer from './connectionPropertiesSlice';
import statesReducer from './statesSlice';
import zipCodesReducer from './zipCodesSlice';
import availablePropertiesReducer from './availablePropertiesSlice';
import sellerPropertiesReducer from './sellerPropertiesSlice';
import sellerConnectionsReducer from './sellerConnectionsSlice';
import sellerSearchesReducer from './sellerSearchesSlice';
import sellerMatchesReducer from './sellerMatchesSlice';
import sellerFavoriteSearchesReducer from './sellerFavoriteSearchesSlice';
import agentDashboardReducer from './agentDashboardSlice';
import agentConnectionsReducer from './agentConnectionsSlice';
import agentSearchesReducer from './agentSearchesSlice';
import agentBuyersReducer from './agentBuyersSlice';
import agentPropertiesReducer from './agentPropertiesSlice';
import agentSellersReducer from './agentSellersSlice';
import agentSellerPropertiesReducer from './agentSellerPropertiesSlice';
import agentBuyerSearchesReducer from './agentBuyerSearchesSlice';
import agentClientsReducer from './agentClientsSlice';
import agentMatchesReducer from './agentMatchesSlice';
import agentRatingsReducer from './agentRatingsSlice';
import agentConnectionPropertiesReducer from './agentConnectionPropertiesSlice';
import agentConnectionSearchesReducer from './agentConnectionSearchesSlice';
import notificationsReducer from './notificationsSlice';
import agentsReducer from './agentsSlice';
import heatMapDashboardReducer from './heatMapDashboardSlice';
import adminAgentsConnectionsDashboardReducer from './adminAgentsConnectionsDashboardSlice';
import agentConnectionsDashboardReducer from './agentConnectionsDashboardSlice';
import adminStatesScorecardReducer from './adminStatesScorecardSlice';
import adminAgentsListReducer from './adminAgentsListSlice';

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: {
    authModals: authModalsReducer,
    users: usersReducer,
    searches: searchesReducer,
    buyerSearches: buyerSearchesReducer,
    cities: citiesReducer,
    searchFilters: searchFiltersReducer,
    connections: connectionsReducer,
    connectionProperties: connectionPropertiesReducer,
    states: statesReducer,
    zipCodes: zipCodesReducer,
    sellerProperties: sellerPropertiesReducer,
    availableProperties: availablePropertiesReducer,
    sellerConnections: sellerConnectionsReducer,
    sellerSearches: sellerSearchesReducer,
    sellerFavoriteSearches: sellerFavoriteSearchesReducer,
    adminDashboard: adminDashboardReducer,
    adminConnections: adminConnectionsReducer,
    adminAgents: adminAgentsReducer,
    adminProperties: adminPropertiesReducer,
    adminUsers: adminUsersReducer,
    adminBuyer: adminBuyerReducer,
    adminBuyerSearches: adminBuyerSearchesReducer,
    adminBuyerConnections: adminBuyerConnectionsReducer,
    adminAgentSearches: adminAgentSearchesReducer,
    adminAgentConnections: adminAgentConnectionsReducer,
    adminSearches: adminSearchesReducer,
    adminSeller: adminSellerReducer,
    adminSellerConnections: adminSellerConnectionsReducer,
    adminSellerProperties: adminSellerPropertiesReducer,
    adminReviews: adminReviewsReducer,
    adminFeedbackDashboard: adminFeedbackDashboardReducer,
    agentDashboard: agentDashboardReducer,
    agentConnections: agentConnectionsReducer,
    agentSearches: agentSearchesReducer,
    agentBuyers: agentBuyersReducer,
    agentProperties: agentPropertiesReducer,
    agentSellers: agentSellersReducer,
    agentSellerProperties: agentSellerPropertiesReducer,
    agentBuyerSearches: agentBuyerSearchesReducer,
    agentClients: agentClientsReducer,
    agentMatches: agentMatchesReducer,
    agentRatings: agentRatingsReducer,
    agentConnectionProperties: agentConnectionPropertiesReducer,
    agentConnectionSearches: agentConnectionSearchesReducer,
    notifications: notificationsReducer,
    agents: agentsReducer,
    heatMapDashboard: heatMapDashboardReducer,
    adminAgentsConnectionsDashboard: adminAgentsConnectionsDashboardReducer,
    agentConnectionsDashboard: agentConnectionsDashboardReducer,
    adminStatesScorecard: adminStatesScorecardReducer,
    adminAgentsList: adminAgentsListReducer,
    sellerMatches: sellerMatchesReducer,
  },
});
