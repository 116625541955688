import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import ConnectionsDashboardRepository from 'repositories/Admin/ConnectionsDashboardRepository';
import ConnectionsDashboard from 'types/resources/connectionsDashboard';

export type AdminAgentsConnectionsDashboardSliceStateType = {
  adminAgentsConnectionsDashboard: {
    data: ConnectionsDashboard;
    fetchStatus: FetchStatus;
  };
};

export type AdminAgentsConnectionsDashboardSliceActionsType = {
  loadAdminAgentsConnectionsDashboard: () => void;
  resetAdminAgentsConnectionsDashboard: () => void;
};

const initialState: AdminAgentsConnectionsDashboardSliceStateType = {
  adminAgentsConnectionsDashboard: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminAgentsConnectionsDashboardSlice = createSlice({
  name: 'adminAgentsConnectionsDashboard',
  initialState,
  reducers: {
    loadAdminAgentsConnectionsDashboardStart: (state): void => {
      state.adminAgentsConnectionsDashboard.fetchStatus = FetchStatus.pending;
    },
    loadAdminAgentsConnectionsDashboardFinish: (state, { payload }): void => {
      const { adminConnectionsDashboard } = payload;

      state.adminAgentsConnectionsDashboard.fetchStatus = FetchStatus.fulfilled;
      state.adminAgentsConnectionsDashboard.data = adminConnectionsDashboard;
    },
    loadAdminAgentsConnectionsDashboardFail: (state): void => {
      state.adminAgentsConnectionsDashboard.fetchStatus = FetchStatus.failed;
    },

    resetAdminAgentsConnectionsDashboard: (): AdminAgentsConnectionsDashboardSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminAgentsConnectionsDashboardSlice;

export const useAdminAgentsConnectionsDashboardActions = (): AdminAgentsConnectionsDashboardSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminAgentsConnectionsDashboard = async () => {
    dispatch(actions.loadAdminAgentsConnectionsDashboardStart());
    try {
      const adminAgentsConnectionsDashboard = await ConnectionsDashboardRepository.show();
      dispatch(actions.loadAdminAgentsConnectionsDashboardFinish(adminAgentsConnectionsDashboard));
    } catch (error: unknown) {
      dispatch(actions.loadAdminAgentsConnectionsDashboardFail());
    }
  };

  const resetAdminAgentsConnectionsDashboard = () => dispatch(actions.resetAdminAgentsConnectionsDashboard());

  return {
    loadAdminAgentsConnectionsDashboard,
    resetAdminAgentsConnectionsDashboard,
  };
};

export default AdminAgentsConnectionsDashboardSlice.reducer;
