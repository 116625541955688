import { find, pathOr } from 'ramda';
import { DOCUMENT_TYPES } from 'presenters/BudgetPresenter';
import VerificationFile from 'types/resources/verificationFile';

export const documentTypeLabel = (verificationFile: VerificationFile): string => {
  const { documentType } = verificationFile;

  const currentType = find((item) => item.id === documentType, DOCUMENT_TYPES);
  const label = pathOr('–', ['name'], currentType);

  return label;
};
