import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Property from 'types/resources/property';
import { PropertyFormDataToSubmit } from 'forms/propertyForm';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import { Meta } from 'types/meta';

export default {
  index(id: ID, params: PropertyFiltersParams): Promise<{ properties: Property[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1UserAgentSellerPropertiesPath(id);

    return Fetcher.get(url, params);
  },
  create(sellerId: ID, params: PropertyFormDataToSubmit): Promise<{ property: Property }> {
    const url = apiRoutes.apiWebV1UserAgentSellerPropertiesPath(sellerId);

    return Fetcher.postFormData(url, params);
  },
};
