import { formatBytes } from 'utils/fileHelper';

export const REQUIRED_ERROR_TEXT = 'cannot be blank';
export const EMAIL_ERROR_TEXT = 'should be email';
export const PASSWORDS_DONT_MATCHES = "passwords don't match";
export const NOT_EMPTY_ERROR_TEXT = 'must have at least 1 item';
export const NO_BANK_INFORMATION_ERROR_TEXT =
  'Please do not upload documents with personal data (social security number, bank account info, etc.) Required document must be a pre approval letter from a bank or other lending institution';
export const MAP_ERROR_TEXT = 'Something went wrong with the map';

export const READ_FILE_ERROR_TEXT = 'Something went wrong while reading file';
export const UPLOAD_FILE_ERROR_TEXT = 'Something went wrong while uploading file';
export const maxFileSizeError = (bytes: number): string => `File size can't be larger than ${formatBytes(bytes)}`;

export const minLengthErrorText = (num: number): string => `should be longer than ${num}`;
export const maxLengthErrorText = (num: number): string => `shouldn't be longer than ${num}`;
export const minValueErrorText = (field: string): string => `should be more than ${field}`;
