import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';
import Connection from 'types/resources/connection';

export default {
  index(id: ID, params: ConnectionFiltersParams): Promise<{ connections: Connection[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1AdminUserBuyerConnectionsPath(id);

    return Fetcher.get(url, params);
  },
};
