import HeatMapDashboard from 'types/resources/heatMapDashboard';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { HeatMapFilterParams } from 'forms/heatMapFiltersForm';

export default {
  show(params: HeatMapFilterParams): Promise<HeatMapDashboard> {
    const url = apiRoutes.apiWebV1HeatMapPath();

    return Fetcher.get(url, params);
  },
};
