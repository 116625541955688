import { FC, lazy } from 'react';
import CommonLayout from 'layouts/CommonLayout';
import User from 'types/resources/user';
import { UserType } from 'enums/UserType';
import { UsersPresenter } from 'presenters';
import { InactiveUserPage, UnconfirmedUserPage } from 'pages/client';
import useLocalStorage from 'hooks/useLocalStorage';
import { appRoutes } from 'routes';
import { match, last, split, isEmpty } from 'ramda';

const BuyerSwitch = lazy(() => import('./components/BuyerSwitch'));
const SellerSwitch = lazy(() => import('./components/SellerSwitch'));
const AgentSwitch = lazy(() => import('./components/AgentSwitch'));
const AdminSwitch = lazy(() => import('./components/AdminSwitch'));

type UserSwitchPropsType = {
  user: User;
};

const UserSwitch: FC<UserSwitchPropsType> = (props: UserSwitchPropsType) => {
  const { user } = props;
  const [path] = useLocalStorage<string>('pathToRedirect', '');

  const getRedirectPath = (): string => {
    if (path && UsersPresenter.isConfirmed(user)) {
      const pathMatch = match(new RegExp(appRoutes.allSearchesPath()), path);

      if (!isEmpty(pathMatch)) {
        const searchParams = last(split('?', path));

        return appRoutes.browseBuyersPath(searchParams);
      }

      return path === '/' ? null : path;
    }

    return null;
  };

  const pathToRedirect = getRedirectPath();

  const renderContent = () => {
    if (UsersPresenter.isInactive(user)) return <InactiveUserPage />;
    if (UsersPresenter.isUnconfirmed(user)) return <UnconfirmedUserPage />;

    switch (user.type) {
      case UserType.buyer: {
        return <BuyerSwitch />;
      }
      case UserType.seller: {
        return <SellerSwitch pathToRedirect={pathToRedirect} />;
      }
      case UserType.agent: {
        return <AgentSwitch pathToRedirect={pathToRedirect} />;
      }
      case UserType.admin: {
        return <AdminSwitch />;
      }
      default: {
        return null;
      }
    }
  };

  return <CommonLayout>{renderContent()}</CommonLayout>;
};

export default UserSwitch;
