import { PropertyCondition } from 'enums/PropertyCondition';
import { EmptyOption } from 'enums/EmptyOption';
import { FinancingType } from 'enums/FinancingType';
import { PropertyType } from 'enums/PropertyType';
import { YesNo } from 'enums/YesNo';
import { TimeToClose } from 'enums/TimeToClose';

export const ZOOM_FOR_ZIP_CODE = 14;
export const TOOLTIP_LEAVE_DELAY = 200;
export const ATTENTION_REQUIRED_PARAM_KEY = 'attention-required';

export const EMPTY_OPTION: { id: EmptyOption; name: string } = {
  id: EmptyOption.none,
  name: '--------',
};

export const ALL_OPTION: { id: EmptyOption; name: string } = {
  id: EmptyOption.none,
  name: 'All',
};

export const YES_NO: { id: boolean; name: YesNo }[] = [
  { id: true, name: YesNo.yes },
  { id: false, name: YesNo.no },
];

export const PROPERTY_TYPES: { id: PropertyType; name: string }[] = [
  { id: PropertyType.singleFamily, name: 'Single family' },
  { id: PropertyType.condoOrTownhouse, name: 'Condo/townhouse' },
  { id: PropertyType.manufacturedOrMobile, name: 'Manufactured (mobile)' },
  { id: PropertyType.modular, name: 'Modular' },
  { id: PropertyType.multiFamily, name: 'Multi family' },
  { id: PropertyType.commercial, name: 'Commercial' },
];

export const PROPERTY_CONDITIONS: { id: PropertyCondition; name: string }[] = [
  { id: PropertyCondition.newBuild, name: 'New Build' },
  { id: PropertyCondition.lessThanFiveYearsOld, name: 'Less than 5 years old' },
  { id: PropertyCondition.fiveToFifteenYearsOld, name: '5-15 years old' },
  { id: PropertyCondition.fullyRenovated, name: 'Fully Renovated' },
  { id: PropertyCondition.renovationReady, name: 'Renovation Ready' },
];

export const FINANCING_TYPES: { id: FinancingType; name: string }[] = [
  { id: FinancingType.cash, name: 'Cash' },
  { id: FinancingType.conventionalMortgage, name: 'Conventional Mortgage' },
  { id: FinancingType.fha, name: 'FHA' },
  { id: FinancingType.usda, name: 'USDA' },
  { id: FinancingType.va, name: 'VA' },
  { id: FinancingType.commercialMortgage, name: 'Commercial Mortgage' },
  { id: FinancingType.ownerFinanced, name: 'Owner Financed' },
];

export const TIME_TO_CLOSE: { id: TimeToClose; name: string }[] = [
  { id: TimeToClose.lessThanSixtyDays, name: 'less than 60 days' },
  { id: TimeToClose.sixtyNinetyDays, name: '60-90 days' },
  { id: TimeToClose.moreThanNinetyDays, name: 'more than 90 days' },
];
