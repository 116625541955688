import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiDividerType = {
  defaultProps: ComponentsProps['MuiDivider'];
  styleOverrides: ComponentsOverrides['MuiDivider'];
};

const MuiDivider: MuiDividerType = {
  styleOverrides: {
    root: {
      borderColor: '#D2D3CF',
    },
  },
  defaultProps: {},
};

export default MuiDivider;
