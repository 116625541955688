import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import User from 'types/resources/user';
import { Meta } from 'types/meta';
import { AgentFiltersParams } from 'forms/assignAgentFiltersForm';
import AgentsListRepository from 'repositories/Admin/AgentsListRepository';

export type AdminAgentsListSliceStateType = {
  adminAgentsList: {
    data: User[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
  };
};

export type AdminAgentsListSliceActionsType = {
  loadAdminAgentsList: (params?: AgentFiltersParams) => void;
  resetAdminAgentsList: () => void;
};

const initialState: AdminAgentsListSliceStateType = {
  adminAgentsList: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminAgentsListSlice = createSlice({
  name: 'adminAgentsList',
  initialState,
  reducers: {
    loadAdminAgentsListStart: (state): void => {
      state.adminAgentsList.fetchStatus = FetchStatus.pending;
    },
    loadAdminAgentsListFinish: (state, { payload }): void => {
      const { user, meta } = payload;

      state.adminAgentsList.fetchStatus = FetchStatus.fulfilled;
      state.adminAgentsList.data = user;
      state.adminAgentsList.meta = meta;
    },
    loadAdminAgentsListFail: (state): void => {
      state.adminAgentsList.fetchStatus = FetchStatus.failed;
    },
    resetAdminAgentsList: (): AdminAgentsListSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminAgentsListSlice;

export const useAdminAgentsListActions = (): AdminAgentsListSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminAgentsList = async (params?: AgentFiltersParams) => {
    dispatch(actions.loadAdminAgentsListStart());
    try {
      const agents = await AgentsListRepository.index(params);
      dispatch(actions.loadAdminAgentsListFinish(agents));
    } catch (error: unknown) {
      dispatch(actions.loadAdminAgentsListFail());
    }
  };

  const resetAdminAgentsList = () => dispatch(actions.resetAdminAgentsList());

  return {
    loadAdminAgentsList,
    resetAdminAgentsList,
  };
};

export default AdminAgentsListSlice.reducer;
