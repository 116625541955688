import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  content: {
    width: 400,
    padding: 5,
    boxSizing: 'content-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default styles;
