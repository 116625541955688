import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { Meta } from 'types/meta';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';
import SearchesRepository from 'repositories/Admin/User/Agent/SearchesRepository';
import Search from 'types/resources/search';

export type AdminAgentSearchesSliceStateType = {
  searches: {
    data: Search[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
};

export type AdminAgentSearchesSliceActionsType = {
  loadSearches: (id: ID, params: SearchFiltersFormDataParams) => void;
  resetSearches: () => void;
};

const initialState: AdminAgentSearchesSliceStateType = {
  searches: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminAgentSearchesSlice = createSlice({
  name: 'adminAgentSearches',
  initialState,
  reducers: {
    loadSearchesStart: (state): void => {
      state.searches.fetchStatus = FetchStatus.pending;
    },
    loadSearchesFinish: (state, { payload }): void => {
      const { searches, meta } = payload;
      state.searches.fetchStatus = FetchStatus.fulfilled;
      state.searches.data = searches;
      state.searches.meta = meta;
    },
    loadSearchesFail: (state): void => {
      state.searches.fetchStatus = FetchStatus.failed;
    },

    resetSearches: (): AdminAgentSearchesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminAgentSearchesSlice;

export const useAdminAgentSearchesActions = (): AdminAgentSearchesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadSearches = async (id: ID, params: SearchFiltersFormDataParams) => {
    dispatch(actions.loadSearchesStart());
    try {
      const users = await SearchesRepository.index(id, params);
      dispatch(actions.loadSearchesFinish(users));
    } catch (error: unknown) {
      dispatch(actions.loadSearchesFail());
    }
  };

  const resetSearches = () => dispatch(actions.resetSearches());

  return {
    loadSearches,
    resetSearches,
  };
};

export default AdminAgentSearchesSlice.reducer;
