import { createSlice } from '@reduxjs/toolkit';
import ConnectionPropertiesRepository from 'repositories/Buyer/Connection/PropertyRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import ConnectionProperty from 'types/resources/connection/property';

export type ConnectionPropertiesSliceStateType = {
  connectionProperty: ConnectionProperty | null;
  showConnectionProperty: { fetchStatus: FetchStatus };
};

export type ConnectionPropertiesSliceActionsType = {
  loadConnectionProperty: (connectionId: ID) => void;
  resetConnectionProperty: () => void;
};

const initialState: ConnectionPropertiesSliceStateType = {
  connectionProperty: null,
  showConnectionProperty: { fetchStatus: FetchStatus.idle },
};

const ConnectionPropertiesSlice = createSlice({
  name: 'connectionProperties',
  initialState,
  reducers: {
    loadConnectionPropertyStart: (state): void => {
      state.showConnectionProperty.fetchStatus = FetchStatus.pending;
    },
    loadConnectionPropertyFinish: (state, { payload }): void => {
      state.showConnectionProperty.fetchStatus = FetchStatus.fulfilled;
      state.connectionProperty = payload;
    },
    loadConnectionPropertyFail: (state): void => {
      state.showConnectionProperty.fetchStatus = FetchStatus.failed;
    },
    resetConnectionProperty: (): ConnectionPropertiesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = ConnectionPropertiesSlice;

export const useConnectionPropertiesActions = (): ConnectionPropertiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadConnectionProperty = async (connectionId: ID) => {
    dispatch(actions.loadConnectionPropertyStart());
    try {
      const connectionProperty = await ConnectionPropertiesRepository.show(connectionId);
      dispatch(actions.loadConnectionPropertyFinish(connectionProperty));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionPropertyFail());
    }
  };

  const resetConnectionProperty = () => dispatch(actions.resetConnectionProperty());

  return {
    loadConnectionProperty,
    resetConnectionProperty,
  };
};

export default ConnectionPropertiesSlice.reducer;
