import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import ConnectionProperty from 'types/resources/property';

export default {
  show(connectionId: ID): Promise<ConnectionProperty> {
    const url = apiRoutes.apiWebV1UserBuyerConnectionPropertyPath(connectionId);

    return Fetcher.get(url);
  },
};
