import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiFormHelperTextType = {
  defaultProps: ComponentsProps['MuiFormHelperText'];
  styleOverrides: ComponentsOverrides['MuiFormHelperText'];
};

const MuiFormHelperText: MuiFormHelperTextType = {
  styleOverrides: {
    root: {
      margin: '0 5px',
      letterSpacing: '-0.6px',
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
  },
  defaultProps: {},
};

export default MuiFormHelperText;
