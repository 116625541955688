import { format, parseISO, startOfDay, endOfDay } from 'date-fns/fp';
import { isNil } from 'ramda';

const STANDART_FORMAT = 'MM/dd/yy';
const STANDART_FORMAT_WITH_FULL_YEAR = 'MM/dd/yyyy';
const FORMAT_WITH_FULL_YEAR = 'd MMM, yyyy';

export const NOW = new Date();

export const formattedDate = (date: string): string => {
  return format(STANDART_FORMAT, parseISO(date));
};

export const formattedLocaleDate = (date: string): string => {
  return format(STANDART_FORMAT_WITH_FULL_YEAR, parseISO(date));
};

export const fullDate = (date: string): string => {
  return format(FORMAT_WITH_FULL_YEAR, parseISO(date));
};

export const formatToISOString = (date: Date): string => {
  return date.toISOString();
};

export const formattedStartOfDay = (date: Date): string => {
  return startOfDay(date).toISOString();
};

export const formattedEndOfDay = (date: Date): string => {
  return endOfDay(date).toISOString();
};

export const isValidDate = (date: Date): boolean => {
  return !isNil(date) && !Number.isNaN(date.getTime());
};

export const currentYear = (): string => {
  return NOW.getFullYear().toString();
};

export const lastYear = (): string => {
  return (Number(NOW.getFullYear()) - 1).toString();
};
