import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useRedux';
import CurrentUserRepository from 'repositories/CurrentUserRepository';
import User from 'types/resources/user';
import { FetchStatus } from 'enums/FetchStatus';
import { UserMeta } from 'types/meta';

export type InitSliceStateType = {
  currentUser: User | null;
  currentUserMeta: UserMeta | null;
  currentUserFetchStatus: FetchStatus;
};

export type UsersSliceActionsType = {
  loadCurrentUser: () => Promise<void>;
  destroyCurrentUser: () => void;
};

const initialState: InitSliceStateType = {
  currentUser: null,
  currentUserMeta: null,
  currentUserFetchStatus: FetchStatus.idle,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loadCurrentUserStart: (state): void => {
      state.currentUserFetchStatus = FetchStatus.pending;
    },
    loadCurrentUserSuccess: (state, { payload }): void => {
      const { user, meta } = payload;

      state.currentUserFetchStatus = FetchStatus.fulfilled;
      state.currentUser = user;
      state.currentUserMeta = meta;
    },
    loadCurrentUserFail: (state): void => {
      state.currentUserFetchStatus = FetchStatus.failed;
      state.currentUser = null;
    },
    destroyCurrentUser: (state): void => {
      state.currentUser = null;
      state.currentUserMeta = null;
    },
  },
});

const { actions } = usersSlice;

export const useUsersActions = (): UsersSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadCurrentUser = async () => {
    dispatch(actions.loadCurrentUserStart());
    try {
      const currentUser = await CurrentUserRepository.show();
      dispatch(actions.loadCurrentUserSuccess(currentUser));
    } catch (e) {
      dispatch(actions.loadCurrentUserFail());
    }
  };

  const destroyCurrentUser = () => {
    dispatch(actions.destroyCurrentUser());
  };

  return { loadCurrentUser, destroyCurrentUser };
};

export default usersSlice.reducer;
