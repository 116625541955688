import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import AdminFeedbackDashboard from 'types/resources/adminFeedbackDashboard';

export default {
  show(): Promise<AdminFeedbackDashboard> {
    const url = apiRoutes.apiWebV1AdminFeedbackDashboardPath();
    return Fetcher.get(url);
  },
};
