import { FC } from 'react';
import { SxProps } from '@material-ui/system';
import { SvgIcon } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

type ArrowDropDownProps = {
  sx?: SxProps<Theme>;
};

const ArrowDropDown: FC<ArrowDropDownProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} width="29" height="17" viewBox="0 0 29 17" fill="none">
      <path d="M1.87305 0.755859L27.873 0.755859L14.873 15.7559L1.87305 0.755859Z" fill="#F4F4F4" />
      <path d="M14.5 15.0108L26.7442 1.5L2.25581 1.5L14.5 15.0108ZM14.5 16.5L0 0.5L29 0.5L14.5 16.5Z" fill="#707070" />
    </SvgIcon>
  );
};

export default ArrowDropDown;
