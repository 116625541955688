import { AdminStatesScorecardFiltersParams } from 'forms/adminStatesScorecardFiltersForm';
import { apiRoutes } from 'routes';
import { Meta } from 'types/meta';
import StateScorecard from 'types/resources/stateScorecard';
import Fetcher from 'utils/fetcher';

export default {
  index(params?: AdminStatesScorecardFiltersParams): Promise<{ states: StateScorecard[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1AdminStatesPath();

    return Fetcher.get(url, params);
  },
};
