import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  footer: {
    flexShrink: 0,
    backgroundColor: 'waveGrey',
    color: 'white',
    zIndex: 1,
  },
  socialInfo: {
    textAlign: 'center',
    marginBottom: '45px',
  },
  title: {
    marginTop: 6,
  },
  linkButton: {
    display: 'block',
  },
  link: {
    marginBottom: 1.5,
    textTransform: 'uppercase',
    color: 'white',
  },
  icon: {
    color: 'mayGreen',
    fontSize: '42px',
    marginTop: '32px',
  },
  iconGoWrapper: {
    marginBottom: '-20%',
    transform: 'translateY(-50%)',
  },
  iconGo: {
    width: '100px',
    height: '100px',
    color: '#D7E8EC',
  },
  info: {
    marginBottom: '32px',
  },
};

export default styles;
