import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    minWidth: 250,
    borderStyle: 'solid',
    borderLeftWidth: '1px',
    borderColor: 'richGrey',
    minHeight: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 2,
  },
  icon: {
    color: 'richGrey',
    fontSize: '14px',
    marginTop: 1,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    minWidth: 120,
    paddingY: 2,
  },
  activeMenuItem: {
    backgroundColor: 'waveLight',
  },
  logout: {
    fontSize: 14,
  },
  avatar: {
    width: '40px',
    height: '40px',
  },
  text: {
    width: '100%',
  },
  zigZagoIcon: {
    fontSize: '40px',
  },
  userInfo: {
    color: 'gray',
    marginRight: 2,
  },
  userType: {
    marginTop: 0.5,
    fontStyle: 'italic',
    color: 'rgba(112, 112, 112, 0.6)',
  },
  icons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

export default styles;
