import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import User from 'types/resources/user';
import { Meta } from 'types/meta';
import { AgentFiltersParams } from 'forms/assignAgentFiltersForm';
import AgentsRepository from 'repositories/AgentsRepository';

export type AgentsSliceStateType = {
  agents: {
    data: User[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
  };
};

export type AgentsSliceActionsType = {
  loadAgents: (params?: AgentFiltersParams) => void;
  resetAgents: () => void;
};

const initialState: AgentsSliceStateType = {
  agents: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AgentsSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    loadAgentsStart: (state): void => {
      state.agents.fetchStatus = FetchStatus.pending;
    },
    loadAgentsFinish: (state, { payload }): void => {
      const { users, meta } = payload;

      state.agents.fetchStatus = FetchStatus.fulfilled;
      state.agents.data = users;
      state.agents.meta = meta;
    },
    loadAgentsFail: (state): void => {
      state.agents.fetchStatus = FetchStatus.failed;
    },
    resetAgents: (): AgentsSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AgentsSlice;

export const useAgentsActions = (): AgentsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAgents = async (params?: AgentFiltersParams) => {
    dispatch(actions.loadAgentsStart());
    try {
      const agents = await AgentsRepository.index(params);
      dispatch(actions.loadAgentsFinish(agents));
    } catch (error: unknown) {
      dispatch(actions.loadAgentsFail());
    }
  };

  const resetAgents = () => dispatch(actions.resetAgents());

  return {
    loadAgents,
    resetAgents,
  };
};

export default AgentsSlice.reducer;
