import License from 'types/resources/license';
import { LicenseRecordType } from 'forms/agentProfileForm';
import { VerificationStatus } from 'enums/VerificationStatus';

export const REJECTION_OPTIONS = [
  { id: 'reason1', name: 'Your license is declined. Please, reach out to find out why' },
  { id: 'reason2', name: 'Please, confirm your NRDS number' },
  { id: 'reason3', name: 'Your licence number is incorrect' },
  { id: 'reason4', name: 'Your license expiration date is incorrect' },
];

export const isLicenseVerified = (license: License | LicenseRecordType): boolean => {
  return license.verificationStatus === VerificationStatus.verified;
};

export const isLicenseUnverified = (license: License | LicenseRecordType): boolean => {
  return license.verificationStatus === VerificationStatus.unverified;
};

export const isLicenseDeclined = (license: License | LicenseRecordType): boolean => {
  return license.verificationStatus === VerificationStatus.declined;
};
