import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  toolbar: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  menuItem: {
    color: 'wave',
  },
  buttonSignUp: {
    color: 'gray',
    backgroundColor: 'waveLight',
    textTransform: 'uppercase',
  },
  buttonSignIn: {
    marginLeft: 3,
    color: 'white',
    backgroundColor: 'wave',
    textTransform: 'uppercase',
    '&:hover': {
      color: 'gray',
      backgroundColor: 'shadeWave',
    },
  },
};

export default styles;
