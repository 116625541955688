import { isNil } from 'ramda';
import Budget from 'types/resources/budget';
import { VerificationStatus } from 'enums/VerificationStatus';
import { DocumentType } from 'enums/DocumentType';

export const DOCUMENT_TYPES = [
  { id: DocumentType.mortgage, name: 'Mortgage letter' },
  { id: DocumentType.preQualifiedLetter, name: 'Pre-qualified letter' },
];

export const REJECTION_OPTIONS = [
  { id: 'reason1', name: 'Your budget amount does not match the budget in documents that you provided' },
  { id: 'reason2', name: 'Your verifying documents do not match their type' },
  { id: 'reason3', name: 'Your verifying documents do not show the budget' },
];

export const isBudgetVerified = (budget: Budget): boolean => {
  return budget.verificationStatus === VerificationStatus.verified;
};

export const isBudgetUnverified = (budget: Budget): boolean => {
  return budget.verificationStatus === VerificationStatus.unverified;
};

export const isBudgetDeclined = (budget: Budget): boolean => {
  return budget.verificationStatus === VerificationStatus.declined;
};

export const description = (budget: Budget): string => {
  if (isNil(budget)) {
    return 'Tell us your budget and send proof to admin';
  }
  if (isBudgetUnverified(budget)) {
    return 'You submitted your budget for approval. Later you can edit it in your Profile.';
  }
  if (isBudgetVerified(budget)) {
    return 'Your Budget is approved. You can edit it in your Profile. ';
  }
  return '';
};
