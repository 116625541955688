import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { Meta } from 'types/meta';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import Property from 'types/resources/property';
import PropertiesRepository from 'repositories/Admin/User/Seller/PropertiesRepository';

export type AdminSellerPropertiesSliceStateType = {
  properties: {
    data: Property[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
};

export type AdminSellerPropertiesSliceActionsType = {
  loadProperties: (id: ID, params: PropertyFiltersParams) => void;
  resetProperties: () => void;
};

const initialState: AdminSellerPropertiesSliceStateType = {
  properties: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminSellerPropertiesSlice = createSlice({
  name: 'adminSellerProperties',
  initialState,
  reducers: {
    loadPropertiesStart: (state): void => {
      state.properties.fetchStatus = FetchStatus.pending;
    },
    loadPropertiesFinish: (state, { payload }): void => {
      const { properties, meta } = payload;
      state.properties.fetchStatus = FetchStatus.fulfilled;
      state.properties.data = properties;
      state.properties.meta = meta;
    },
    loadPropertiesFail: (state): void => {
      state.properties.fetchStatus = FetchStatus.failed;
    },

    resetProperties: (): AdminSellerPropertiesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminSellerPropertiesSlice;

export const useAdminSellerPropertiesActions = (): AdminSellerPropertiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadProperties = async (id: ID, params: PropertyFiltersParams) => {
    dispatch(actions.loadPropertiesStart());
    try {
      const users = await PropertiesRepository.index(id, params);
      dispatch(actions.loadPropertiesFinish(users));
    } catch (error: unknown) {
      dispatch(actions.loadPropertiesFail());
    }
  };

  const resetProperties = () => dispatch(actions.resetProperties());

  return {
    loadProperties,
    resetProperties,
  };
};

export default AdminSellerPropertiesSlice.reducer;
