import { AccountNewUserType, AccountSwitchUserType } from 'forms/accountForm';
import { SignUpFormDataToSubmit } from 'forms/signUpForm';
import AccountsRepository from 'repositories/AccountsRepository';
import UsersRepository from 'repositories/UsersRepository';

type UseAccountsType = {
  createAccount: (params: SignUpFormDataToSubmit) => void;
  confirmAccount: (confirmationToken: string) => void;
  resendAccountConfirmation: () => void;
  switchType: (params: AccountSwitchUserType) => void;
  addType: (params: AccountNewUserType) => void;
};

const useAccounts = (): UseAccountsType => {
  const createAccount = (params: SignUpFormDataToSubmit) => AccountsRepository.create(params);
  const confirmAccount = (confirmationToken: string) => AccountsRepository.confirm(confirmationToken);
  const resendAccountConfirmation = () => AccountsRepository.resendConfirmation();

  const switchType = (params: AccountSwitchUserType) => AccountsRepository.switchType(params);
  const addType = (params: AccountNewUserType) => UsersRepository.create(params);

  return {
    createAccount,
    confirmAccount,
    resendAccountConfirmation,
    switchType,
    addType,
  };
};

export default useAccounts;
