import Fetcher from 'utils/fetcher';
import { apiRoutes } from 'routes';
import User from 'types/resources/user';
import { UserMeta } from 'types/meta';

const CurrentUserRepository = {
  show(): Promise<{ user: User; meta: UserMeta }> {
    const url = apiRoutes.apiWebV1CurrentUserPath();

    return Fetcher.get(url);
  },
};

export default CurrentUserRepository;
