import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import Property from 'types/resources/property';
import PropertiesRepository from 'repositories/Agent/PropertiesRepository';
import { Meta } from 'types/meta';
import { PropertyEvent } from 'enums/PropertyEvent';
import { ImportResponseType } from 'types/importResponse';
import { PropertiesImportRecordType } from 'types/importRecord';

export type AgentPropertiesSliceStateType = {
  agentProperties: {
    data: Property[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
  };
  agentProperty: {
    fetchStatus: FetchStatus;
    data: Property | null;
  };
  agentPropertyPhaseChanged: {
    fetchStatus: FetchStatus;
  };
  agentPropertyArchived: {
    fetchStatus: FetchStatus;
  };
  importRecords: {
    userId: ID;
    data: PropertiesImportRecordType[];
    responseData: ImportResponseType[];
    fetchStatus: FetchStatus;
  };
};

export type AgentPropertiesSliceActionsType = {
  loadAgentProperties: (params: PropertyFiltersParams) => void;
  resetAgentProperties: () => void;
  loadAgentProperty: (id: ID) => void;
  loadMatchesStatus: (id: ID) => Promise<Property>;
  changeAgentPropertyPhase: (id: ID, phase: PropertyEvent) => void;
  archiveAgentProperty: (id: ID) => void;
  importProperties: (userId: ID, data: PropertiesImportRecordType[]) => void;
  setImportRecords: (userId: ID, data: PropertiesImportRecordType[]) => void;
};

const initialState: AgentPropertiesSliceStateType = {
  agentProperties: {
    fetchStatus: FetchStatus.idle,
    data: [],
    meta: null,
  },
  agentProperty: {
    fetchStatus: FetchStatus.idle,
    data: null,
  },
  agentPropertyPhaseChanged: {
    fetchStatus: FetchStatus.idle,
  },
  agentPropertyArchived: {
    fetchStatus: FetchStatus.idle,
  },
  importRecords: {
    userId: null,
    data: [],
    responseData: [],
    fetchStatus: FetchStatus.idle,
  },
};

const AgentPropertiesSlice = createSlice({
  name: 'agentProperties',
  initialState,
  reducers: {
    loadPropertiesStart: (state): void => {
      state.agentProperties.fetchStatus = FetchStatus.pending;
    },
    loadPropertiesFinish: (state, { payload }): void => {
      const { properties, meta } = payload;

      state.agentProperties.fetchStatus = FetchStatus.fulfilled;
      state.agentProperties.data = properties;
      state.agentProperties.meta = meta;
    },
    loadPropertiesFail: (state): void => {
      state.agentProperties.fetchStatus = FetchStatus.failed;
    },
    resetAgentProperties: (): AgentPropertiesSliceStateType => {
      return initialState;
    },
    loadAgentPropertyStart: (state): void => {
      state.agentProperty.fetchStatus = FetchStatus.pending;
    },
    loadAgentPropertyFinish: (state, { payload }): void => {
      const { property } = payload;

      state.agentProperty.fetchStatus = FetchStatus.fulfilled;
      state.agentProperty.data = property;
    },
    loadAgentPropertyFail: (state): void => {
      state.agentProperty.fetchStatus = FetchStatus.failed;
    },
    changeAgentPropertyPhaseStart: (state): void => {
      state.agentPropertyPhaseChanged.fetchStatus = FetchStatus.pending;
    },
    changeAgentPropertyPhaseFinish: (state): void => {
      state.agentPropertyPhaseChanged.fetchStatus = FetchStatus.fulfilled;
    },
    changeAgentPropertyPhaseFail: (state): void => {
      state.agentPropertyPhaseChanged.fetchStatus = FetchStatus.failed;
    },
    archiveAgentPropertyStart: (state): void => {
      state.agentPropertyArchived.fetchStatus = FetchStatus.pending;
    },
    archiveAgentPropertyFinish: (state): void => {
      state.agentPropertyArchived.fetchStatus = FetchStatus.fulfilled;
    },
    archiveAgentPropertyFail: (state): void => {
      state.agentPropertyArchived.fetchStatus = FetchStatus.failed;
    },

    setImportData: (state, { payload }): void => {
      state.importRecords.fetchStatus = FetchStatus.idle;
      state.importRecords.userId = payload.userId;
      state.importRecords.data = payload.data;
    },
    importPropertiesStart: (state): void => {
      state.importRecords.fetchStatus = FetchStatus.pending;
    },
    importPropertiesFinish: (state, { payload }): void => {
      state.importRecords.fetchStatus = FetchStatus.fulfilled;
      state.importRecords.responseData = payload;
    },
    importPropertiesFail: (state): void => {
      state.importRecords.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = AgentPropertiesSlice;

export const useAgentPropertiesActions = (): AgentPropertiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAgentProperties = async (params: PropertyFiltersParams = {}) => {
    dispatch(actions.loadPropertiesStart());
    try {
      const properties = await PropertiesRepository.index(params);
      dispatch(actions.loadPropertiesFinish(properties));
    } catch (error: unknown) {
      dispatch(actions.loadPropertiesFail());
    }
  };

  const loadAgentProperty = async (id: ID) => {
    dispatch(actions.loadAgentPropertyStart());
    try {
      const search = await PropertiesRepository.show(id);
      dispatch(actions.loadAgentPropertyFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadAgentPropertyFail());
    }
  };

  const resetAgentProperties = () => dispatch(actions.resetAgentProperties());

  const changeAgentPropertyPhase = async (id: ID, event: PropertyEvent) => {
    dispatch(actions.changeAgentPropertyPhaseStart());
    try {
      await PropertiesRepository.changePhase(id, event);
      dispatch(actions.changeAgentPropertyPhaseFinish());
    } catch (error: unknown) {
      dispatch(actions.changeAgentPropertyPhaseFail());
    }
  };

  const archiveAgentProperty = async (id: ID) => {
    dispatch(actions.archiveAgentPropertyStart());
    try {
      await PropertiesRepository.archive(id);
      dispatch(actions.archiveAgentPropertyFinish());
    } catch (error: unknown) {
      dispatch(actions.archiveAgentPropertyFail());
    }
  };

  const setImportRecords = (userId: ID, data: PropertiesImportRecordType[]) =>
    dispatch(actions.setImportData({ userId, data }));

  const importProperties = async (userId: ID, data: PropertiesImportRecordType[]) => {
    dispatch(actions.importPropertiesStart());
    try {
      const response = await PropertiesRepository.import(userId, { properties: { data } });
      dispatch(actions.importPropertiesFinish(response));

      return response;
    } catch (error: unknown) {
      dispatch(actions.importPropertiesFail());

      return null;
    }
  };

  const loadMatchesStatus = async (id: ID) => {
    const { property } = await PropertiesRepository.showMatchesStatus(id);
    return property;
  };

  return {
    loadAgentProperties,
    resetAgentProperties,
    loadAgentProperty,
    changeAgentPropertyPhase,
    archiveAgentProperty,

    importProperties,
    setImportRecords,

    loadMatchesStatus,
  };
};

export default AgentPropertiesSlice.reducer;
