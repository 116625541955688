import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { AuthModal } from 'enums/AuthModal';

export type AuthModalsActiveModalType = AuthModal | null;
export type AuthModalSliceStateType = { activeModal: AuthModalsActiveModalType };
export type AuthModalSliceActionsType = {
  closeAuthModal: () => void;
  openSignInModal: () => void;
  openRecoveryPasswordModal: () => void;
  openResetPasswordModal: () => void;
  openConfirmNewPasswordModal: () => void;
  openConfirmSuccessModal: () => void;
  openConfirmFailModal: () => void;
};

const authModalSlice = createSlice({
  name: 'authModals',
  initialState: { activeModal: null } as AuthModalSliceStateType,
  reducers: {
    openSignInModal: (state): void => {
      state.activeModal = AuthModal.signIn;
    },
    openRecoveryPasswordModal: (state): void => {
      state.activeModal = AuthModal.recoveryPassword;
    },
    openResetPasswordModal: (state): void => {
      state.activeModal = AuthModal.resetPassword;
    },
    openConfirmNewPasswordModal: (state): void => {
      state.activeModal = AuthModal.confirmNewPassword;
    },
    openConfirmSuccessModal: (state): void => {
      state.activeModal = AuthModal.confirmSuccess;
    },
    openConfirmFailModal: (state): void => {
      state.activeModal = AuthModal.confirmFail;
    },
    closeAuthModal: (state): void => {
      state.activeModal = null;
    },
  },
});

const { actions } = authModalSlice;

export const useAuthModalsActions = (): AuthModalSliceActionsType => {
  const dispatch = useDispatch();

  const openSignInModal = () => dispatch(actions.openSignInModal());
  const openRecoveryPasswordModal = () => dispatch(actions.openRecoveryPasswordModal());
  const openResetPasswordModal = () => dispatch(actions.openResetPasswordModal());
  const openConfirmNewPasswordModal = () => dispatch(actions.openConfirmNewPasswordModal());
  const openConfirmSuccessModal = () => dispatch(actions.openConfirmSuccessModal());
  const openConfirmFailModal = () => dispatch(actions.openConfirmFailModal());
  const closeAuthModal = () => dispatch(actions.closeAuthModal());

  return {
    openSignInModal,
    openRecoveryPasswordModal,
    openResetPasswordModal,
    openConfirmNewPasswordModal,
    openConfirmSuccessModal,
    openConfirmFailModal,
    closeAuthModal,
  };
};

export default authModalSlice.reducer;
