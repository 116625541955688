import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import Review from 'types/resources/review';
import { AdminReviewFiltersParams } from 'forms/adminReviewFiltersForm';

export default {
  index(params?: AdminReviewFiltersParams): Promise<{ connections: Review[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1AdminReviewsPath();

    return Fetcher.get(url, params);
  },
};
