import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  title: {
    marginBottom: 3,
  },
  description: {
    color: 'simpleGrey',
  },
};

export default styles;
