import { createTheme, Theme, ThemeOptions } from '@material-ui/core/styles';

import palette from './palette';
import breakpoints from './breakpoints';
import typography from './typography';
import overrides from './overrides';

declare module '@material-ui/core/Button/Button' {
  interface ButtonPropsVariantOverrides {
    social: true;
    link: true;
  }
}

const createAppTheme = (themeOptions: ThemeOptions): Theme => createTheme(themeOptions);

const theme: Theme = createAppTheme({
  palette,
  breakpoints,
  typography,
  ...overrides,
});

export default theme;
