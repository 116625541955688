import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiPaperType = {
  defaultProps: ComponentsProps['MuiPaper'];
  styleOverrides: ComponentsOverrides['MuiPaper'];
};

const MuiPaper: MuiPaperType = {
  styleOverrides: {
    outlined: {
      padding: '30px',
    },
  },
  defaultProps: {},
};

export default MuiPaper;
