import { FC } from 'react';
import { Container } from '@material-ui/core';
import Box from 'components/Box';

import HeaderToolbar from './components/HeaderToolbar';

const Header: FC = () => {
  return (
    <Box>
      <Container>
        <HeaderToolbar />
      </Container>
    </Box>
  );
};

export default Header;
