import { FC, ReactElement } from 'react';
import { Typography } from '@material-ui/core';
import { SxProps } from '@material-ui/system';
import { Theme } from '@material-ui/core/styles';

import styles from './styles';

type OrDividerProps = {
  sx: SxProps<Theme>;
};

const OrDivider: FC<OrDividerProps> = (props): ReactElement => {
  const { sx } = props;

  return (
    <Typography align="center" sx={{ ...styles.root, ...sx }} variant="subtitle2" component="p">
      <span>or</span>
    </Typography>
  );
};

export default OrDivider;
