import { AuthModalSliceStateType, AuthModalSliceActionsType, useAuthModalsActions } from 'store/authModalsSlice';
import { useSelector } from 'react-redux';

type UseAuthModalsType = AuthModalSliceStateType & AuthModalSliceActionsType;

const useAuthModals = (): UseAuthModalsType => {
  const { activeModal } = useSelector((state) => state.authModals);

  const {
    closeAuthModal,
    openSignInModal,
    openRecoveryPasswordModal,
    openResetPasswordModal,
    openConfirmNewPasswordModal,
    openConfirmSuccessModal,
    openConfirmFailModal,
  } = useAuthModalsActions();

  return {
    activeModal,
    closeAuthModal,
    openSignInModal,
    openRecoveryPasswordModal,
    openResetPasswordModal,
    openConfirmNewPasswordModal,
    openConfirmSuccessModal,
    openConfirmFailModal,
  };
};

export default useAuthModals;
