import ConnectionsRepository from 'repositories/Buyer/ConnectionsRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import Connection from 'types/resources/connection';
import { Meta } from 'types/meta';
import { createSlice } from '@reduxjs/toolkit';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';

export type ConnectionsSliceStateType = {
  connections: Connection[];
  connectionsMeta: Meta | null;
  showConnections: { fetchStatus: FetchStatus };
  connection: Connection | null;
  showConnection: { fetchStatus: FetchStatus };
  phaseChanged: { fetchStatus: FetchStatus };
  connectionArchived: { fetchStatus: FetchStatus };
};

export type ConnectionsSliceActionsType = {
  loadConnections: (params?: ConnectionFiltersParams) => void;
  resetConnections: () => void;
  loadConnection: (id: ID) => void;
  acceptContact: (id: ID) => void;
  rejectContact: (id: ID) => void;
  archiveConnection: (id: ID) => void;
};

const initialState: ConnectionsSliceStateType = {
  connections: [],
  connectionsMeta: null,
  showConnections: { fetchStatus: FetchStatus.idle },
  connection: null,
  showConnection: { fetchStatus: FetchStatus.idle },
  phaseChanged: { fetchStatus: FetchStatus.idle },
  connectionArchived: { fetchStatus: FetchStatus.idle },
};

const ConnectionsSlice = createSlice({
  name: 'connections',
  initialState,
  reducers: {
    loadConnectionsStart: (state): void => {
      state.showConnections.fetchStatus = FetchStatus.pending;
    },
    loadConnectionsFinish: (state, { payload }): void => {
      const { connections, meta } = payload;

      state.showConnections.fetchStatus = FetchStatus.fulfilled;
      state.connections = connections;
      state.connectionsMeta = meta;
    },
    loadConnectionsFail: (state): void => {
      state.showConnections.fetchStatus = FetchStatus.failed;
    },
    resetConnections: (): ConnectionsSliceStateType => {
      return initialState;
    },
    loadConnectionStart: (state): void => {
      state.showConnection.fetchStatus = FetchStatus.pending;
    },
    loadConnectionFinish: (state, { payload }): void => {
      const { connection } = payload;

      state.showConnection.fetchStatus = FetchStatus.fulfilled;
      state.connection = connection;
    },
    loadConnectionFail: (state): void => {
      state.showConnection.fetchStatus = FetchStatus.failed;
    },
    changePhaseStart: (state): void => {
      state.phaseChanged.fetchStatus = FetchStatus.pending;
    },
    changePhaseFinish: (state): void => {
      state.phaseChanged.fetchStatus = FetchStatus.fulfilled;
    },
    changePhaseFail: (state): void => {
      state.phaseChanged.fetchStatus = FetchStatus.failed;
    },
    archiveConnectionStart: (state): void => {
      state.connectionArchived.fetchStatus = FetchStatus.pending;
    },
    archiveConnectionFinish: (state): void => {
      state.connectionArchived.fetchStatus = FetchStatus.fulfilled;
    },
    archiveConnectionFail: (state): void => {
      state.connectionArchived.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = ConnectionsSlice;

export const useConnectionsActions = (): ConnectionsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadConnections = async (params?: ConnectionFiltersParams) => {
    dispatch(actions.loadConnectionsStart());
    try {
      const connections = await ConnectionsRepository.index(params);
      dispatch(actions.loadConnectionsFinish(connections));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionsFail());
    }
  };

  const resetConnections = () => dispatch(actions.resetConnections());

  const loadConnection = async (id: ID) => {
    dispatch(actions.loadConnectionStart());
    try {
      const search = await ConnectionsRepository.show(id);
      dispatch(actions.loadConnectionFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionFail());
    }
  };

  const acceptContact = async (id: ID) => {
    dispatch(actions.changePhaseStart());
    try {
      await ConnectionsRepository.acceptContact(id);
      dispatch(actions.changePhaseFinish());
    } catch (error: unknown) {
      dispatch(actions.changePhaseFail());
    }
  };

  const rejectContact = async (id: ID) => {
    dispatch(actions.changePhaseStart());
    try {
      await ConnectionsRepository.rejectContact(id);
      dispatch(actions.changePhaseFinish());
    } catch (error: unknown) {
      dispatch(actions.changePhaseFail());
    }
  };

  const archiveConnection = async (id: ID) => {
    dispatch(actions.archiveConnectionStart());
    try {
      await ConnectionsRepository.archive(id);
      dispatch(actions.archiveConnectionFinish());
    } catch (error: unknown) {
      dispatch(actions.archiveConnectionFail());
    }
  };

  return {
    loadConnections,
    resetConnections,
    loadConnection,
    acceptContact,
    rejectContact,
    archiveConnection,
  };
};

export default ConnectionsSlice.reducer;
