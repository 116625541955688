import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import User from 'types/resources/user';
import { Meta } from 'types/meta';
import SellersRepository from 'repositories/Agent/SellersRepository';
import ProfileRepository from 'repositories/Agent/Seller/ProfileRepository';
import { SellerProfileFormDataToSubmit } from 'forms/sellerProfileForm';

export type AgentSellersSliceStateType = {
  sellers: {
    data: User[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
  seller: {
    data: User;
    fetchStatus: FetchStatus;
  };
};

export type AgentSellersSliceActionsType = {
  loadSellers: (params?: QueryParams) => void;
  resetSellers: () => void;
  loadSeller: (id: ID) => void;
  updateSeller: (id: ID, params: SellerProfileFormDataToSubmit) => void;
};

const initialState: AgentSellersSliceStateType = {
  sellers: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
  seller: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AgentSellersSlice = createSlice({
  name: 'agentSellers',
  initialState,
  reducers: {
    loadSellersStart: (state): void => {
      state.sellers.fetchStatus = FetchStatus.pending;
    },
    loadSellersFinish: (state, { payload }): void => {
      const { users, meta } = payload;
      state.sellers.fetchStatus = FetchStatus.fulfilled;
      state.sellers.data = users;
      state.sellers.meta = meta;
    },
    loadSellersFail: (state): void => {
      state.sellers.fetchStatus = FetchStatus.failed;
    },
    resetSellers: (): AgentSellersSliceStateType => {
      return initialState;
    },

    loadSellerStart: (state): void => {
      state.seller.fetchStatus = FetchStatus.pending;
    },
    loadSellerFinish: (state, { payload }): void => {
      const { user } = payload;

      state.seller.fetchStatus = FetchStatus.fulfilled;
      state.seller.data = user;
    },
    loadSellerFail: (state): void => {
      state.seller.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = AgentSellersSlice;

export const useAgentSellersActions = (): AgentSellersSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadSellers = async (params?: QueryParams) => {
    dispatch(actions.loadSellersStart());
    try {
      const sellers = await SellersRepository.index(params);
      dispatch(actions.loadSellersFinish(sellers));
    } catch (error: unknown) {
      dispatch(actions.loadSellersFail());
    }
  };

  const resetSellers = () => dispatch(actions.resetSellers());

  const loadSeller = async (id: ID) => {
    dispatch(actions.loadSellerStart());
    try {
      const user = await ProfileRepository.show(id);
      dispatch(actions.loadSellerFinish(user));
    } catch (error: unknown) {
      dispatch(actions.loadSellerFail());
    }
  };

  const updateSeller = (id: ID, params: SellerProfileFormDataToSubmit) => ProfileRepository.update(id, params);

  return {
    loadSellers,
    resetSellers,
    loadSeller,
    updateSeller,
  };
};

export default AgentSellersSlice.reducer;
