import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import User from 'types/resources/user';
import ProfileRepository from 'repositories/Admin/User/Seller/ProfileRepository';

export type AdminSellerSliceStateType = {
  adminSeller: {
    data: User;
    fetchStatus: FetchStatus;
  };
};

export type AdminSellerSliceActionsType = {
  loadAdminSeller: (id: ID) => void;
  resetAdminSeller: () => void;
};

const initialState: AdminSellerSliceStateType = {
  adminSeller: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminSellerSlice = createSlice({
  name: 'adminSeller',
  initialState,
  reducers: {
    loadAdminSellerStart: (state): void => {
      state.adminSeller.fetchStatus = FetchStatus.pending;
    },
    loadAdminSellerFinish: (state, { payload }): void => {
      const { user } = payload;

      state.adminSeller.fetchStatus = FetchStatus.fulfilled;
      state.adminSeller.data = user;
    },
    loadAdminSellerFail: (state): void => {
      state.adminSeller.fetchStatus = FetchStatus.failed;
    },

    resetAdminSeller: (): AdminSellerSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminSellerSlice;

export const useAdminSellerActions = (): AdminSellerSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminSeller = async (id: ID) => {
    dispatch(actions.loadAdminSellerStart());
    try {
      const user = await ProfileRepository.show(id);
      dispatch(actions.loadAdminSellerFinish(user));
    } catch (error: unknown) {
      dispatch(actions.loadAdminSellerFail());
    }
  };

  const resetAdminSeller = () => dispatch(actions.resetAdminSeller());

  return {
    loadAdminSeller,
    resetAdminSeller,
  };
};

export default AdminSellerSlice.reducer;
