import { withComma } from './NumberPresenter';

export type FeedbackDashboardType = {
  key: string;
  title: string;
  value: (value: number) => string;
  error?: boolean;
};

export const DASHBOARD_KEYS: FeedbackDashboardType[] = [
  {
    key: 'totalReviewCount',
    title: 'Total Feedback',
    value: (value: number): string => withComma(value),
  },
  {
    key: 'todayReviewCount',
    title: 'Today’s Feedback',
    value: (value: number): string => withComma(value),
  },
  {
    key: 'negativeReviewCount',
    title: 'Negative Feedback',
    value: (value: number): string => withComma(value),
    error: true,
  },
  {
    key: 'positiveReviewCount',
    title: 'Positive Feedback',
    value: (value: number): string => withComma(value),
  },
];
