import { FC } from 'react';
import { SvgIcon, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type ZigzagoProps = {
  sx?: SxProps<Theme>;
};

const Zigzago: FC<ZigzagoProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 224 64" width="224" height="64" variant="image">
      <path
        d="M28.5776 42.591H11.0137C10.6422 42.591 10.4367 42.1571 10.6768 41.8709L28.4322 20.6397C28.4991 20.5612 28.5337 20.462 28.5337 20.3581V14.9805C28.5337 14.7382 28.3375 14.542 28.0952 14.542H1.88563C1.64329 14.542 1.44711 14.7382 1.44711 14.9805V20.4643C1.44711 20.7066 1.64329 20.9028 1.88563 20.9028H17.52C17.8916 20.9028 18.097 21.3367 17.8569 21.6229L0.101552 42.8565C0.0346205 42.9349 0 43.0342 0 43.138V48.5157C0 48.758 0.19618 48.9542 0.438519 48.9542H28.5753C28.8176 48.9542 29.0138 48.758 29.0138 48.5157V43.0319C29.0161 42.7872 28.8199 42.591 28.5776 42.591Z"
        fill="#51A9BA"
      />
      <path
        d="M40.5768 14.54H33.8882C33.6459 14.54 33.4497 14.7362 33.4497 14.9786V48.5137C33.4497 48.756 33.6459 48.9522 33.8882 48.9522H40.5768C40.8191 48.9522 41.0153 48.756 41.0153 48.5137V14.9786C41.0153 14.7362 40.8191 14.54 40.5768 14.54Z"
        fill="#42A2B5"
      />
      <path
        d="M79.5405 30.6611C74.0637 30.6588 67.9567 30.6173 63.3499 30.6126C62.9668 30.6126 62.6576 30.9242 62.6576 31.3074V36.9758C62.6576 37.0243 63.0915 37.0242 64.0077 37.0242C65.9349 37.0242 69.4546 36.9758 70.9963 36.9758H71.7672C70.3686 40.2531 66.5142 42.1826 62.6576 42.1826C56.1375 42.1826 51.0876 36.3642 52.477 29.4933C53.3309 25.265 56.8229 22.0431 61.0997 21.4661C65.3902 20.8891 69.1522 22.7239 71.2525 25.8974C71.4464 26.1882 71.8226 26.2967 72.1365 26.1397C74.7514 24.8311 75.2892 24.5426 77.5626 23.3863C77.9249 23.2017 78.0565 22.7424 77.8372 22.4008C74.6614 17.3925 69.023 13.9766 62.6322 13.9859C52.7724 14.002 44.6667 22.3039 44.8791 32.159C45.0868 41.8133 53.0055 49.6051 62.6599 49.6051C69.36 49.6051 75.3354 45.8939 78.2757 40.3501C79.8082 38.3606 80.166 33.3799 80.2375 31.3766C80.2468 30.9842 79.9329 30.6611 79.5405 30.6611Z"
        fill="#319CAF"
      />
      <path
        d="M110.636 42.6632H92.9751C92.6289 42.6632 92.4397 42.2593 92.6612 41.9939L110.467 20.7026C110.53 20.6288 110.562 20.5364 110.562 20.4418V15.0203C110.562 14.7941 110.38 14.6118 110.154 14.6118H83.8816C83.6555 14.6118 83.4731 14.7941 83.4731 15.0203V20.5664C83.4731 20.7926 83.6555 20.975 83.8816 20.975H99.6152C99.9614 20.975 100.151 21.3789 99.9291 21.6443L82.1229 42.9378C82.0606 43.0117 82.0283 43.104 82.0283 43.1986V48.6178C82.0283 48.844 82.2107 49.0263 82.4368 49.0263H110.636C110.862 49.0263 111.044 48.844 111.044 48.6178V43.0717C111.044 42.8455 110.862 42.6632 110.636 42.6632Z"
        fill="#1D95AB"
      />
      <path
        d="M138.145 14.9994C138.053 14.7917 137.847 14.6602 137.621 14.6602H130.748C130.522 14.6602 130.319 14.7917 130.224 14.9971L115.13 48.2138C114.957 48.5946 115.236 49.0262 115.654 49.0262H121.29C121.511 49.0262 121.714 48.8992 121.809 48.6984L125.112 41.7398C125.206 41.539 125.41 41.4121 125.631 41.4121H142.293C142.521 41.4121 142.726 41.546 142.819 41.7537L145.891 48.6846C145.983 48.8923 146.188 49.0262 146.417 49.0262H152.482C152.9 49.0262 153.177 48.5969 153.006 48.2161L138.145 14.9994ZM139.054 34.4212H129.375C128.962 34.4212 128.682 33.9965 128.846 33.6157L134.282 21.1156H134.33L139.585 33.6203C139.745 34.0011 139.465 34.4212 139.054 34.4212Z"
        fill="#018FA5"
      />
      <path
        d="M185.819 30.6592C180.617 30.6545 174.897 30.6176 170.464 30.613C169.854 30.613 169.36 31.1069 169.36 31.7162V36.9738C169.36 37.0223 169.794 37.0223 170.711 37.0223C172.638 37.0223 176.158 36.9738 177.699 36.9738H178.47C177.071 40.2512 173.217 42.1807 169.36 42.1807C162.84 42.1807 157.79 36.3622 159.182 29.489C160.038 25.2608 163.53 22.0388 167.805 21.4641C171.978 20.9033 175.65 22.625 177.778 25.6393C178.089 26.0801 178.682 26.2186 179.165 25.9762C181.348 24.8799 182.004 24.5384 183.857 23.5921C184.443 23.2943 184.646 22.5489 184.279 22.0019C181.062 17.2128 175.557 13.977 169.337 13.9839C159.478 13.9977 151.37 22.2973 151.58 32.1547C151.785 41.8114 159.704 49.6032 169.36 49.6032C176.061 49.6032 182.036 45.8919 184.976 40.3481C186.407 38.4902 186.814 34.0265 186.92 31.8132C186.95 31.1854 186.449 30.6615 185.819 30.6592Z"
        fill="#0088A0"
      />
      <path
        d="M206.111 49.6358C196.249 49.6358 188.27 41.6594 188.27 31.795C188.27 21.8822 196.249 13.9058 206.111 13.9058C215.927 13.9058 224 21.8845 224 31.795C224 41.6571 215.927 49.6358 206.111 49.6358ZM195.57 31.795C195.57 37.5488 200.357 42.3356 206.111 42.3356C211.865 42.3356 216.554 37.5488 216.554 31.795C216.554 26.0412 211.865 21.2059 206.111 21.2059C200.357 21.2059 195.57 26.0389 195.57 31.795Z"
        fill="#00829B"
      />
      <path
        d="M209.309 9.97746C208.926 10.3629 208.305 10.3721 207.906 10.0005C202.369 4.84217 195.194 2.01257 187.593 2.01257C179.993 2.01257 172.82 4.84217 167.283 10.0005C166.884 10.3721 166.265 10.3629 165.88 9.97746C165.481 9.57356 165.49 8.91809 165.905 8.53035C171.818 3.02116 179.479 0 187.593 0C195.708 0 203.371 3.02116 209.284 8.53035C209.699 8.91809 209.709 9.57356 209.309 9.97746Z"
        fill="#51A9BA"
      />
      <path
        d="M187.594 63.6845C179.439 63.6845 171.752 60.6379 165.829 55.0872C165.416 54.6994 165.407 54.0417 165.808 53.6401C166.194 53.2546 166.815 53.2477 167.212 53.6193C172.758 58.8169 179.956 61.6696 187.594 61.6696C195.231 61.6696 202.432 58.8192 207.975 53.6193C208.372 53.2477 208.993 53.2546 209.379 53.6401C209.78 54.0417 209.771 54.6971 209.358 55.0872C203.438 60.6402 195.75 63.6845 187.594 63.6845Z"
        fill="#95CC31"
      />
    </SvgIcon>
  );
};

export default Zigzago;
