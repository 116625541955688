import { FC, ReactNode } from 'react';
import { Dialog, DialogContent, IconButton, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { SxProps } from '@material-ui/system';

import styles from './styles';

type ModalProps = {
  children?: ReactNode;
  open: boolean;
  closable?: boolean;
  onClose: () => void;
  sx?: SxProps<Theme>;
};

const Modal: FC<ModalProps> = (props): JSX.Element => {
  const { open, onClose, children, closable = true, sx } = props;

  const handleClose = () => {
    if (closable) {
      onClose();
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown={!closable}
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { ...sx, ...styles.paper } }}
    >
      {closable && (
        <IconButton sx={styles.closeButton} onClick={onClose} aria-label="Close">
          <CloseIcon sx={styles.closeIcon} />
        </IconButton>
      )}
      <DialogContent sx={styles.content}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
