import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiFormControlLabelType = {
  defaultProps: ComponentsProps['MuiFormControlLabel'];
  styleOverrides: ComponentsOverrides['MuiFormControlLabel'];
};

const MuiFormControlLabel: MuiFormControlLabelType = {
  styleOverrides: {
    label: {
      fontSize: '16px',
      color: '#4A4F56',
    },
  },
  defaultProps: {},
};

export default MuiFormControlLabel;
