import { pathOr } from 'ramda';
import City from 'types/resources/city';

export const stateName = (city: City): string => {
  return pathOr('–', ['state', 'name'], city);
};

export const cityName = (city: City): string => {
  return pathOr('–', ['name'], city);
};
