import { FC, Fragment, useState, SyntheticEvent } from 'react';
import { Button, Menu, MenuItem } from '@material-ui/core';
import { any, isNil, pluck } from 'ramda';
import { Link, useHistory } from 'react-router-dom';
import useRouter from 'hooks/useRouter';
import useNotifications from 'hooks/useNotifications';
import clsx from 'utils/clsx';
import Box from 'components/Box';
import User from 'types/resources/user';
import { MenuItemType } from 'types/navigation';
import { UsersPresenter, NavigationPresenter } from 'presenters';

import styles from './styles';

type NavbarPropsType = {
  user: User;
};

const Navbar: FC<NavbarPropsType> = (props: NavbarPropsType) => {
  const { user } = props;
  const {
    contactedConnectionsCount,
    unverifiedBudgetsCount,
    propertyOnReviewCount,
    unverifiedLicensesCount,
    communicatingConnectionsCount,
    waitingForAgentResponseConnectionsCount,
  } = useNotifications();

  const notificationsCountObject = {
    contactedConnectionsCount,
    unverifiedBudgetsCount,
    propertyOnReviewCount,
    unverifiedLicensesCount,
    communicatingConnectionsCount,
    waitingForAgentResponseConnectionsCount,
  };

  const history = useHistory();
  const { match } = useRouter();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const getButtonStyles = () => {
    return clsx(styles.linkCommon, [
      [styles.linkSecondary, UsersPresenter.isSeller(user) || UsersPresenter.isAgent(user)],
      [styles.linkPrimary, UsersPresenter.isBuyer(user)],
    ]);
  };

  const getActiveButtonStyles = () => {
    return clsx(styles.linkPrimary, [[styles.linkSecondary, UsersPresenter.isBuyer(user)]]);
  };

  const getShowNotificationStyles = () => {
    return clsx(styles.shouldShowNotificationCommon, [
      [styles.shouldShowNotificationForSeller, UsersPresenter.isSeller(user) || UsersPresenter.isAgent(user)],
      [styles.shouldShowNotificationForBuyer, UsersPresenter.isBuyer(user)],
    ]);
  };

  const getActiveShowNotificationStyles = () => {
    return clsx(styles.shouldShowNotificationForBuyer, [
      [styles.shouldShowNotificationForSeller, UsersPresenter.isBuyer(user)],
    ]);
  };

  const navLinks = NavigationPresenter.getUserNavigationLinks(user);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (itemPath: string) => {
    history.push(itemPath);
    handleMenuClose();
  };

  const buttonStyles = (isActive: boolean, isNotificationShowing: boolean, isActiveNotificationShowing: boolean) =>
    clsx({ ...styles.link, ...getButtonStyles() }, [
      [{ ...styles.linkActive, ...getActiveButtonStyles() }, isActive],
      [getShowNotificationStyles(), isNotificationShowing],
      [getActiveShowNotificationStyles(), isActiveNotificationShowing],
    ]);

  return (
    <Box sx={clsx(styles.root, [[styles.rootAgent, UsersPresenter.isAgent(user)]])}>
      {navLinks.map((navLink: MenuItemType) => {
        const { label, path, items = [], notificationFactors = [] } = navLink;
        const notificationCounts = notificationFactors.map((item: string) => notificationsCountObject[item]);
        const isNotificationShowing = any((item: number) => item > 0, notificationCounts);
        const isItemActive = !isNil(match(path));
        const isActiveNotificationShowing = !isNil(match(path));

        if (path) {
          return (
            <Button
              key={label}
              component={Link}
              variant="link"
              disableRipple
              sx={buttonStyles(
                isItemActive,
                isNotificationShowing,
                isNotificationShowing ? isActiveNotificationShowing : null,
              )}
              to={path}
            >
              {label}
            </Button>
          );
        }

        const isSectionActive = any((itemPath) => !isNil(match(itemPath)), pluck('path', items));

        return (
          <Fragment key={label}>
            <Button
              variant="link"
              onClick={handleClick}
              sx={buttonStyles(
                isSectionActive,
                isNotificationShowing,
                isNotificationShowing ? isActiveNotificationShowing : null,
              )}
            >
              {label}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
              {items.map((item) => {
                const { label: itemLabel, path: itemPath } = item;

                return (
                  <MenuItem key={itemLabel} onClick={() => handleMenuItemClick(itemPath)}>
                    {itemLabel}
                  </MenuItem>
                );
              })}
            </Menu>
          </Fragment>
        );
      })}
    </Box>
  );
};

export default Navbar;
