import axios, { AxiosError } from 'axios';
import { path, isNil } from 'ramda';
import { HttpStatusCode } from 'enums/HttpStatusCode';

import { camelizeKeys } from './keysConverter';

export const isUnauthorizedError = (error: AxiosError): boolean =>
  error.response.status === HttpStatusCode.unauthorized;

export const isForbiddenError = (error: AxiosError): boolean => error.response.status === HttpStatusCode.forbidden;

export const isUnprocessedEntityError = (error: AxiosError): boolean =>
  error.response.status === HttpStatusCode.unprocessedEntity;

export const isNotFoundError = (error: AxiosError): boolean => error.response.status === HttpStatusCode.notFound;

export const isAxiosError = (error: unknown | AxiosError): error is AxiosError => axios.isAxiosError(error);

export const extractResponseErrors = (response: unknown): unknown => {
  return path(['response', 'data', 'errors'], response);
};

export const parseToFormikErrors = (errors: unknown): unknown => {
  if (isNil(errors)) return null;
  const camelizedErrors = camelizeKeys(errors);
  return Object.keys(camelizedErrors).reduce((acc, key) => ({ ...acc, [key]: camelizedErrors[key].join(', ') }), {});
};
