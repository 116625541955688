import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { Meta } from 'types/meta';
import StateScorecard from 'types/resources/stateScorecard';
import { AdminStatesScorecardFiltersParams } from 'forms/adminStatesScorecardFiltersForm';
import StatesScorecardRepository from 'repositories/Admin/StatesScorecardRepository';

export type AdminStatesScorecardSliceStateType = {
  adminStatesScorecard: {
    data: StateScorecard[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
};

export type AdminStatesScorecardSliceActionsType = {
  loadAdminStatesScorecard: (params: AdminStatesScorecardFiltersParams) => void;
  resetAdminStatesScorecard: () => void;
};

const initialState: AdminStatesScorecardSliceStateType = {
  adminStatesScorecard: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminStatesScorecardSlice = createSlice({
  name: 'adminStatesScorecard',
  initialState,
  reducers: {
    loadAdminStatesScorecardStart: (state): void => {
      state.adminStatesScorecard.fetchStatus = FetchStatus.pending;
    },
    loadAdminStatesScorecardFinish: (state, { payload }): void => {
      const { states, meta } = payload;

      state.adminStatesScorecard.fetchStatus = FetchStatus.fulfilled;
      state.adminStatesScorecard.data = states;
      state.adminStatesScorecard.meta = meta;
    },
    loadAdminStatesScorecardFail: (state): void => {
      state.adminStatesScorecard.fetchStatus = FetchStatus.failed;
    },

    resetStatesScorecard: (): AdminStatesScorecardSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminStatesScorecardSlice;

export const useAdminStatesScorecardActions = (): AdminStatesScorecardSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminStatesScorecard = async (params: AdminStatesScorecardFiltersParams) => {
    dispatch(actions.loadAdminStatesScorecardStart());
    try {
      const users = await StatesScorecardRepository.index(params);
      dispatch(actions.loadAdminStatesScorecardFinish(users));
    } catch (error: unknown) {
      dispatch(actions.loadAdminStatesScorecardFail());
    }
  };

  const resetAdminStatesScorecard = () => dispatch(actions.resetStatesScorecard());

  return {
    loadAdminStatesScorecard,
    resetAdminStatesScorecard,
  };
};

export default AdminStatesScorecardSlice.reducer;
