import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Match from 'types/resources/match';

export default {
  show(matchId: ID): Promise<{ match: Match }> {
    const url = apiRoutes.apiWebV1UserAgentMatchPath(matchId);

    return Fetcher.get(url, {});
  },
};
