import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTableHeadType = {
  defaultProps: ComponentsProps['MuiTableHead'];
  styleOverrides: ComponentsOverrides['MuiTableHead'];
};

const MuiTableHead: MuiTableHeadType = {
  styleOverrides: {},
  defaultProps: {
    sx: {
      backgroundColor: 'wave',
      borderBottom: '1px solid',
      borderColor: 'richGrey',
      '& th': {
        textTransform: 'uppercase',
        color: 'white',
        paddingY: 0,
        paddingX: 1.5,
        height: '48px',
        fontWeight: '600',
        fontSize: '14px',
      },
    },
  },
};

export default MuiTableHead;
