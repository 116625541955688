import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  content: {
    position: 'relative',
    marginX: 0,
    padding: 0,
    zIndex: 0,
  },
  paper: {
    maxWidth: 1000,
    overflowY: 'initial',
    borderRadius: '8px',
  },
  closeButton: {
    position: 'absolute',
    zIndex: 1,
    right: '-55px',
    top: '-8px',
    color: 'common.white',
  },
  closeIcon: {
    fontSize: 24,
  },
};

export default styles;
