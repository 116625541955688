import ConnectionsRepository from 'repositories/Seller/ConnectionsRepository';
import ConnectionsDashboardRepository from 'repositories/Seller/ConnectionsDashboardRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import { Meta } from 'types/meta';
import Connection from 'types/resources/connection';
import DashboardInfo from 'types/resources/connection/dashboard';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';

export type SellerConnectionsSliceStateType = {
  sellerConnections: {
    data: Connection[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
  sellerConnectionsDashboard: {
    data: DashboardInfo;
    fetchStatus: FetchStatus;
  };
  sellerConnection: {
    data: Connection;
    fetchStatus: FetchStatus;
  };
  createdConnection: {
    fetchStatus: FetchStatus;
  };
};

export type SellerConnectionsSliceActionsType = {
  loadConnections: (params: ConnectionFiltersParams) => void;
  loadConnection: (id: ID) => void;
  loadConnectionsDashboard: () => void;
  createConnection: (params: ConnectionCreationParams) => void;
  resetConnections: () => void;
  resetConnection: () => void;
  archiveConnection: (id: ID) => void;
};

export type ConnectionCreationParams = {
  connection: {
    searchId: ID;
    propertyId: ID;
  };
};

const initialState: SellerConnectionsSliceStateType = {
  sellerConnections: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
  sellerConnectionsDashboard: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
  sellerConnection: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
  createdConnection: {
    fetchStatus: FetchStatus.idle,
  },
};

const SellerConnectionsSlice = createSlice({
  name: 'sellerConnections',
  initialState,
  reducers: {
    loadConnectionsStart: (state): void => {
      state.sellerConnections.fetchStatus = FetchStatus.pending;
    },
    loadConnectionsFinish: (state, { payload }): void => {
      const { connections, meta } = payload;

      state.sellerConnections.fetchStatus = FetchStatus.fulfilled;
      state.sellerConnections.data = connections;
      state.sellerConnections.meta = meta;
    },
    loadConnectionsFail: (state): void => {
      state.sellerConnections.fetchStatus = FetchStatus.failed;
    },
    loadConnectionStart: (state): void => {
      state.sellerConnection.fetchStatus = FetchStatus.pending;
    },
    loadConnectionFinish: (state, { payload }): void => {
      const { connection } = payload;

      state.sellerConnection.fetchStatus = FetchStatus.fulfilled;
      state.sellerConnection.data = connection;
    },
    loadConnectionFail: (state): void => {
      state.sellerConnection.fetchStatus = FetchStatus.failed;
    },
    loadConnectionsDashboardStart: (state): void => {
      state.sellerConnectionsDashboard.fetchStatus = FetchStatus.pending;
    },
    loadConnectionsDashboardFinish: (state, { payload }): void => {
      const { sellerConnectionsDashboard } = payload;

      state.sellerConnectionsDashboard.fetchStatus = FetchStatus.fulfilled;
      state.sellerConnectionsDashboard.data = sellerConnectionsDashboard;
    },
    loadConnectionsDashboardFail: (state): void => {
      state.sellerConnectionsDashboard.fetchStatus = FetchStatus.failed;
    },
    createConnectionStart: (state): void => {
      state.createdConnection.fetchStatus = FetchStatus.pending;
    },
    createConnectionFail: (state): void => {
      state.createdConnection.fetchStatus = FetchStatus.failed;
    },
    createConnectionSuccess: (state): void => {
      state.createdConnection.fetchStatus = FetchStatus.fulfilled;
    },
    resetConnection: (state): void => {
      state.sellerConnection = initialState.sellerConnection;
    },
    resetConnections: (state): void => {
      state.sellerConnections = initialState.sellerConnections;
      state.sellerConnectionsDashboard = initialState.sellerConnectionsDashboard;
    },
  },
});

const { actions } = SellerConnectionsSlice;

export const useSellerConnectionsActions = (): SellerConnectionsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadConnections = async (params: ConnectionFiltersParams) => {
    dispatch(actions.loadConnectionsStart());
    try {
      const connections = await ConnectionsRepository.index(params);
      dispatch(actions.loadConnectionsFinish(connections));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionsFail());
    }
  };

  const loadConnection = async (id: ID) => {
    dispatch(actions.loadConnectionStart());
    try {
      const search = await ConnectionsRepository.show(id);
      dispatch(actions.loadConnectionFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionFail());
    }
  };

  const loadConnectionsDashboard = async () => {
    dispatch(actions.loadConnectionsDashboardStart());
    try {
      const dashboard = await ConnectionsDashboardRepository.show();
      dispatch(actions.loadConnectionsDashboardFinish(dashboard));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionsDashboardFail());
    }
  };

  const createConnection = async (params: ConnectionCreationParams) => {
    dispatch(actions.createConnectionStart());
    try {
      await ConnectionsRepository.create(params);
      dispatch(actions.createConnectionSuccess());
    } catch (error: unknown) {
      dispatch(actions.createConnectionFail());
    }
  };

  const resetConnections = () => dispatch(actions.resetConnections());
  const resetConnection = () => dispatch(actions.resetConnection());
  const archiveConnection = async (id: ID) => ConnectionsRepository.archive(id);

  return {
    loadConnections,
    loadConnection,
    loadConnectionsDashboard,
    createConnection,
    resetConnections,
    resetConnection,
    archiveConnection,
  };
};

export default SellerConnectionsSlice.reducer;
