import ConnectionsRepository from 'repositories/Agent/ConnectionsRepository';
import ConnectionsDashboardRepository from 'repositories/Agent/ConnectionsDashboardRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import { Meta } from 'types/meta';
import Connection from 'types/resources/connection';
import DashboardInfo from 'types/resources/connection/dashboard';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';
import { ConnectionEvent } from 'enums/ConnectionEvent';
import { CloseConnectionFormData } from 'forms/closeConnectionForm';

import { ConnectionCreationParams } from './sellerConnectionsSlice';

export type AgentConnectionsSliceStateType = {
  agentConnections: {
    data: Connection[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
  agentConnectionsDashboard: {
    data: DashboardInfo;
    fetchStatus: FetchStatus;
  };
  agentConnection: {
    data: Connection;
    fetchStatus: FetchStatus;
  };
  createdConnection: {
    fetchStatus: FetchStatus;
  };
};

export type AgentConnectionsSliceActionsType = {
  loadConnections: (params: ConnectionFiltersParams) => void;
  loadConnection: (id: ID) => void;
  loadConnectionsDashboard: () => void;
  createConnection: (params: ConnectionCreationParams) => void;
  resetConnections: () => void;
  changePhase: (id: ID, phase: ConnectionEvent) => void;
  closeConnection: (connectionId: ID, params: CloseConnectionFormData) => void;
  archiveConnection: (id: ID) => void;
};

const initialState: AgentConnectionsSliceStateType = {
  agentConnections: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
  agentConnectionsDashboard: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
  agentConnection: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
  createdConnection: {
    fetchStatus: FetchStatus.idle,
  },
};

const AgentConnectionsSlice = createSlice({
  name: 'agentConnections',
  initialState,
  reducers: {
    loadConnectionsStart: (state): void => {
      state.agentConnections.fetchStatus = FetchStatus.pending;
    },
    loadConnectionsFinish: (state, { payload }): void => {
      const { connections, meta } = payload;

      state.agentConnections.fetchStatus = FetchStatus.fulfilled;
      state.agentConnections.data = connections;
      state.agentConnections.meta = meta;
    },
    loadConnectionsFail: (state): void => {
      state.agentConnections.fetchStatus = FetchStatus.failed;
    },
    loadConnectionStart: (state): void => {
      state.agentConnection.fetchStatus = FetchStatus.pending;
    },
    loadConnectionFinish: (state, { payload }): void => {
      const { connection } = payload;

      state.agentConnection.fetchStatus = FetchStatus.fulfilled;
      state.agentConnection.data = connection;
    },
    loadConnectionFail: (state): void => {
      state.agentConnection.fetchStatus = FetchStatus.failed;
    },
    loadConnectionsDashboardStart: (state): void => {
      state.agentConnectionsDashboard.fetchStatus = FetchStatus.pending;
    },
    loadConnectionsDashboardFinish: (state, { payload }): void => {
      state.agentConnectionsDashboard.fetchStatus = FetchStatus.fulfilled;
      state.agentConnectionsDashboard.data = payload;
    },
    loadConnectionsDashboardFail: (state): void => {
      state.agentConnectionsDashboard.fetchStatus = FetchStatus.failed;
    },
    resetConnections: (): AgentConnectionsSliceStateType => {
      return initialState;
    },
    createConnectionStart: (state): void => {
      state.createdConnection.fetchStatus = FetchStatus.pending;
    },
    createConnectionFail: (state): void => {
      state.createdConnection.fetchStatus = FetchStatus.failed;
    },
    createConnectionSuccess: (state): void => {
      state.createdConnection.fetchStatus = FetchStatus.fulfilled;
    },
  },
});

const { actions } = AgentConnectionsSlice;

export const useAgentConnectionsActions = (): AgentConnectionsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadConnections = async (params: ConnectionFiltersParams) => {
    dispatch(actions.loadConnectionsStart());
    try {
      const connections = await ConnectionsRepository.index(params);
      dispatch(actions.loadConnectionsFinish(connections));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionsFail());
    }
  };

  const loadConnection = async (id: ID) => {
    dispatch(actions.loadConnectionStart());
    try {
      const search = await ConnectionsRepository.show(id);
      dispatch(actions.loadConnectionFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionFail());
    }
  };

  const loadConnectionsDashboard = async () => {
    dispatch(actions.loadConnectionsDashboardStart());
    try {
      const { agentConnectionsDashboard } = await ConnectionsDashboardRepository.show();
      dispatch(actions.loadConnectionsDashboardFinish(agentConnectionsDashboard));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionsDashboardFail());
    }
  };

  const resetConnections = () => dispatch(actions.resetConnections());

  const createConnection = async (params: ConnectionCreationParams) => {
    dispatch(actions.createConnectionStart());
    try {
      await ConnectionsRepository.create(params);
      dispatch(actions.createConnectionSuccess());
    } catch (error: unknown) {
      dispatch(actions.createConnectionFail());
    }
  };

  const changePhase = async (id: ID, phase: ConnectionEvent) => ConnectionsRepository.changePhase(id, phase);

  const archiveConnection = async (id: ID) => ConnectionsRepository.archive(id);

  const closeConnection = async (id: ID, params: CloseConnectionFormData) => ConnectionsRepository.close(id, params);

  return {
    loadConnections,
    loadConnection,
    loadConnectionsDashboard,
    createConnection,
    resetConnections,
    changePhase,
    closeConnection,
    archiveConnection,
  };
};

export default AgentConnectionsSlice.reducer;
