import { any } from 'ramda';
import { ImportResponseType } from 'types/importResponse';
import { ImportResponseStatus } from 'enums/ImportResponseStatus';

export const isResponseSuccess = (response: ImportResponseType): boolean =>
  response && response.status === ImportResponseStatus.success;

export const isResponseFailed = (response: ImportResponseType): boolean =>
  response && response.status === ImportResponseStatus.failed;

export const hasResponseErrors = (response: ImportResponseType[]): boolean =>
  any((responseItem: ImportResponseType) => isResponseFailed(responseItem), response);
