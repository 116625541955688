/* eslint-disable no-param-reassign */
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import Rating from 'types/resources/rating';
import RatingsRepository from 'repositories/Agent/RatingsRepository';

export type AgentRatingsSliceStateType = {
  ratings: {
    data: Rating[];
    fetchStatus: FetchStatus;
  };
};

export type AgentRatingsSliceActionsType = {
  loadAgentRatings: () => void;
  resetAgentRatings: () => void;
};

const initialState: AgentRatingsSliceStateType = {
  ratings: {
    data: [],
    fetchStatus: FetchStatus.idle,
  },
};

const AgentRatingsSlice = createSlice({
  name: 'agentRatings',
  initialState,
  reducers: {
    loadAgentRatingsStart: (state): void => {
      state.ratings.fetchStatus = FetchStatus.pending;
    },
    loadAgentRatingsFinish: (state, { payload }): void => {
      const { ratings } = payload;

      state.ratings.fetchStatus = FetchStatus.fulfilled;
      state.ratings.data = ratings;
    },
    loadAgentRatingsFail: (state): void => {
      state.ratings.fetchStatus = FetchStatus.failed;
    },

    resetAgentRatings: (): AgentRatingsSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AgentRatingsSlice;

export const useAgentRatingsActions = (): AgentRatingsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAgentRatings = async () => {
    dispatch(actions.loadAgentRatingsStart());
    try {
      const rating = await RatingsRepository.index();
      dispatch(actions.loadAgentRatingsFinish(rating));
    } catch (error: unknown) {
      dispatch(actions.loadAgentRatingsFail());
    }
  };

  const resetAgentRatings = () => dispatch(actions.resetAgentRatings());

  return {
    loadAgentRatings,
    resetAgentRatings,
  };
};

export default AgentRatingsSlice.reducer;
