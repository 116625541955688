import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import ZipCode from 'types/resources/zipCode';

export default {
  index(params: QueryParams): Promise<{ zipCodes: ZipCode[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1ZipCodesPath();

    return Fetcher.get(url, params);
  },

  show(id: ID): Promise<{ zipCode: ZipCode }> {
    const url = apiRoutes.apiWebV1ZipCodePath(id);

    return Fetcher.get(url);
  },
};
