import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import City from 'types/resources/city';

export default {
  index(params: QueryParams): Promise<{ cities: City[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1CitiesPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<City> {
    const url = apiRoutes.apiWebV1CityPath(id);

    return Fetcher.get(url);
  },
};
