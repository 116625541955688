import { isEmpty, either, isNil, is, when, pipe, map, reject } from 'ramda';

export const isBlank = either(isEmpty, isNil);

const isArrayOrObject = either(is(Array), is(Object));

export const compactParams = when(
  isArrayOrObject,
  pipe(
    map((a) => compactParams(a)),
    reject(isBlank),
  ),
);

export const isString = (value: unknown): boolean => typeof value === 'string';
