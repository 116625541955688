import { path } from 'ramda';
import { GeoJSONType } from 'types/geo';
import ZipCode from 'types/resources/zipCode';

export const areas = (zipCodes: ZipCode[]): GeoJSONType[] => {
  const geojsons = zipCodes.map((item: ZipCode): GeoJSONType => {
    return path(['geoLayer'], item);
  });

  return geojsons;
};
