import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';
import Search from 'types/resources/search';
import SearchesRepository from 'repositories/Admin/SearchesRepository';
import { Meta } from 'types/meta';
import { SearchEvent } from 'enums/SearchEvent';

export type AdminSearchesSliceStateType = {
  adminSearches: {
    data: Search[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
  };
  adminSearch: {
    fetchStatus: FetchStatus;
    data: Search | null;
  };
  searchArchived: { fetchStatus: FetchStatus };
};

export type AdminSearchesSliceActionsType = {
  loadAdminSearches: (params: SearchFiltersFormDataParams) => void;
  resetAdminSearches: () => void;
  loadAdminSearch: (id: ID) => void;
  changeSearchPhase: (id: ID, event: SearchEvent) => void;
  archiveSearch: (id: ID) => void;
};

const initialState: AdminSearchesSliceStateType = {
  adminSearches: {
    fetchStatus: FetchStatus.idle,
    data: [],
    meta: null,
  },
  adminSearch: {
    fetchStatus: FetchStatus.idle,
    data: null,
  },
  searchArchived: { fetchStatus: FetchStatus.idle },
};

const AdminSearchesSlice = createSlice({
  name: 'adminSearches',
  initialState,
  reducers: {
    loadSearchesStart: (state): void => {
      state.adminSearches.fetchStatus = FetchStatus.pending;
    },
    loadSearchesFinish: (state, { payload }): void => {
      const { searches, meta } = payload;

      state.adminSearches.fetchStatus = FetchStatus.fulfilled;
      state.adminSearches.data = searches;
      state.adminSearches.meta = meta;
    },
    loadSearchesFail: (state): void => {
      state.adminSearches.fetchStatus = FetchStatus.failed;
    },
    resetAdminSearches: (): AdminSearchesSliceStateType => {
      return initialState;
    },
    loadAdminSearchStart: (state): void => {
      state.adminSearch.fetchStatus = FetchStatus.pending;
    },
    loadAdminSearchFinish: (state, { payload }): void => {
      const { search } = payload;

      state.adminSearch.fetchStatus = FetchStatus.fulfilled;
      state.adminSearch.data = search;
    },
    loadAdminSearchFail: (state): void => {
      state.adminSearch.fetchStatus = FetchStatus.failed;
    },
    archiveSearchStart: (state): void => {
      state.searchArchived.fetchStatus = FetchStatus.pending;
    },
    archiveSearchFinish: (state): void => {
      state.searchArchived.fetchStatus = FetchStatus.fulfilled;
    },
    archiveSearchFail: (state): void => {
      state.searchArchived.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = AdminSearchesSlice;

export const useAdminSearchesActions = (): AdminSearchesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminSearches = async (params: SearchFiltersFormDataParams = {}) => {
    dispatch(actions.loadSearchesStart());

    try {
      const searches = await SearchesRepository.index(params);
      dispatch(actions.loadSearchesFinish(searches));
    } catch (error: unknown) {
      dispatch(actions.loadSearchesFail());
    }
  };

  const loadAdminSearch = async (id: ID) => {
    dispatch(actions.loadAdminSearchStart());

    try {
      const search = await SearchesRepository.show(id);
      dispatch(actions.loadAdminSearchFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadAdminSearchFail());
    }
  };

  const archiveSearch = async (id: ID) => {
    dispatch(actions.archiveSearchStart());

    try {
      await SearchesRepository.archive(id);
      dispatch(actions.archiveSearchFinish());
    } catch (error: unknown) {
      dispatch(actions.archiveSearchFail());
    }
  };

  const changeSearchPhase = async (id: ID, event: SearchEvent) => SearchesRepository.changePhase(id, event);

  const resetAdminSearches = () => dispatch(actions.resetAdminSearches());

  return {
    loadAdminSearches,
    resetAdminSearches,
    loadAdminSearch,
    changeSearchPhase,
    archiveSearch,
  };
};

export default AdminSearchesSlice.reducer;
