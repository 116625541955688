import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import NotificationsRepository from 'repositories/NotificationsRepository';

export type NotificationsSliceStateType = {
  unverifiedBudgetsCount: number;
  unverifiedLicensesCount: number;
  communicatingConnectionsCount: number;
  propertyOnReviewCount: number;
  waitingForAgentResponseConnectionsCount: number;
  contactedConnectionsCount: number;
  fetchStatus: FetchStatus;
};

export type NotificationsSliceActionsType = {
  loadNotificationsCount: () => Promise<void>;
};

const initialState: NotificationsSliceStateType = {
  unverifiedBudgetsCount: null,
  unverifiedLicensesCount: null,
  communicatingConnectionsCount: null,
  propertyOnReviewCount: null,
  waitingForAgentResponseConnectionsCount: null,
  contactedConnectionsCount: null,
  fetchStatus: FetchStatus.idle,
};

const NotificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    loadNotificationsCountStart: (state): void => {
      state.fetchStatus = FetchStatus.pending;
    },
    loadNotificationsCountSuccess: (state, { payload }): void => {
      const {
        contactedConnectionsCount,
        communicatingConnectionsCount,
        unverifiedBudgetsCount,
        unverifiedLicensesCount,
        propertyOnReviewCount,
        waitingForAgentResponseConnectionsCount,
      } = payload;

      state.fetchStatus = FetchStatus.fulfilled;
      state.contactedConnectionsCount = contactedConnectionsCount;
      state.communicatingConnectionsCount = communicatingConnectionsCount;
      state.unverifiedBudgetsCount = unverifiedBudgetsCount;
      state.unverifiedLicensesCount = unverifiedLicensesCount;
      state.propertyOnReviewCount = propertyOnReviewCount;
      state.waitingForAgentResponseConnectionsCount = waitingForAgentResponseConnectionsCount;
    },
    loadNotificationsCountFail: (state): void => {
      state.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = NotificationsSlice;

export const useNotificationsActions = (): NotificationsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadNotificationsCount = async () => {
    dispatch(actions.loadNotificationsCountStart());
    try {
      const notifications = await NotificationsRepository.show();
      dispatch(actions.loadNotificationsCountSuccess(notifications));
    } catch (e) {
      dispatch(actions.loadNotificationsCountFail());
    }
  };

  return {
    loadNotificationsCount,
  };
};

export default NotificationsSlice.reducer;
