import { ConnectionPhase } from 'enums/ConnectionPhase';
import ConnectionProperty from 'types/resources/connection/property';

export const isConnectionClosed = (connectionProperty: ConnectionProperty): boolean => {
  const { phase } = connectionProperty;

  return phase === ConnectionPhase.closed;
};

export const isConnectionContacted = (connectionProperty: ConnectionProperty): boolean => {
  const { phase } = connectionProperty;

  return phase === ConnectionPhase.contacted;
};
