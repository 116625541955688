const bytesInKilobyte = 1024;
const sizeNames = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export const formatBytes = (bytes: number, decimals = 2): string => {
  if (bytes === 0) return '0 Bytes';

  const index = Math.floor(Math.log(bytes) / Math.log(bytesInKilobyte));

  return `${parseFloat((bytes / bytesInKilobyte ** index).toFixed(decimals))} ${sizeNames[index]}`;
};
