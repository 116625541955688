import { FC } from 'react';
import { SvgIcon, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type FacebookProps = {
  sx?: SxProps<Theme>;
};

const Facebook: FC<FacebookProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M31.15 7.0537C31.1326 3.41584 28.4956 0.770962 24.8578 0.763081C18.6728 0.748895 12.4878 0.748895 6.30281 0.763081C2.66495 0.770962 0.0200965 3.41584 0.0106393 7.0537C-0.00354644 13.2387 -0.00354644 19.4237 0.0106393 25.6087C0.0185203 29.2466 2.62397 31.9403 6.2571 31.9671C12.4705 32.0112 18.6854 32.0112 24.8988 31.9671C28.5287 31.9418 31.1421 29.2308 31.1515 25.6102C31.1594 22.5178 31.1531 19.4253 31.1531 16.3328C31.1531 13.2387 31.1641 10.1462 31.15 7.0537ZM25.5403 9.36281C24.5867 9.36281 23.6977 9.35812 22.8087 9.36443C21.7007 9.37073 21.1474 9.93026 21.149 11.0399C21.1506 12.0597 21.149 13.0795 21.149 14.2333C22.6306 14.2333 24.0429 14.2333 25.5245 14.2333C25.5245 15.6535 25.5245 16.9381 25.5245 18.3645C24.0697 18.3645 22.7 18.3645 21.1884 18.3645C21.1884 22.2892 21.1884 26.0768 21.1884 29.9369C19.6737 29.9369 18.3008 29.9369 16.7782 29.9369C16.7782 26.1446 16.7782 22.357 16.7782 18.4417C15.7143 18.4417 14.7922 18.4417 13.8008 18.4417C13.8008 17.02 13.8008 15.7291 13.8008 14.3121C14.7386 14.3121 15.6623 14.3121 16.7483 14.3121C16.7483 13.1 16.7372 12.0077 16.7498 10.917C16.7908 7.61329 18.7579 5.55793 22.0742 5.39716C23.2075 5.34199 24.3455 5.38923 25.5387 5.38923C25.5403 6.79677 25.5403 8.00571 25.5403 9.36281Z"
        fill="currentColor"
      />
      <path
        d="M22.0757 5.39736C18.7594 5.55813 16.7907 7.61349 16.7513 10.9172C16.7371 12.0079 16.7497 13.1002 16.7497 14.3123C15.6622 14.3123 14.7401 14.3123 13.8022 14.3123C13.8022 15.7309 13.8022 17.0218 13.8022 18.4419C14.7937 18.4419 15.7157 18.4419 16.7797 18.4419C16.7797 22.3572 16.7797 26.1448 16.7797 29.9371C18.3023 29.9371 19.6751 29.9371 21.1899 29.9371C21.1899 26.077 21.1899 22.2879 21.1899 18.3647C22.7014 18.3647 24.0712 18.3647 25.526 18.3647C25.526 16.9383 25.526 15.6521 25.526 14.2335C24.0444 14.2335 22.6321 14.2335 21.1505 14.2335C21.1505 13.0813 21.152 12.0599 21.1505 11.0401C21.1489 9.93046 21.7021 9.37093 22.8102 9.36463C23.6992 9.3599 24.5866 9.36301 25.5418 9.36301C25.5418 8.00591 25.5418 6.79701 25.5418 5.38789C24.347 5.38789 23.209 5.34219 22.0757 5.39736Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Facebook;
