import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Property from 'types/resources/property';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import { Meta } from 'types/meta';

export default {
  index(id: ID, params: PropertyFiltersParams): Promise<{ properties: Property[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1AdminUserSellerPropertiesPath(id);

    return Fetcher.get(url, params);
  },
};
