import * as yup from 'yup';

import { NO_SPACES_RULE, MIN_PASSWORD_LENGTH } from './customRules';
import { REQUIRED_ERROR_TEXT, EMAIL_ERROR_TEXT, minLengthErrorText } from './errorTexts';

export type SignInFormData = {
  email: string;
  password: string;
};

export type SignInFormDataToSubmit = {
  account: SignInFormData;
};

const validationFields = {
  email: yup.string().email(EMAIL_ERROR_TEXT).required(REQUIRED_ERROR_TEXT),
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH, minLengthErrorText(MIN_PASSWORD_LENGTH))
    .test(NO_SPACES_RULE)
    .required(REQUIRED_ERROR_TEXT),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: SignInFormData = {
  email: '',
  password: '',
};

export const attributesToSubmit = (values: SignInFormData): SignInFormDataToSubmit => ({ account: values });
