import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';
import Search from 'types/resources/search';
import { Meta } from 'types/meta';

export default {
  index(params: SearchFiltersFormDataParams): Promise<{ searches: Search[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1SearchesPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<Search> {
    const url = apiRoutes.apiWebV1SearchPath(id);

    return Fetcher.get(url);
  },
};
