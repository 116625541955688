import { includes } from 'ramda';

export const NO_SPACES_RULE = {
  name: 'no-spaces',
  test: (value: string): boolean => (value ? !includes(' ', value) : true),
  message: 'spaces are not allowed',
};

export const MIN_PASSWORD_LENGTH = 8;
export const ZIP_CODE_LENGTH = 5;
export const MAX_IMAGE_SIZE_IN_BYTES = 3145728; // 3 MB
export const MAX_FILE_SIZE_IN_BYTES = 1048576; // 1 MB
export const MAX_IMAGES_SHOWCASE_COUNT = 9;
export const MAX_IMAGES_SECTION_COUNT = 3;
export const MAX_FILES_COUNT = 3;
