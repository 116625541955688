import { useAppSelector } from 'hooks/useRedux';
import {
  NotificationsSliceActionsType,
  NotificationsSliceStateType,
  useNotificationsActions,
} from 'store/notificationsSlice';
import getFetchStatus from 'utils/fetchStatus';

type UseNotificationsType = NotificationsSliceStateType &
  NotificationsSliceActionsType & {
    notificationsLoading: boolean;
  };

const useNotifications = (): UseNotificationsType => {
  const {
    unverifiedBudgetsCount,
    unverifiedLicensesCount,
    communicatingConnectionsCount,
    propertyOnReviewCount,
    waitingForAgentResponseConnectionsCount,
    contactedConnectionsCount,
    fetchStatus,
  } = useAppSelector((state) => state.notifications);
  const { loadNotificationsCount } = useNotificationsActions();

  const notificationsLoading = !getFetchStatus(fetchStatus).isFinished;

  return {
    unverifiedBudgetsCount,
    unverifiedLicensesCount,
    communicatingConnectionsCount,
    propertyOnReviewCount,
    waitingForAgentResponseConnectionsCount,
    contactedConnectionsCount,
    fetchStatus,
    loadNotificationsCount,
    notificationsLoading,
  };
};

export default useNotifications;
