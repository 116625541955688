import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    margin: 'auto',
    textAlign: 'center',
  },
  rootAgent: {
    maxWidth: '540px',
  },
  link: {
    color: 'richBlack',
    marginRight: 2,
    marginLeft: 2,
    paddingTop: 0.5,
    paddingBottom: 0.5,
    borderRadius: '0px',
    position: 'relative',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
    },
  },
  linkCommon: {
    color: 'richBlack',
  },
  linkPrimary: {
    color: 'mayGreen',
  },
  linkSecondary: {
    color: 'wave',
  },
  linkActive: {
    fontWeight: 700,
  },
  shouldShowNotificationCommon: {
    '&::after': {
      content: '""',
      position: 'absolute',
      right: -1,
      top: '40%',
      transform: 'translate(100%, -50%)',
      width: 7,
      height: 7,
      borderRadius: '50%',
      backgroundColor: 'richBlack',
    },
  },
  shouldShowNotificationForSeller: {
    '&::after': {
      backgroundColor: 'wave',
    },
  },
  shouldShowNotificationForBuyer: {
    '&::after': {
      backgroundColor: 'middleGreen',
    },
  },
};

export default styles;
