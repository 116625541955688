import Fetcher from 'utils/fetcher';
import { apiRoutes } from 'routes';
import { SignUpFormDataToSubmit } from 'forms/signUpForm';
import { RecoveryPasswordFormDataToSubmit } from 'forms/recoveryPasswordForm';
import { ResetPasswordFormDataToSubmit } from 'forms/resetPasswordForm';
import { AccountSwitchUserType } from 'forms/accountForm';

export default {
  create(params: SignUpFormDataToSubmit): Promise<void> {
    const url = apiRoutes.apiWebV1AccountsPath();

    return Fetcher.post(url, params);
  },
  switchType(params: AccountSwitchUserType): Promise<void> {
    const url = apiRoutes.switchTypeApiWebV1AccountsPath();

    return Fetcher.put(url, params);
  },
  passwordReset(params: RecoveryPasswordFormDataToSubmit): Promise<void> {
    const url = apiRoutes.apiWebV1AccountPasswordResetPath();

    return Fetcher.post(url, params);
  },
  passwordUpdate(params: ResetPasswordFormDataToSubmit): Promise<void> {
    const url = apiRoutes.apiWebV1AccountPasswordResetPath();

    return Fetcher.put(url, params);
  },
  confirm(confirmationToken: string): Promise<void> {
    const url = apiRoutes.confirmApiWebV1AccountsPath();
    const params = { confirmationToken };

    return Fetcher.put(url, params);
  },
  resendConfirmation(): Promise<void> {
    const url = apiRoutes.resendConfirmationApiWebV1AccountsPath();

    return Fetcher.put(url, {});
  },
  acceptTerms(): Promise<void> {
    const url = apiRoutes.acceptTermsApiWebV1AccountsPath();

    return Fetcher.put(url, {});
  },
};
