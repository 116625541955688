import { serialize } from 'object-to-formdata';
import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { isEmpty, set, lensPath, path } from 'ramda';
import { camelizeKeys, decamelizeKeys } from 'utils/keysConverter';

export const axiosInstance = axios.create({
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

function authenticityToken() {
  const token: HTMLMetaElement = document.querySelector('meta[name="csrf-token"]');
  return token ? token.content : null;
}

axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common['X-CSRF-Token'] = authenticityToken();

const handleResponse = ({ data }: AxiosResponse, camelizeResponse: boolean) => {
  if (isEmpty(data)) return {};

  return camelizeResponse ? camelizeKeys(data) : data;
};

const handleError = (axiosError: AxiosError) => {
  const errorsPath = ['response', 'data', 'errors'];
  const axiosErrorResponseWithCamelizedErrors = set(
    lensPath(errorsPath),
    camelizeKeys(path(errorsPath, axiosError)),
    axiosError,
  );

  return Promise.reject(axiosErrorResponseWithCamelizedErrors);
};

const toFormData = <D>(data: D) => {
  const decamelizedParams = decamelizeKeys(data);
  return serialize(decamelizedParams);
};

const Fetcher = {
  get: <R>(url: string, params?: QueryParams, camelizeResponse = true): Promise<R> =>
    axiosInstance
      .get(url, { params: params ? decamelizeKeys(params) : null })
      .then((response: AxiosResponse) => handleResponse(response, camelizeResponse))
      .catch(handleError),
  post: <D, R>(url: string, data?: D, config?: AxiosRequestConfig): Promise<R> =>
    axiosInstance
      .post(url, decamelizeKeys(data), config)
      .then((response: AxiosResponse) => handleResponse(response, true))
      .catch(handleError),
  put: <D, R>(url: string, data: D, config?: AxiosRequestConfig): Promise<R> =>
    axiosInstance
      .put(url, decamelizeKeys(data), config)
      .then((response: AxiosResponse) => handleResponse(response, true))
      .catch(handleError),
  patch: <D, R>(url: string, data: D): Promise<R> =>
    axiosInstance
      .patch(url, decamelizeKeys(data))
      .then((response: AxiosResponse) => handleResponse(response, true))
      .catch(handleError),
  delete: (url: string): Promise<null> =>
    axiosInstance.delete(url).then((response: AxiosResponse) => handleResponse(response, true)),
  postFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': authenticityToken(),
        },
      })
      .then((response: AxiosResponse) => handleResponse(response, true))
      .catch(handleError);
  },
  putFormData: <D, R>(url: string, data: D): Promise<R> => {
    const formData = toFormData(data);
    return axios
      .put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-CSRF-Token': authenticityToken(),
        },
      })
      .then((response: AxiosResponse) => handleResponse(response, true))
      .catch(handleError);
  },
  postExternal: <D, R>(url: string, data: D, config?: AxiosRequestConfig): Promise<R> => {
    return axios
      .post(url, data, config)
      .then((response: AxiosResponse) => handleResponse(response, true))
      .catch(handleError);
  },
};

export default Fetcher;
