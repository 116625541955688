import User from 'types/resources/user';
import State from 'types/resources/state';
import { withComma } from 'presenters/NumberPresenter';
import { fullName } from 'presenters/UsersPresenter';
import { ConnectionsTileType } from 'enums/ConnectionsTileType';

export type ConnectionsDashboardType = {
  key: string;
  title: string;
  value?: (value: User | State) => string;
  countNowadays?: {
    key: string;
    value: (value: number) => string;
  };
  countPast?: {
    key: string;
    value: (value: number) => string;
  };
};

export const DASHBOARD_KEYS: ConnectionsDashboardType[] = [
  {
    key: ConnectionsTileType.totalSalesByMonth,
    title: 'Total Sales',
    countNowadays: {
      key: 'thisMonth',
      value: (value: number): string => withComma(value),
    },
    countPast: {
      key: 'lastMonth',
      value: (value: number): string => withComma(value),
    },
  },
  {
    key: ConnectionsTileType.mostSalesState,
    title: 'Most sales in State',
    value: (value: State): string => value.name,
  },
  {
    key: ConnectionsTileType.connectionRatio,
    title: 'Connection Created / Connection closed',
    countNowadays: {
      key: 'allConnectionsCount',
      value: (value: number): string => withComma(value),
    },
    countPast: {
      key: 'closedConnectionsCount',
      value: (value: number): string => withComma(value),
    },
  },
  {
    key: ConnectionsTileType.mostSalesAgent,
    title: 'Agent sales',
    value: (value: User): string => fullName(value),
  },
];

export const NAMES_CONNECTIONS_SORTING = 'name';
export const SUCCESS_CLOSED_SORTING = 'total_success_sales_amount';
