import { UserType } from 'enums/UserType';
import { appRoutes } from 'routes';
import { MenuItemType } from 'types/navigation';
import User from 'types/resources/user';

const BUYER_MENU_ITEMS: MenuItemType[] = [
  {
    label: 'My Searches',
    path: appRoutes.searchesPath(),
  },
  {
    label: 'My Connections',
    path: appRoutes.connectionsPath(),
    notificationFactors: ['contactedConnectionsCount'],
  },
];

const SELLER_MENU_ITEMS: MenuItemType[] = [
  {
    label: 'Pre-Qualified Buyers',
    path: appRoutes.browseBuyersPath(),
  },
  {
    label: 'Buyer Connections',
    path: appRoutes.connectionsPath(),
    notificationFactors: ['contactedConnectionsCount'],
  },
  {
    label: 'My Favorite Buyers',
    path: appRoutes.favoriteSearchesPath(),
  },
  {
    label: 'My Property',
    path: appRoutes.propertiesPath(),
  },
];

const AGENT_MENU_ITEMS: MenuItemType[] = [
  {
    label: 'Profile',
    path: appRoutes.profilePath(),
  },
  {
    label: 'Pre-Qualified Buyers',
    items: [
      {
        label: 'My Qualified Buyers',
        path: appRoutes.buyersPath(),
      },
      {
        label: 'All Qualified Buyers',
        path: appRoutes.browseBuyersPath(),
      },
    ],
  },
  {
    label: 'My Homeowners',
    path: appRoutes.sellersPath(),
  },
  {
    label: 'My Clients Matches',
    path: appRoutes.clientsPath(),
  },
  {
    label: 'Dashboard',
    path: appRoutes.dashboardPath(),
  },
  {
    label: 'Off Market Connections',
    path: appRoutes.connectionsPath(),
    notificationFactors: ['waitingForAgentResponseConnectionsCount', 'contactedConnectionsCount'],
  },
];

const ADMIN_MENU_ITEMS: MenuItemType[] = [
  {
    label: 'Dashboard',
    path: appRoutes.adminDashboardPath(),
  },
  {
    label: 'Users',
    path: appRoutes.adminUsersPath(),
    notificationFactors: ['unverifiedLicensesCount', 'unverifiedBudgetsCount'],
  },
  {
    label: 'Connections',
    path: appRoutes.adminConnectionsPath(),
    notificationFactors: ['communicatingConnectionsCount'],
  },
  {
    label: 'Properties',
    path: appRoutes.adminPropertiesPath(),
    notificationFactors: ['propertyOnReviewCount'],
  },
  {
    label: 'Searches',
    path: appRoutes.adminSearchesPath(),
  },
  {
    label: 'Feedback',
    items: [
      {
        label: 'Feedback',
        path: appRoutes.adminFeedbackPath(),
      },
      {
        label: 'Agent Scorecard ',
        path: appRoutes.adminAgentScorecardPath(),
      },
    ],
  },
  {
    label: 'Geodata',
    path: appRoutes.adminGeoData(),
  },
];

export const getUserNavigationLinks = (user: User): MenuItemType[] => {
  switch (user.type) {
    case UserType.buyer: {
      return BUYER_MENU_ITEMS;
    }
    case UserType.seller: {
      return SELLER_MENU_ITEMS;
    }
    case UserType.agent: {
      return AGENT_MENU_ITEMS;
    }
    case UserType.admin: {
      return ADMIN_MENU_ITEMS;
    }
    default: {
      return [];
    }
  }
};
