import React, { FC, FormEvent, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormikHelpers, useFormik } from 'formik';
import { TextField, Typography, InputLabel, Link, Alert, InputAdornment, IconButton } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { extractResponseErrors, isAxiosError, isUnprocessedEntityError } from 'utils/responseErrors';
import { SignInFormData, initialValues, validationSchema, attributesToSubmit } from 'forms/signInForm';
import SocialAuthButton from 'components/SocialAuthButton';
import { SocialButton } from 'enums/SocialButton';
import { appRoutes } from 'routes';
import useUsers from 'hooks/useUsers';
import SessionRepository from 'repositories/SessionRepository';
import Box from 'components/Box';
import OrDivider from 'components/OrDivider';
import { getParamFromRoute } from 'utils/urlHelper';

import styles from './styles';

type SignInProps = {
  onClose: () => void;
};

const SignIn: FC<SignInProps> = (props): JSX.Element => {
  const { onClose } = props;
  const history = useHistory();

  const [formError, setFormError] = useState(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { loadCurrentUser } = useUsers();

  useEffect(() => {
    setFormError(getParamFromRoute('message'));
  }, []);

  const handleSubmit = async (
    formData: SignInFormData,
    { setSubmitting, setErrors }: FormikHelpers<SignInFormData>,
  ) => {
    const params = attributesToSubmit(formData);
    setFormError(null);

    try {
      await SessionRepository.create(params);
      await loadCurrentUser();
      onClose();
      history.push(appRoutes.searchesPath());
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setErrors(errors);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { values, errors, submitForm, touched, handleChange, isSubmitting } = useFormik<SignInFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitForm();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.title} variant="h3" align="center">
        Log In to ZigZago
      </Typography>
      {formError && (
        <Alert sx={styles.alert} severity="error">
          <Typography variant="subtitle2" component="p">
            {formError}
          </Typography>
        </Alert>
      )}
      <form onSubmit={handleFormSubmit}>
        <InputLabel>Email Address</InputLabel>
        <TextField
          sx={styles.input}
          name="email"
          fullWidth
          value={values.email}
          onChange={handleChange}
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          autoComplete="username"
        />
        <InputLabel sx={styles.passwordLabel}>
          <div>Password</div>
          <Typography variant="body1" component="p">
            <Link color="primary" href={appRoutes.recoveryPasswordPath()}>
              Restore Password
            </Link>
          </Typography>
        </InputLabel>
        <TextField
          sx={styles.input}
          name="password"
          fullWidth
          value={values.password}
          onChange={handleChange}
          error={touched.password && !!errors.password}
          helperText={touched.password && errors.password}
          type={showPassword ? 'text' : 'password'}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <LoadingButton
          sx={styles.button}
          type="submit"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Log In
        </LoadingButton>
      </form>
      <Typography sx={styles.signUp} align="center" variant="subtitle2" component="p">
        Don’t have an account?{' '}
        <Link sx={styles.link} color="primary" href={appRoutes.signUpPath()}>
          Sign Up
        </Link>
      </Typography>
      <OrDivider sx={styles.orDivider} />
      <SocialAuthButton
        buttonText="Log In with Google"
        buttonType={SocialButton.google}
        fullWidth
        sx={styles.socialButton}
      />
      {/* <SocialAuthButton buttonText="Log in with Facebook" buttonType={SocialButton.facebook} fullWidth /> */}
    </Box>
  );
};

export default SignIn;
