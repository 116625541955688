import { FC, ReactElement } from 'react';
import { isNil } from 'ramda';
import { Toolbar, Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo';
import useAuthModals from 'hooks/useAuthModals';
import useUsers from 'hooks/useUsers';
import { appRoutes } from 'routes';
import useRouter from 'hooks/useRouter';
import Navbar from 'components/Navbar';
import Box from 'components/Box';

import Menu from './components/Menu';
import styles from './styles';

const HeaderToolbar: FC = (): ReactElement => {
  const { match } = useRouter();
  const { openSignInModal } = useAuthModals();
  const { currentUser } = useUsers();

  const isActionsVisible = isNil(match(appRoutes.signUpPath()));

  const renderActions = isNil(currentUser) ? (
    <Grid container spacing={2}>
      <Grid item flexGrow={1} textAlign="center">
        <Button sx={styles.menuItem} component={Link} to={appRoutes.rootPath()} size="small">
          Home
        </Button>
        <Button sx={styles.menuItem} component={Link} to={appRoutes.rootPath()} size="small">
          About
        </Button>
      </Grid>
      <Grid item>
        <Box>
          <Button sx={styles.buttonSignUp} component={Link} to={appRoutes.signUpPath()} size="small">
            Sign Up
          </Button>
          <Button sx={styles.buttonSignIn} color="primary" onClick={openSignInModal} size="small">
            Log In
          </Button>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Menu />
  );

  return (
    <Toolbar disableGutters sx={styles.toolbar}>
      <Logo />
      {currentUser && <Navbar user={currentUser} />}
      {isActionsVisible && renderActions}
    </Toolbar>
  );
};

export default HeaderToolbar;
