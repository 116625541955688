import { FC } from 'react';
import { SvgIcon, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type GoProps = {
  sx?: SxProps<Theme>;
};

const Go: FC<GoProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} viewBox="0 0 838 838" width="64" height="64" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M418.71 837.32C649.924 837.32 837.36 649.884 837.36 418.67C837.36 187.456 649.924 0.0200195 418.71 0.0200195C187.496 0.0200195 0.0600586 187.456 0.0600586 418.67C0.0600586 649.884 187.496 837.32 418.71 837.32Z"
        fill="currentColor"
      />
      <path
        d="M403.99 391.59C351.7 391.54 294.23 391.18 249.66 391.12C243.53 391.11 238.57 396.08 238.57 402.21V455.06C238.57 455.54 242.94 455.54 252.14 455.54C271.51 455.54 306.87 455.06 322.38 455.06H330.14C316.09 488.01 277.34 507.38 238.58 507.38C173.06 507.38 122.3 448.9 136.28 379.82C144.88 337.33 179.98 304.95 222.94 299.17C264.87 293.53 301.79 310.83 323.17 341.12C326.3 345.55 332.26 346.94 337.11 344.5C359.06 333.49 365.64 330.04 384.27 320.54C390.16 317.54 392.19 310.05 388.51 304.56C356.18 256.43 300.85 223.9 238.34 223.98C139.25 224.11 57.76 307.53 59.87 406.6C61.94 503.64 141.53 581.97 238.58 581.97C305.92 581.97 365.98 544.67 395.54 488.96C409.93 470.3 414.01 425.43 415.06 403.18C415.35 396.88 410.31 391.6 403.99 391.59Z"
        fill="white"
      />
      <path
        opacity="0.95"
        d="M403.99 391.59C351.7 391.54 294.23 391.18 249.66 391.12C243.53 391.11 238.57 396.08 238.57 402.21V455.06C238.57 455.54 242.94 455.54 252.14 455.54C271.51 455.54 306.87 455.06 322.38 455.06H330.14C316.09 488.01 277.34 507.38 238.58 507.38C173.06 507.38 122.3 448.9 136.28 379.82C144.88 337.33 179.98 304.95 222.94 299.17C264.87 293.53 301.79 310.83 323.17 341.12C326.3 345.55 332.26 346.94 337.11 344.5C359.06 333.49 365.64 330.04 384.27 320.54C390.16 317.54 392.19 310.05 388.51 304.56C356.18 256.43 300.85 223.9 238.34 223.98C139.25 224.11 57.76 307.53 59.87 406.6C61.94 503.64 141.53 581.97 238.58 581.97C305.92 581.97 365.98 544.67 395.54 488.96C409.93 470.3 414.01 425.43 415.06 403.18C415.35 396.88 410.31 391.6 403.99 391.59Z"
        fill="#00829B"
      />
      <path
        d="M607.93 582.3C508.81 582.3 428.62 502.13 428.62 402.99C428.62 303.37 508.8 223.19 607.93 223.19C706.58 223.19 787.73 303.37 787.73 402.99C787.73 502.12 706.58 582.3 607.93 582.3ZM501.99 402.99C501.99 460.81 550.1 508.93 607.93 508.93C665.76 508.93 712.9 460.82 712.9 402.99C712.9 345.16 665.77 296.57 607.93 296.57C550.11 296.56 501.99 345.16 501.99 402.99Z"
        fill="#00829B"
      />
      <path
        opacity="0.7"
        d="M640.07 183.72C636.21 187.6 629.98 187.69 625.97 183.96C570.32 132.12 498.21 103.69 421.82 103.69C345.44 103.69 273.34 132.12 217.68 183.96C213.67 187.69 207.44 187.6 203.58 183.72C199.56 179.67 199.65 173.07 203.83 169.18C263.26 113.81 340.25 83.4399 421.81 83.4399C503.37 83.4399 580.38 113.81 639.8 169.18C644 173.07 644.1 179.67 640.07 183.72Z"
        fill="#00829B"
      />
      <path
        d="M421.83 723.5C339.87 723.5 262.6 692.89 203.09 637.09C198.93 633.19 198.85 626.59 202.88 622.55C206.75 618.68 212.98 618.6 216.98 622.35C272.72 674.6 345.08 703.25 421.83 703.25C498.59 703.25 570.95 674.6 626.68 622.35C630.68 618.6 636.91 618.67 640.78 622.55C644.81 626.59 644.73 633.18 640.57 637.09C581.07 692.9 503.8 723.5 421.83 723.5Z"
        fill="#95CC31"
      />
    </SvgIcon>
  );
};

export default Go;
