import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import Connection from 'types/resources/connection';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';
import { ConnectionEvent } from 'enums/ConnectionEvent';
import { CloseConnectionFormData } from 'forms/closeConnectionForm';
import { ConnectionCreationParams } from 'store/sellerConnectionsSlice';

export default {
  index(params: ConnectionFiltersParams): Promise<{ connections: Connection[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1UserAgentConnectionsPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<Connection> {
    const url = apiRoutes.apiWebV1UserAgentConnectionPath(id);

    return Fetcher.get(url);
  },
  create(params: ConnectionCreationParams): Promise<void> {
    const url = apiRoutes.apiWebV1UserAgentConnectionsPath();

    return Fetcher.post(url, params);
  },
  changePhase(id: ID, event: ConnectionEvent): Promise<Connection> {
    const params = {
      connection: {
        event,
      },
    };
    const url = apiRoutes.changePhaseApiWebV1UserAgentConnectionPath(id);

    return Fetcher.put(url, params);
  },
  archive(id: ID): Promise<void> {
    const url = apiRoutes.apiWebV1UserAgentConnectionPath(id);

    return Fetcher.delete(url);
  },
  close(id: ID, params: CloseConnectionFormData): Promise<void> {
    const url = apiRoutes.closeApiWebV1UserAgentConnectionPath(id);

    return Fetcher.put(url, params);
  },
};
