import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import AgentConnectionsDashboard from 'types/resources/agentConnectionsDashboard';
import ConnectionsDashboardRepository from 'repositories/Admin/User/Agent/ConnectionsDashboardRepository';

export type AgentConnectionsDashboardSliceStateType = {
  dashboard: {
    data: AgentConnectionsDashboard;
    fetchStatus: FetchStatus;
  };
};

export type AgentConnectionsDashboardSliceActionsType = {
  loadAgentConnectionsDashboard: (id: ID) => void;
  resetAgentConnectionsDashboard: () => void;
};

const initialState: AgentConnectionsDashboardSliceStateType = {
  dashboard: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AgentConnectionsDashboardSlice = createSlice({
  name: 'agentConnectionsDashboard',
  initialState,
  reducers: {
    loadAgentConnectionsDashboardStart: (state): void => {
      state.dashboard.fetchStatus = FetchStatus.pending;
    },
    loadAgentConnectionsDashboardFinish: (state, { payload }): void => {
      const { agentConnectionsDashboard } = payload;

      state.dashboard.fetchStatus = FetchStatus.fulfilled;
      state.dashboard.data = agentConnectionsDashboard;
    },
    loadAgentConnectionsDashboardFail: (state): void => {
      state.dashboard.fetchStatus = FetchStatus.failed;
    },

    resetAgentConnectionsDashboard: (): AgentConnectionsDashboardSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AgentConnectionsDashboardSlice;

export const useAgentConnectionsDashboardActions = (): AgentConnectionsDashboardSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAgentConnectionsDashboard = async (id: ID) => {
    dispatch(actions.loadAgentConnectionsDashboardStart());
    try {
      const dashboard = await ConnectionsDashboardRepository.show(id);
      dispatch(actions.loadAgentConnectionsDashboardFinish(dashboard));
    } catch (error: unknown) {
      dispatch(actions.loadAgentConnectionsDashboardFail());
    }
  };

  const resetAgentConnectionsDashboard = () => dispatch(actions.resetAgentConnectionsDashboard());

  return {
    loadAgentConnectionsDashboard,
    resetAgentConnectionsDashboard,
  };
};

export default AgentConnectionsDashboardSlice.reducer;
