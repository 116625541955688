import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { SavedSearchFilterParams } from 'forms/savedSearchFilterForm';
import { useAppDispatch } from 'hooks/useRedux';
import SearchFilterRepository from 'repositories/SearchFilterRepository';
import SearchFilter from 'types/resources/searchFilter';

export type SearchFiltersSliceStateType = {
  searchFilters: {
    data: SearchFilter[];
    fetchStatus: FetchStatus;
  };
};

export type SearchFiltersSliceActionsType = {
  loadSearchFilters: () => void;
  createSearchFilter: (data: SavedSearchFilterParams) => void;
  deleteSearchFilter: (id: ID) => void;
  resetSearchFilters: () => void;
};

const initialState: SearchFiltersSliceStateType = {
  searchFilters: {
    data: [],
    fetchStatus: FetchStatus.idle,
  },
};

const SearchFiltersSlice = createSlice({
  name: 'searchFilters',
  initialState,
  reducers: {
    loadSearchFiltersStart: (state): void => {
      state.searchFilters.fetchStatus = FetchStatus.pending;
    },
    loadSearchFiltersFinish: (state, { payload }): void => {
      const { searchFilters } = payload;

      state.searchFilters.fetchStatus = FetchStatus.fulfilled;
      state.searchFilters.data = searchFilters;
    },
    loadSearchFiltersFail: (state): void => {
      state.searchFilters.fetchStatus = FetchStatus.failed;
    },

    resetSearchFilters: (): SearchFiltersSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = SearchFiltersSlice;

export const useSearchFiltersActions = (): SearchFiltersSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadSearchFilters = async () => {
    dispatch(actions.loadSearchFiltersStart());
    try {
      const users = await SearchFilterRepository.index();
      dispatch(actions.loadSearchFiltersFinish(users));
    } catch (error: unknown) {
      dispatch(actions.loadSearchFiltersFail());
    }
  };

  const createSearchFilter = async (data: SavedSearchFilterParams) => {
    await SearchFilterRepository.create(data);
  };

  const deleteSearchFilter = async (id: ID) => {
    await SearchFilterRepository.delete(id);
  };

  const resetSearchFilters = () => dispatch(actions.resetSearchFilters());

  return {
    loadSearchFilters,
    createSearchFilter,
    deleteSearchFilter,
    resetSearchFilters,
  };
};

export default SearchFiltersSlice.reducer;
