import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import Property from 'types/resources/property';
import PropertiesRepository from 'repositories/Seller/PropertiesRepository';
import { Meta } from 'types/meta';
import { PropertyEvent } from 'enums/PropertyEvent';

export type SellerPropertiesSliceStateType = {
  sellerProperties: {
    data: Property[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
  };
  sellerProperty: {
    fetchStatus: FetchStatus;
    data: Property | null;
  };
  sellerPropertyPhaseChanged: { fetchStatus: FetchStatus };
  sellerPropertyArchived: { fetchStatus: FetchStatus };
};

export type SellerPropertiesSliceActionsType = {
  loadSellerProperties: (params: PropertyFiltersParams) => void;
  loadMatchesStatus: (id: ID) => Promise<Property>;
  resetSellerProperties: () => void;
  loadSellerProperty: (id: ID) => void;
  changeSellerPropertyPhase: (id: ID, event: PropertyEvent) => void;
  archiveSellerProperty: (id: ID) => void;
};

const initialState: SellerPropertiesSliceStateType = {
  sellerProperties: {
    fetchStatus: FetchStatus.idle,
    data: [],
    meta: null,
  },
  sellerProperty: {
    fetchStatus: FetchStatus.idle,
    data: null,
  },
  sellerPropertyPhaseChanged: {
    fetchStatus: FetchStatus.idle,
  },
  sellerPropertyArchived: {
    fetchStatus: FetchStatus.idle,
  },
};

const SellerPropertiesSlice = createSlice({
  name: 'sellerProperties',
  initialState,
  reducers: {
    loadPropertiesStart: (state): void => {
      state.sellerProperties.fetchStatus = FetchStatus.pending;
    },
    loadPropertiesFinish: (state, { payload }): void => {
      const { properties, meta } = payload;

      state.sellerProperties.fetchStatus = FetchStatus.fulfilled;
      state.sellerProperties.data = properties;
      state.sellerProperties.meta = meta;
    },
    loadPropertiesFail: (state): void => {
      state.sellerProperties.fetchStatus = FetchStatus.failed;
    },
    resetSellerProperties: (): SellerPropertiesSliceStateType => {
      return initialState;
    },
    loadSellerPropertyStart: (state): void => {
      state.sellerProperty.fetchStatus = FetchStatus.pending;
    },
    loadSellerPropertyFinish: (state, { payload }): void => {
      const { property } = payload;

      state.sellerProperty.fetchStatus = FetchStatus.fulfilled;
      state.sellerProperty.data = property;
    },
    loadSellerPropertyFail: (state): void => {
      state.sellerProperty.fetchStatus = FetchStatus.failed;
    },
    changeSellerPropertyPhaseStart: (state): void => {
      state.sellerPropertyPhaseChanged.fetchStatus = FetchStatus.pending;
    },
    changeSellerPropertyPhaseFinish: (state): void => {
      state.sellerPropertyPhaseChanged.fetchStatus = FetchStatus.fulfilled;
    },
    changeSellerPropertyPhaseFail: (state): void => {
      state.sellerPropertyPhaseChanged.fetchStatus = FetchStatus.failed;
    },
    archiveSellerPropertyStart: (state): void => {
      state.sellerPropertyArchived.fetchStatus = FetchStatus.pending;
    },
    archiveSellerPropertyFinish: (state): void => {
      state.sellerPropertyArchived.fetchStatus = FetchStatus.fulfilled;
    },
    archiveSellerPropertyFail: (state): void => {
      state.sellerPropertyArchived.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = SellerPropertiesSlice;

export const useSellerPropertiesActions = (): SellerPropertiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadSellerProperties = async (params: PropertyFiltersParams = {}) => {
    dispatch(actions.loadPropertiesStart());
    try {
      const properties = await PropertiesRepository.index(params);
      dispatch(actions.loadPropertiesFinish(properties));
    } catch (error: unknown) {
      dispatch(actions.loadPropertiesFail());
    }
  };

  const loadSellerProperty = async (id: ID) => {
    dispatch(actions.loadSellerPropertyStart());
    try {
      const search = await PropertiesRepository.show(id);
      dispatch(actions.loadSellerPropertyFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadSellerPropertyFail());
    }
  };

  const resetSellerProperties = () => dispatch(actions.resetSellerProperties());

  const changeSellerPropertyPhase = async (id: ID, event: PropertyEvent) => {
    dispatch(actions.changeSellerPropertyPhaseStart());
    try {
      await PropertiesRepository.changePhase(id, event);
      dispatch(actions.changeSellerPropertyPhaseFinish());
    } catch (error: unknown) {
      dispatch(actions.changeSellerPropertyPhaseFail());
    }
  };

  const archiveSellerProperty = async (id: ID) => {
    dispatch(actions.archiveSellerPropertyStart());
    try {
      await PropertiesRepository.archive(id);
      dispatch(actions.archiveSellerPropertyFinish());
    } catch (error: unknown) {
      dispatch(actions.archiveSellerPropertyFail());
    }
  };

  const loadMatchesStatus = async (id: ID) => {
    const { property } = await PropertiesRepository.showMatchesStatus(id);
    return property;
  };

  return {
    loadSellerProperties,
    loadMatchesStatus,
    resetSellerProperties,
    loadSellerProperty,
    changeSellerPropertyPhase,
    archiveSellerProperty,
  };
};

export default SellerPropertiesSlice.reducer;
