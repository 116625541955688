import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import User from 'types/resources/user';
import { LicenseRejectParams } from 'forms/licenseRejectForm';
import ProfileRepository from 'repositories/Admin/User/Agent/ProfileRepository';
import LicenseRepository from 'repositories/Admin/User/Agent/LicenseRepository';

export type AdminAgentsSliceStateType = {
  agent: {
    data: User;
    fetchStatus: FetchStatus;
  };
};

export type AdminAgentsSliceActionsType = {
  loadAgent: (id: ID) => void;
  approveLicenseAgent: (id: ID, licenseId: ID) => void;
  declineLicenseAgent: (id: ID, licenseId: ID, params: LicenseRejectParams) => void;
  resetAgent: () => void;
};

const initialState: AdminAgentsSliceStateType = {
  agent: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminAgentsSlice = createSlice({
  name: 'adminAgents',
  initialState,
  reducers: {
    loadAgentStart: (state): void => {
      state.agent.fetchStatus = FetchStatus.pending;
    },
    loadAgentFinish: (state, { payload }): void => {
      const { user } = payload;

      state.agent.fetchStatus = FetchStatus.fulfilled;
      state.agent.data = user;
    },
    loadAgentFail: (state): void => {
      state.agent.fetchStatus = FetchStatus.failed;
    },
    resetAgent: (): AdminAgentsSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminAgentsSlice;

export const useAdminAgentsActions = (): AdminAgentsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAgent = async (id: ID) => {
    dispatch(actions.loadAgentStart());
    try {
      const user = await ProfileRepository.show(id);
      dispatch(actions.loadAgentFinish(user));
    } catch (error: unknown) {
      dispatch(actions.loadAgentFail());
    }
  };

  const approveLicenseAgent = (id: ID, licenseId: ID) => LicenseRepository.approveLicense(id, licenseId);

  const declineLicenseAgent = (id: ID, licenseId: ID, params: LicenseRejectParams) =>
    LicenseRepository.declineLicense(id, licenseId, params);

  const resetAgent = () => dispatch(actions.resetAgent());

  return {
    loadAgent,
    approveLicenseAgent,
    declineLicenseAgent,
    resetAgent,
  };
};

export default AdminAgentsSlice.reducer;
