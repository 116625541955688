import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import User from 'types/resources/user';
import ProfileRepository from 'repositories/Admin/User/Buyer/ProfileRepository';
import BudgetRepository from 'repositories/Admin/User/Buyer/BudgetRepository';

export type AdminBuyerSliceStateType = {
  adminBuyer: {
    data: User;
    fetchStatus: FetchStatus;
  };
};

export type AdminBuyerSliceActionsType = {
  loadAdminBuyer: (id: ID) => void;
  resetAdminBuyer: () => void;
  approveBudgetAdminBuyer: (id: ID) => void;
  declineBudgetAdminBuyer: (id: ID) => void;
};

const initialState: AdminBuyerSliceStateType = {
  adminBuyer: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminBuyerSlice = createSlice({
  name: 'adminBuyer',
  initialState,
  reducers: {
    loadAdminBuyerStart: (state): void => {
      state.adminBuyer.fetchStatus = FetchStatus.pending;
    },
    loadAdminBuyerFinish: (state, { payload }): void => {
      const { user } = payload;

      state.adminBuyer.fetchStatus = FetchStatus.fulfilled;
      state.adminBuyer.data = user;
    },
    loadAdminBuyerFail: (state): void => {
      state.adminBuyer.fetchStatus = FetchStatus.failed;
    },

    resetAdminBuyer: (): AdminBuyerSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminBuyerSlice;

export const useAdminBuyerActions = (): AdminBuyerSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminBuyer = async (id: ID) => {
    dispatch(actions.loadAdminBuyerStart());
    try {
      const users = await ProfileRepository.show(id);
      dispatch(actions.loadAdminBuyerFinish(users));
    } catch (error: unknown) {
      dispatch(actions.loadAdminBuyerFail());
    }
  };

  const resetAdminBuyer = () => dispatch(actions.resetAdminBuyer());

  const approveBudgetAdminBuyer = (id: ID) => BudgetRepository.approveBudget(id);

  const declineBudgetAdminBuyer = (id: ID) => BudgetRepository.declineBudget(id);

  return {
    loadAdminBuyer,
    resetAdminBuyer,
    approveBudgetAdminBuyer,
    declineBudgetAdminBuyer,
  };
};

export default AdminBuyerSlice.reducer;
