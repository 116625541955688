import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiInputLabelType = {
  defaultProps: ComponentsProps['MuiInputLabel'];
  styleOverrides: ComponentsOverrides['MuiInputLabel'];
};

const MuiInputLabel: MuiInputLabelType = {
  styleOverrides: {
    root: {
      fontSize: 16,
      color: '#4D738A',
      marginBottom: '8px',
      fontWeight: 500,
    },
  },
  defaultProps: {},
};

export default MuiInputLabel;
