import { PaletteOptions } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    default: Palette['primary'];
  }

  interface PaletteOptions {
    default: Palette['primary'];
  }
}

const COLORS = {
  green: '#24A300',
  greenLight: '#dcead9',
  paleGreen: '#E9EDE8',
  pale: '#F6FAF5',
  white: '#FFFFFF',
  black: '#000000',
  blackForest: '#272926',
  darkGrey: '#313430',
  richGrey: '#50524F',
  simpleGrey: '#9FA0A1',
  middleGrey: '#868985',
  paleGrey: '#D1D3D0',
  shadeGrey: '#f4f4f4',
  orange: '#F9BA5B',
  darkGreen: '#5D924E',
  brightGreen: '#38C610',
  red: '#F40835',
  paleRed: '#fcebe7',
  brightRed: '#DE350B',
  panache: '#EDF5EB',
  limeGreen: '#d6ebd6',
  appleGreen: '#53923f',
  mintGreen: '#e9f6e5',
  lightRed: '#fffcfb',
  cinnabar: '#E65A37',
  wave: '#17768E',
  mayGreen: '#8BC63F',
  shadeWave: '#e2edf0',
  paleWave: '#fbfcfd',
  waveLight: '#D2E5E9',
  waveGrey: '#4D738A',
  middleGreen: '#A1C84F',
  waveWeak: '#D1E5E9',
  lightGreen: '#70b261',
  richBlack: '#020204',
  fordWhite: '#FFF5F5',
  gray: '#707070',
};

const palette: PaletteOptions = {
  primary: {
    main: COLORS.mayGreen,
  },
  secondary: {
    main: COLORS.wave,
  },
  default: {
    main: COLORS.richGrey,
    light: COLORS.richGrey,
    dark: COLORS.richGrey,
    contrastText: COLORS.richGrey,
  },
  ...COLORS,
};

export default palette;
