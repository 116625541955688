import { FC, useEffect, Suspense } from 'react';
import { isNil } from 'ramda';
import { Provider } from 'react-redux';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { appRoutes } from 'routes';
import { store } from 'store';
import { BrowserRouter as Router } from 'react-router-dom';
import UserSwitch from 'routeSwitches/UserSwitch';
import NonUserSwitch from 'routeSwitches/NonUserSwitch';
import useUsers from 'hooks/useUsers';
import useAccounts from 'hooks/useAccounts';
import useRouter from 'hooks/useRouter';
import useAuthModals from 'hooks/useAuthModals';
import AuthModal from 'components/AuthModal';
import Loader from 'components/Loader';
import { isAxiosError, isForbiddenError } from 'utils/responseErrors';
import useNotifications from 'hooks/useNotifications';
import useScrollToTop from 'hooks/useScrollToTop';
import 'utils/errorTracking';

import theme from './theme';

const App: FC = () => {
  const { loadCurrentUser, currentUser, currentUserLoading } = useUsers();
  const { confirmAccount } = useAccounts();
  const { openConfirmSuccessModal, openConfirmFailModal } = useAuthModals();
  const { match, getUrlParam } = useRouter();
  const { loadNotificationsCount, notificationsLoading } = useNotifications();

  useScrollToTop();

  const isConfirmPath = match(appRoutes.confirmPath());

  const handleUserConfirm = async (token: string) => {
    try {
      await confirmAccount(token);
      openConfirmSuccessModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isForbiddenError(error)) {
        openConfirmFailModal();
      }
    }
  };

  useEffect(() => {
    loadCurrentUser();
    if (isConfirmPath) {
      const token = getUrlParam('confirmation_token');
      handleUserConfirm(token);
    }
  }, []);

  useEffect(() => {
    if (!isNil(currentUser)) {
      loadNotificationsCount();
    }
  }, [currentUser]);

  const renderSwitch = () => {
    if (isNil(currentUser)) {
      return <NonUserSwitch />;
    }
    return <UserSwitch user={currentUser} />;
  };

  const shouldShowLoader = !isNil(currentUser) ? currentUserLoading || notificationsLoading : currentUserLoading;

  if (shouldShowLoader) return <Loader />;

  return (
    <>
      {renderSwitch()}
      <AuthModal />
    </>
  );
};

const UserApp: FC = () => (
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Suspense fallback={<Loader />}>
            <Router>
              <App />
            </Router>
          </Suspense>
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
);

export default UserApp;
