import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Property from 'types/resources/property';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import { Meta } from 'types/meta';
import { PropertyRejectParams } from 'forms/propertyRejectForm';

export default {
  index(params: PropertyFiltersParams): Promise<{ properties: Property[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1AdminPropertiesPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<Property> {
    const url = apiRoutes.apiWebV1AdminPropertyPath(id);

    return Fetcher.get(url);
  },
  approve(id: ID): Promise<void> {
    const url = apiRoutes.approveApiWebV1AdminPropertyPath(id);

    return Fetcher.put(url, {});
  },
  reject(id: ID, data: PropertyRejectParams): Promise<void> {
    const url = apiRoutes.rejectApiWebV1AdminPropertyPath(id);

    return Fetcher.put(url, data);
  },
};
