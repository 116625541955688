import * as yup from 'yup';

import { NO_SPACES_RULE, MIN_PASSWORD_LENGTH } from './customRules';
import { REQUIRED_ERROR_TEXT, minLengthErrorText, PASSWORDS_DONT_MATCHES } from './errorTexts';

export type ResetPasswordFormData = {
  password: string;
  passwordConfirmation: string;
};

export type ResetPasswordFormDataToSubmit = {
  passwordReset: ResetPasswordFormData;
  passwordResetToken: string;
};

const validationFields = {
  password: yup
    .string()
    .min(MIN_PASSWORD_LENGTH, minLengthErrorText(MIN_PASSWORD_LENGTH))
    .test(NO_SPACES_RULE)
    .required(REQUIRED_ERROR_TEXT),
  passwordConfirmation: yup
    .string()
    .min(MIN_PASSWORD_LENGTH, minLengthErrorText(MIN_PASSWORD_LENGTH))
    .test(NO_SPACES_RULE)
    .required(REQUIRED_ERROR_TEXT)
    .oneOf([yup.ref('password'), null], PASSWORDS_DONT_MATCHES),
};

export const validationSchema = yup.object(validationFields);

export const initialValues: ResetPasswordFormData = {
  password: '',
  passwordConfirmation: '',
};

export const attributesToSubmit = (
  values: ResetPasswordFormData,
  passwordResetToken: string,
): ResetPasswordFormDataToSubmit => {
  return {
    passwordReset: {
      ...values,
    },
    passwordResetToken,
  };
};
