import { SavedSearchFilterParams } from 'forms/savedSearchFilterForm';
import { apiRoutes } from 'routes';
import SearchFilter from 'types/resources/searchFilter';
import Fetcher from 'utils/fetcher';

export default {
  index(): Promise<{ searchFilters: SearchFilter[] }> {
    const url = apiRoutes.apiWebV1UserSearchFiltersPath();

    return Fetcher.get(url);
  },
  create(params: SavedSearchFilterParams): Promise<void> {
    const url = apiRoutes.apiWebV1UserSearchFiltersPath();

    return Fetcher.post(url, params);
  },
  delete(id: ID): Promise<void> {
    const url = apiRoutes.apiWebV1UserSearchFilterPath(id);

    return Fetcher.delete(url);
  },
};
