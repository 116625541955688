import SearchesRepository from 'repositories/Buyer/SearchesRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import Search from 'types/resources/search';
import { Meta } from 'types/meta';
import { createSlice } from '@reduxjs/toolkit';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';

export type BuyerSearchesSliceStateType = {
  searches: Search[];
  searchesMeta: Meta | null;
  show: { fetchStatus: FetchStatus };
  search: Search | null;
  showSearch: { fetchStatus: FetchStatus };
  showSearchPhaseChanged: { fetchStatus: FetchStatus };
  showSearchArchived: { fetchStatus: FetchStatus };
};

export type BuyerSearchesSliceActionsType = {
  loadSearches: (params: SearchFiltersFormDataParams) => void;
  resetSearches: () => void;
  loadSearch: (id: ID) => void;
  changeSearchPhase: (id: ID, state: string) => void;
  archiveSearch: (id: ID) => void;
};

const initialState: BuyerSearchesSliceStateType = {
  searches: [],
  searchesMeta: null,
  show: { fetchStatus: FetchStatus.idle },
  search: null,
  showSearch: { fetchStatus: FetchStatus.idle },
  showSearchPhaseChanged: { fetchStatus: FetchStatus.idle },
  showSearchArchived: { fetchStatus: FetchStatus.idle },
};

const BuyerSearchesSlice = createSlice({
  name: 'buyerSearches',
  initialState,
  reducers: {
    loadSearchesStart: (state): void => {
      state.show.fetchStatus = FetchStatus.pending;
    },
    loadSearchesFinish: (state, { payload }): void => {
      const { searches, meta } = payload;
      state.show.fetchStatus = FetchStatus.fulfilled;
      state.searches = searches;
      state.searchesMeta = meta;
    },
    loadSearchesFail: (state): void => {
      state.show.fetchStatus = FetchStatus.failed;
    },
    resetSearches: (): BuyerSearchesSliceStateType => {
      return initialState;
    },
    loadSearchStart: (state): void => {
      state.showSearch.fetchStatus = FetchStatus.pending;
    },
    loadSearchFinish: (state, { payload }): void => {
      const { search } = payload;

      state.showSearch.fetchStatus = FetchStatus.fulfilled;
      state.search = search;
    },
    loadSearchFail: (state): void => {
      state.showSearch.fetchStatus = FetchStatus.failed;
    },
    changeSearchPhaseStart: (state): void => {
      state.showSearchPhaseChanged.fetchStatus = FetchStatus.pending;
    },
    changeSearchPhaseFinish: (state): void => {
      state.showSearchPhaseChanged.fetchStatus = FetchStatus.fulfilled;
    },
    changeSearchPhaseFail: (state): void => {
      state.showSearchPhaseChanged.fetchStatus = FetchStatus.failed;
    },
    archiveSearchStart: (state): void => {
      state.showSearchArchived.fetchStatus = FetchStatus.pending;
    },
    archiveSearchFinish: (state): void => {
      state.showSearchArchived.fetchStatus = FetchStatus.fulfilled;
    },
    archiveSearchFail: (state): void => {
      state.showSearchArchived.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = BuyerSearchesSlice;

export const useBuyerSearchesActions = (): BuyerSearchesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadSearches = async (params: SearchFiltersFormDataParams = {}) => {
    dispatch(actions.loadSearchesStart());
    try {
      const searches = await SearchesRepository.index(params);
      dispatch(actions.loadSearchesFinish(searches));
    } catch (error: unknown) {
      dispatch(actions.loadSearchesFail());
    }
  };

  const resetSearches = () => dispatch(actions.resetSearches());

  const loadSearch = async (id: ID) => {
    dispatch(actions.loadSearchStart());
    try {
      const search = await SearchesRepository.show(id);
      dispatch(actions.loadSearchFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadSearchFail());
    }
  };

  const changeSearchPhase = async (id: ID, event: string) => {
    dispatch(actions.changeSearchPhaseStart());
    try {
      await SearchesRepository.changePhase(id, event);
      dispatch(actions.changeSearchPhaseFinish());
    } catch (error: unknown) {
      dispatch(actions.changeSearchPhaseFail());
    }
  };

  const archiveSearch = async (id: ID) => {
    dispatch(actions.archiveSearchStart());
    try {
      await SearchesRepository.archive(id);
      dispatch(actions.archiveSearchFinish());
    } catch (error: unknown) {
      dispatch(actions.archiveSearchFail());
    }
  };

  return {
    loadSearches,
    resetSearches,
    loadSearch,
    changeSearchPhase,
    archiveSearch,
  };
};

export default BuyerSearchesSlice.reducer;
