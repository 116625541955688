import { FC } from 'react';
import { Typography, Button } from '@material-ui/core';
import useAuthModals from 'hooks/useAuthModals';

import styles from './styles';

const ConfirmNewPassword: FC = (): JSX.Element => {
  const { openSignInModal } = useAuthModals();

  return (
    <>
      <Typography sx={styles.title} variant="h3" align="center">
        Your password has been
        <br /> reset successfully
      </Typography>
      <Typography sx={styles.description} variant="body1" align="center">
        You can now log in with your new password.
      </Typography>
      <Button onClick={openSignInModal} fullWidth variant="contained">
        Log In
      </Button>
    </>
  );
};

export default ConfirmNewPassword;
