import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import ConnectionSearch from 'types/resources/connection/search';

export default {
  show(connectionId: ID): Promise<ConnectionSearch> {
    const url = apiRoutes.apiWebV1UserAgentConnectionSearchPath(connectionId);

    return Fetcher.get(url);
  },
};
