import Dashboard from 'types/resources/dashboard';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

export default {
  show(): Promise<Dashboard> {
    const url = apiRoutes.apiWebV1AdminDashboardPath();
    return Fetcher.get(url);
  },
};
