import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import Box from 'components/Box';
import { appRoutes } from 'routes';
import Zigzago from 'components/Icons/Zigzago';

import styles from './styles';

const Logo: FC = (): ReactElement => {
  return (
    <Box sx={styles.logo}>
      <Link to={appRoutes.rootPath()}>
        <Zigzago />
      </Link>
    </Box>
  );
};

export default Logo;
