import Fetcher from 'utils/fetcher';
import { apiRoutes } from 'routes';
import { AccountNewUserType } from 'forms/accountForm';

const UsersRepository = {
  create(params: AccountNewUserType): Promise<void> {
    const url = apiRoutes.apiWebV1AccountUsersPath();

    return Fetcher.post(url, params);
  },
};

export default UsersRepository;
