import { ComponentsProps } from '@material-ui/core';

type MuiLinkType = {
  defaultProps: ComponentsProps['MuiLink'];
};

const MuiLink: MuiLinkType = {
  defaultProps: {
    underline: 'none',
  },
};

export default MuiLink;
