import { createSlice } from '@reduxjs/toolkit';
import AgentConnectionPropertiesRepository from 'repositories/Agent/Connection/PropertyRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import ConnectionProperty from 'types/resources/connection/property';

export type AgentConnectionPropertiesSliceStateType = {
  agentConnectionProperty: ConnectionProperty | null;
  showAgentConnectionProperty: { fetchStatus: FetchStatus };
};

export type AgentConnectionPropertiesSliceActionsType = {
  loadAgentConnectionProperty: (connectionId: ID) => void;
  resetAgentConnectionProperty: () => void;
};

const initialState: AgentConnectionPropertiesSliceStateType = {
  agentConnectionProperty: null,
  showAgentConnectionProperty: { fetchStatus: FetchStatus.idle },
};

const AgentConnectionPropertiesSlice = createSlice({
  name: 'agentConnectionProperties',
  initialState,
  reducers: {
    loadAgentConnectionPropertyStart: (state): void => {
      state.showAgentConnectionProperty.fetchStatus = FetchStatus.pending;
    },
    loadAgentConnectionPropertyFinish: (state, { payload }): void => {
      state.showAgentConnectionProperty.fetchStatus = FetchStatus.fulfilled;
      state.agentConnectionProperty = payload;
    },
    loadAgentConnectionPropertyFail: (state): void => {
      state.showAgentConnectionProperty.fetchStatus = FetchStatus.failed;
    },
    resetAgentConnectionProperty: (): AgentConnectionPropertiesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AgentConnectionPropertiesSlice;

export const useAgentConnectionPropertiesActions = (): AgentConnectionPropertiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAgentConnectionProperty = async (connectionId: ID) => {
    dispatch(actions.loadAgentConnectionPropertyStart());
    try {
      const connectionProperty = await AgentConnectionPropertiesRepository.show(connectionId);
      dispatch(actions.loadAgentConnectionPropertyFinish(connectionProperty));
    } catch (error: unknown) {
      dispatch(actions.loadAgentConnectionPropertyFail());
    }
  };

  const resetAgentConnectionProperty = () => dispatch(actions.resetAgentConnectionProperty());

  return {
    loadAgentConnectionProperty,
    resetAgentConnectionProperty,
  };
};

export default AgentConnectionPropertiesSlice.reducer;
