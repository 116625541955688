import { pathOr, find, is, isNil, keys, omit } from 'ramda';
import { PROPERTY_TYPES, FINANCING_TYPES, PROPERTY_CONDITIONS } from 'constants/common';
import { PropertyCustomField } from 'enums/PropertyCustomField';
import { SunlightOption } from 'enums/SunlightOption';
import { PropertyPhase } from 'enums/PropertyPhase';
import Property from 'types/resources/property';
import CustomField from 'types/resources/customField';

export const MIN_PROGRESS = 70;

export const PROPERTY_PHASES = {
  [PropertyPhase.draft]: {
    label: 'Draft',
    color: 'paleGrey',
  },
  [PropertyPhase.onReview]: {
    label: 'In Review',
    color: 'orange',
  },
  [PropertyPhase.published]: {
    label: 'Published',
    color: 'brightGreen',
  },
  [PropertyPhase.closed]: {
    label: 'Closed',
    color: 'paleGrey',
  },
  [PropertyPhase.rejected]: {
    label: 'Rejected',
    color: 'red',
  },
};

export const PROPERTY_PHASES_OPTIONS = keys(PROPERTY_PHASES).map((phase) => {
  return {
    id: phase,
    name: PROPERTY_PHASES[phase].label,
  };
});

export const PROPERTY_PHASES_OPTIONS_WITHOUT_DRAFT = keys(omit([PropertyPhase.draft], PROPERTY_PHASES)).map((phase) => {
  return {
    id: phase,
    name: PROPERTY_PHASES[phase].label,
  };
});

export const PROPERTY_CUSTOM_FIELDS: { id: PropertyCustomField; name: string }[] = [
  { id: PropertyCustomField.area, name: 'Area' },
  { id: PropertyCustomField.rooms, name: 'Rooms' },
  { id: PropertyCustomField.sunlight, name: 'Sunlight' },
  { id: PropertyCustomField.yard, name: 'Yard' },
];

export const SUNLIGHT_OPTIONS: { id: SunlightOption; name: string }[] = [
  { id: SunlightOption.none, name: 'None' },
  { id: SunlightOption.minimal, name: 'Minimal' },
  { id: SunlightOption.average, name: 'Average' },
  { id: SunlightOption.abundant, name: 'Abundant' },
];

export const EXCLUSIVE_REPRESENTATION = [
  { id: true, name: 'I have exclusive representation' },
  { id: false, name: 'I do not have exclusive representation' },
];

export const COMPLETE_SORTING = 'progress';

export const PRICE_SORTING = 'price';

export const CREATED_AT_SORTING = 'created_at';

export const SORTING_OPTIONS = [
  { id: 1, name: 'Complete', value: COMPLETE_SORTING },
  { id: 2, name: 'Price', value: PRICE_SORTING },
  { id: 3, name: 'Most recent', value: CREATED_AT_SORTING },
];

export const REJECTION_OPTIONS = [
  { id: 'reason1', name: 'Your Property documents do not correspond with your Property' },
  { id: 'reason2', name: 'No Property documents are uploaded' },
  { id: 'reason3', name: 'Photos quality does not meet the ZigZago requirements' },
  { id: 'reason4', name: 'More photos need to be added for the Area section' },
  { id: 'reason5', name: 'More photos need to be added for the Room section' },
  { id: 'reason6', name: 'More photos need to be added for the Sunlight section' },
  { id: 'reason7', name: 'More photos need to be added for the Yard section' },
  { id: 'reason8', name: 'The Property area is located outside the ZigZago jurisdiction' },
];

export const propertyTypeLabel = (property: Property): string => {
  const { propertyType } = property;

  const currentType = find((item) => item.id === propertyType, PROPERTY_TYPES);
  const label = pathOr('–', ['name'], currentType);

  return label;
};

export const financingTypeLabel = (property: Property): string => {
  const { financingType } = property;

  const currentType = find((item) => item.id === financingType, FINANCING_TYPES);
  const label = pathOr('–', ['name'], currentType);

  return label;
};

export const propertyConditionLabel = (property: Property): string => {
  const { propertyCondition } = property;

  const currentType = find((item) => item.id === propertyCondition, PROPERTY_CONDITIONS);
  const label = pathOr('–', ['name'], currentType);

  return label;
};

export const formattedCustomFields = (property: Property): CustomField[] => {
  const { customFields } = property;

  if (isNil(customFields)) {
    return [];
  }

  return customFields.map((field) => {
    if (is(String, field)) {
      return JSON.parse(field as string);
    }
    return field;
  });
};

export const isDraft = (property: Property): boolean => {
  const { phase } = property;

  return phase === PropertyPhase.draft;
};

export const isPublished = (property: Property): boolean => {
  const { phase } = property;

  return phase === PropertyPhase.published;
};

export const isOnReview = (property: Property): boolean => {
  const { phase } = property;

  return phase === PropertyPhase.onReview;
};

export const isRejected = (property: Property): boolean => {
  const { phase } = property;

  return phase === PropertyPhase.rejected;
};

export const isClosed = (property: Property): boolean => {
  const { phase } = property;

  return phase === PropertyPhase.closed;
};

export const isActivateShowing = (property: Property): boolean => {
  return isOnReview(property);
};

export const isCloseShowing = (property: Property): boolean => {
  return isRejected(property) || isPublished(property);
};

export const isArchiveShowing = (property: Property): boolean => {
  return isDraft(property) || isClosed(property);
};

export const location = (property: Property): string => {
  const { state, city } = property;

  if (city && state) {
    return `${pathOr('', ['name'], state)}, ${pathOr('', ['name'], city)}`;
  }

  return null;
};
