import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Property from 'types/resources/property';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import { Meta } from 'types/meta';

type PropertiesResponse = {
  properties: Property[];
  meta: Meta;
  isAllConnected?: boolean;
};

export default {
  index(searchId: ID, params: PropertyFiltersParams): Promise<PropertiesResponse> {
    const url = apiRoutes.apiWebV1UserSearchPropertiesPath(searchId);

    return Fetcher.get(url, params);
  },
};
