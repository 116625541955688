import StatesRepository from 'repositories/StatesRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import State from 'types/resources/state';
import { createSlice } from '@reduxjs/toolkit';

export type StatesSliceStateType = {
  state: {
    data: State | null;
    fetchStatus: FetchStatus;
  };
};

export type StatesSliceActionsType = {
  loadState: (id: ID) => void;
  resetState: () => void;
};

const initialState: StatesSliceStateType = {
  state: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const StatesSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
    loadStateStart: (state): void => {
      state.state.fetchStatus = FetchStatus.pending;
    },
    loadStateFinish: (state, { payload }): void => {
      const { state: data } = payload;

      state.state.fetchStatus = FetchStatus.fulfilled;
      state.state.data = data;
    },
    loadStateFail: (state): void => {
      state.state.fetchStatus = FetchStatus.failed;
    },
    resetState: (): StatesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = StatesSlice;

export const useStatesActions = (): StatesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadState = async (id: ID) => {
    dispatch(actions.loadStateStart());
    try {
      const state = await StatesRepository.show(id);
      dispatch(actions.loadStateFinish(state));
    } catch (error: unknown) {
      dispatch(actions.loadStateFail());
    }
  };

  const resetState = () => dispatch(actions.resetState());

  return {
    loadState,
    resetState,
  };
};

export default StatesSlice.reducer;
