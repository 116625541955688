import { FC } from 'react';
import { Typography, Link } from '@material-ui/core';
import { appRoutes } from 'routes';
import useLocalStorage from 'hooks/useLocalStorage';

import styles from './styles';

const ConfirmSuccess: FC = (): JSX.Element => {
  const [path] = useLocalStorage<string>('pathToRedirect', '');

  const linkTo = path || appRoutes.rootPath();

  return (
    <>
      <Typography sx={styles.title} variant="h3" align="center">
        Confirmed!
      </Typography>
      <Typography sx={styles.description} variant="body1" align="center">
        Now you can use{' '}
        <Link href={linkTo} sx={styles.link}>
          ZigZago
        </Link>
        .
      </Typography>
    </>
  );
};

export default ConfirmSuccess;
