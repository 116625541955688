import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    position: 'relative',
    color: 'simpleGrey',
    '& span': {
      display: 'inline-block',
      padding: '0 8px',
      position: 'relative',
      backgroundColor: 'white',
    },
    '&::before': {
      content: '""',
      width: '100%',
      height: '1px',
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
      backgroundColor: 'simpleGrey',
    },
  },
};

export default styles;
