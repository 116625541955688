import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { UserFormDataToSubmit } from 'forms/userForm';
import { InviteUserFormDataToSubmit } from 'forms/inviteUserForm';
import User from 'types/resources/user';
import { AccountImpersonateParams } from 'forms/accountForm';

export default {
  create(params: UserFormDataToSubmit): Promise<{ user: User }> {
    const url = apiRoutes.apiWebV1AdminAccountsPath();

    return Fetcher.post(url, params);
  },
  invite(params: InviteUserFormDataToSubmit): Promise<void> {
    const url = apiRoutes.inviteApiWebV1AdminAccountsPath();

    return Fetcher.post(url, params);
  },
  activate(id: ID): Promise<void> {
    const url = apiRoutes.activateApiWebV1AdminAccountPath(id);

    return Fetcher.put(url, {});
  },
  deactivate(id: ID): Promise<void> {
    const url = apiRoutes.deactivateApiWebV1AdminAccountPath(id);

    return Fetcher.put(url, {});
  },
  impersonate(id: ID, params: AccountImpersonateParams): Promise<void> {
    const url = apiRoutes.impersonateApiWebV1AdminAccountPath(id);

    return Fetcher.put(url, params);
  },
  stopImpersonating(): Promise<void> {
    const url = apiRoutes.stopImpersonatingApiWebV1AdminAccountsPath();

    return Fetcher.delete(url);
  },
};
