import DashboardRepository from 'repositories/Admin/DashboardRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { createSlice } from '@reduxjs/toolkit';
import Dashboard from 'types/resources/dashboard';

export type AdminDashboardSliceStateType = {
  dashboard: {
    data: Dashboard;
    fetchStatus: FetchStatus;
  };
};

export type AdminDashboardSliceActionsType = {
  loadAdminDashboard: () => void;
  resetAdminDashboard: () => void;
};

const initialState: AdminDashboardSliceStateType = {
  dashboard: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminDashboardSlice = createSlice({
  name: 'adminDashboard',
  initialState,
  reducers: {
    loadAdminDashboardStart: (state): void => {
      state.dashboard.fetchStatus = FetchStatus.pending;
    },
    loadAdminDashboardFinish: (state, { payload }): void => {
      const { adminDashboard } = payload;

      state.dashboard.fetchStatus = FetchStatus.fulfilled;
      state.dashboard.data = adminDashboard;
    },
    loadAdminDashboardFail: (state): void => {
      state.dashboard.fetchStatus = FetchStatus.failed;
    },

    resetAdminDashboard: (): AdminDashboardSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminDashboardSlice;

export const useAdminDashboardActions = (): AdminDashboardSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAdminDashboard = async () => {
    dispatch(actions.loadAdminDashboardStart());
    try {
      const dashboard = await DashboardRepository.show();
      dispatch(actions.loadAdminDashboardFinish(dashboard));
    } catch (error: unknown) {
      dispatch(actions.loadAdminDashboardFail());
    }
  };

  const resetAdminDashboard = () => dispatch(actions.resetAdminDashboard);

  return {
    loadAdminDashboard,
    resetAdminDashboard,
  };
};

export default AdminDashboardSlice.reducer;
