import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { AxiosResponse } from 'axios';
import Connection from 'types/resources/connection';
import { Meta } from 'types/meta';
import { ConnectionCreationParams } from 'store/sellerConnectionsSlice';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';

export default {
  index(params: ConnectionFiltersParams): Promise<{ connections: Connection[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1UserSellerConnectionsPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<Connection> {
    const url = apiRoutes.apiWebV1UserSellerConnectionPath(id);

    return Fetcher.get(url);
  },
  create(params: ConnectionCreationParams): Promise<AxiosResponse> {
    const url = apiRoutes.apiWebV1UserSellerConnectionsPath();

    return Fetcher.post(url, params);
  },
  archive(id: ID): Promise<void> {
    const url = apiRoutes.apiWebV1UserSellerConnectionPath(id);

    return Fetcher.delete(url);
  },
};
