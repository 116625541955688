import Fetcher from 'utils/fetcher';
import { apiRoutes } from 'routes';
import User from 'types/resources/user';
import { SellerProfileFormDataToSubmit } from 'forms/sellerProfileForm';

const ProfileRepository = {
  show(id: ID): Promise<{ user: User }> {
    const url = apiRoutes.apiWebV1UserAgentSellerPath(id);

    return Fetcher.get(url);
  },
  update(id: ID, params: SellerProfileFormDataToSubmit): Promise<User> {
    const url = apiRoutes.apiWebV1UserAgentSellerPath(id);
    return Fetcher.putFormData(url, params);
  },
};

export default ProfileRepository;
