import { UsersSliceActionsType, useUsersActions } from 'store/usersSlice';
import { useAppSelector } from 'hooks/useRedux';
import getFetchStatus from 'utils/fetchStatus';
import User from 'types/resources/user';
import { UserMeta } from 'types/meta';

type UseUsersType = UsersSliceActionsType & {
  currentUser: User;
  currentUserLoading: boolean;
  currentUserMeta: UserMeta;
};

const useUsers = (): UseUsersType => {
  const { currentUser, currentUserMeta, currentUserFetchStatus } = useAppSelector((state) => state.users);
  const { loadCurrentUser, destroyCurrentUser } = useUsersActions();

  const currentUserLoading = !getFetchStatus(currentUserFetchStatus).isFinished;

  return {
    currentUser,
    currentUserMeta,
    currentUserLoading,
    loadCurrentUser,
    destroyCurrentUser,
  };
};

export default useUsers;
