import { apiRoutes } from 'routes';
import AgentConnectionsDashboard from 'types/resources/agentConnectionsDashboard';
import Fetcher from 'utils/fetcher';

export default {
  show(id: ID): Promise<{ agentConnectionsDashboard: AgentConnectionsDashboard }> {
    const url = apiRoutes.apiWebV1AdminUserAgentConnectionsDashboardPath(id);

    return Fetcher.get(url);
  },
};
