import { FC } from 'react';
import { Typography } from '@material-ui/core';

import styles from './styles';

const ConfirmFail: FC = (): JSX.Element => {
  return (
    <>
      <Typography sx={styles.title} variant="h3" align="center">
        Confirmation failed!
      </Typography>
      <Typography sx={styles.description} variant="body1" align="center">
        Confirmation token is expired.
      </Typography>
    </>
  );
};

export default ConfirmFail;
