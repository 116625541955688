import ZipCodesRepository from 'repositories/ZipCodesRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import ZipCode from 'types/resources/zipCode';
import { Meta } from 'types/meta';
import { createSlice } from '@reduxjs/toolkit';

export type ZipCodesSliceZipCodeType = {
  zipCodes: {
    data: ZipCode[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
  };
};

export type ZipCodesSliceActionsType = {
  loadZipCodes: (params: QueryParams) => void;
  resetZipCodes: () => void;
};

const initialState: ZipCodesSliceZipCodeType = {
  zipCodes: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
};

const zipCodesSlice = createSlice({
  name: 'zipCodes',
  initialState,
  reducers: {
    loadZipCodesStart: (state): void => {
      state.zipCodes.fetchStatus = FetchStatus.pending;
    },
    loadZipCodesFinish: (state, { payload }): void => {
      const { zipCodes: data, meta } = payload;

      state.zipCodes.fetchStatus = FetchStatus.fulfilled;
      state.zipCodes.meta = meta;
      state.zipCodes.data = data;
    },
    loadZipCodesFail: (state): void => {
      state.zipCodes.fetchStatus = FetchStatus.failed;
    },
    resetZipCodes: (): ZipCodesSliceZipCodeType => {
      return initialState;
    },
  },
});

const { actions } = zipCodesSlice;

export const useZipCodesActions = (): ZipCodesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadZipCodes = async (params: QueryParams) => {
    dispatch(actions.loadZipCodesStart());

    try {
      const data = await ZipCodesRepository.index(params);
      dispatch(actions.loadZipCodesFinish(data));
    } catch (error: unknown) {
      dispatch(actions.loadZipCodesFail());
    }
  };

  const resetZipCodes = () => dispatch(actions.resetZipCodes());

  return {
    loadZipCodes,
    resetZipCodes,
  };
};

export default zipCodesSlice.reducer;
