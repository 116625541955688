import { PropertiesImportRecordType } from 'types/importRecord';
import { find, pathOr } from 'ramda';
import { PROPERTY_TYPES, PROPERTY_CONDITIONS } from 'constants/common';

export const propertyTypeLabel = (record: PropertiesImportRecordType): string => {
  const { propertyType } = record;

  const currentType = find((item) => item.id === propertyType, PROPERTY_TYPES);
  const label = pathOr('–', ['name'], currentType);

  return label;
};

export const propertyConditionLabel = (record: PropertiesImportRecordType): string => {
  const { propertyCondition } = record;

  const currentType = find((item) => item.id === propertyCondition, PROPERTY_CONDITIONS);
  const label = pathOr('–', ['name'], currentType);

  return label;
};
