import { ReactElement } from 'react';
import Box from 'components/Box';
import { CircularProgress } from '@material-ui/core';

import styles from './styles';

const Loader = (): ReactElement => {
  return (
    <Box sx={styles.progress}>
      <CircularProgress sx={styles.icon} />
    </Box>
  );
};

export default Loader;
