import Fetcher from 'utils/fetcher';
import { apiRoutes } from 'routes';
import { UserNotificationsType } from 'types/notifications';

const NotificationsRepository = {
  show(): Promise<UserNotificationsType> {
    const url = apiRoutes.apiWebV1NotificationsPath();

    return Fetcher.get(url);
  },
};

export default NotificationsRepository;
