/* tslint:disable */
/* This is generated by ts_routes-rails */

export type ScalarType = string | number | boolean;

function $buildOptions(options: any, names: string[]): string {
  if (options) {
    let anchor = "";
    const q = [] as string[];
    for (const key of Object.keys(options)) {
      if (names.indexOf(key) !== -1) {
        // the key is already consumed
        continue;
      }

      const value = options[key];

      if (key === "anchor") {
        anchor = `#${$encode(value)}`;
      } else {
        $buildQuery(q, key, value);
      }
    }
    return (q.length > 0 ? "?" + q.join("&") : "") + anchor;
  } else {
    return "";
  }
}

function $buildQuery(q: string[], key: string, value: any) {
  if ($isScalarType(value)) {
    q.push(`${$encode(key)}=${$encode(value)}`);
  } else if (Array.isArray(value)) {
    for (const v of value) {
      $buildQuery(q, `${key}[]`, v);
    }
  } else if (value != null) {
    // i.e. non-null, non-scalar, non-array type
    for (const k of Object.keys(value)) {
      $buildQuery(q, `${key}[${k}]`, value[k]);
    }
  }
}

function $encode(value: any): string {
  return encodeURIComponent(value);
}

function $isScalarType(value: any): value is ScalarType {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean"
  );
}

function $isPresent(value: any): boolean {
  return value != null && ("" + value).length > 0;
}

function $hasPresentOwnProperty(options: any, key: string): boolean {
  return options && options.hasOwnProperty(key) && $isPresent(options[key]);
}

/** /rails/info/properties(.:format) */
export function railsInfoPropertiesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info/routes(.:format) */
export function railsInfoRoutesPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + "/" + "routes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/info(.:format) */
export function railsInfoPath(options?: object): string {
  return "/" + "rails" + "/" + "info" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/mailers(.:format) */
export function railsMailersPath(options?: object): string {
  return "/" + "rails" + "/" + "mailers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /sidekiq */
export function sidekiqWebPath(options?: object): string {
  return "/" + "sidekiq" + $buildOptions(options, []);
}

/** /letter_opener */
export function letterOpenerWebPath(options?: object): string {
  return "/" + "letter_opener" + $buildOptions(options, []);
}

/** /letter_opener/clear(.:format) */
export function letterOpenerWebClearLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + "clear" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /letter_opener/:id(.:format) */
export function letterOpenerWebDeleteLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /letter_opener/ */
export function letterOpenerWebLettersPath(options?: object): string {
  return "/" + "letter_opener" + "/" + $buildOptions(options, []);
}

/** /letter_opener/:id(/:style)(.:format) */
export function letterOpenerWebLetterPath(id: ScalarType, options?: object): string {
  return "/" + "letter_opener" + "/" + id + ($hasPresentOwnProperty(options, "style") ? "/" + (options as any).style : "") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","style","format"]);
}

/** /letter_opener/clear(.:format) */
export function letterOpenerWebClearPath(options?: object): string {
  return "/" + "letter_opener" + "/" + "clear" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/current_user(.:format) */
export function apiWebV1CurrentUserPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "current_user" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/notifications(.:format) */
export function apiWebV1NotificationsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "notifications" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/heat_map(.:format) */
export function apiWebV1HeatMapPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "heat_map" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/agents/invite(.:format) */
export function inviteApiWebV1AgentsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "agents" + "/" + "invite" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/agents(.:format) */
export function apiWebV1AgentsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "agents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/accounts/accept_terms(.:format) */
export function acceptTermsApiWebV1AccountsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "accounts" + "/" + "accept_terms" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/accounts/confirm(.:format) */
export function confirmApiWebV1AccountsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "accounts" + "/" + "confirm" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/accounts/resend_confirmation(.:format) */
export function resendConfirmationApiWebV1AccountsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "accounts" + "/" + "resend_confirmation" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/accounts/switch_type(.:format) */
export function switchTypeApiWebV1AccountsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "accounts" + "/" + "switch_type" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/accounts(.:format) */
export function apiWebV1AccountsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "accounts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/searches(.:format) */
export function apiWebV1SearchesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/searches/:id(.:format) */
export function apiWebV1SearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/session(.:format) */
export function apiWebV1SessionPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "session" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/zip_codes(.:format) */
export function apiWebV1ZipCodesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "zip_codes" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/zip_codes/:id(.:format) */
export function apiWebV1ZipCodePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "zip_codes" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/cities(.:format) */
export function apiWebV1CitiesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "cities" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/cities/:id(.:format) */
export function apiWebV1CityPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "cities" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/states(.:format) */
export function apiWebV1StatesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "states" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/states/:id(.:format) */
export function apiWebV1StatePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "states" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/account/password_reset(.:format) */
export function apiWebV1AccountPasswordResetPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "account" + "/" + "password_reset" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/account/users(.:format) */
export function apiWebV1AccountUsersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "account" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/users(.:format) */
export function apiWebV1AdminUsersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "users" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/zip_codes/:id(.:format) */
export function apiWebV1AdminZipCodePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "zip_codes" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/accounts/invite(.:format) */
export function inviteApiWebV1AdminAccountsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "accounts" + "/" + "invite" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/accounts/:id/activate(.:format) */
export function activateApiWebV1AdminAccountPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "accounts" + "/" + id + "/" + "activate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/accounts/:id/deactivate(.:format) */
export function deactivateApiWebV1AdminAccountPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "accounts" + "/" + id + "/" + "deactivate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/accounts/:id/impersonate(.:format) */
export function impersonateApiWebV1AdminAccountPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "accounts" + "/" + id + "/" + "impersonate" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/accounts/stop_impersonating(.:format) */
export function stopImpersonatingApiWebV1AdminAccountsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "accounts" + "/" + "stop_impersonating" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/accounts(.:format) */
export function apiWebV1AdminAccountsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "accounts" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/states(.:format) */
export function apiWebV1AdminStatesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "states" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/dashboard(.:format) */
export function apiWebV1AdminDashboardPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/feedback_dashboard(.:format) */
export function apiWebV1AdminFeedbackDashboardPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "feedback_dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/connections_dashboard(.:format) */
export function apiWebV1AdminConnectionsDashboardPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "connections_dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/properties/:id/approve(.:format) */
export function approveApiWebV1AdminPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "properties" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/properties/:id/reject(.:format) */
export function rejectApiWebV1AdminPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "properties" + "/" + id + "/" + "reject" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/properties(.:format) */
export function apiWebV1AdminPropertiesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/properties/:id(.:format) */
export function apiWebV1AdminPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "properties" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/reviews(.:format) */
export function apiWebV1AdminReviewsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "reviews" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/connections/:id/assign_agent(.:format) */
export function assignAgentApiWebV1AdminConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "connections" + "/" + id + "/" + "assign_agent" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/connections/:id/unassign_agent(.:format) */
export function unassignAgentApiWebV1AdminConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "connections" + "/" + id + "/" + "unassign_agent" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/connections/:id/close(.:format) */
export function closeApiWebV1AdminConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "connections" + "/" + id + "/" + "close" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/connections(.:format) */
export function apiWebV1AdminConnectionsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/connections/:id(.:format) */
export function apiWebV1AdminConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "connections" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/searches/:id/change_phase(.:format) */
export function changePhaseApiWebV1AdminSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "searches" + "/" + id + "/" + "change_phase" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/searches(.:format) */
export function apiWebV1AdminSearchesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/searches/:id(.:format) */
export function apiWebV1AdminSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/user/buyers/:buyer_id/connections(.:format) */
export function apiWebV1AdminUserBuyerConnectionsPath(buyer_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "buyers" + "/" + buyer_id + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["buyer_id","format"]);
}

/** /api/web/v1/admin/user/buyers/:buyer_id/searches(.:format) */
export function apiWebV1AdminUserBuyerSearchesPath(buyer_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "buyers" + "/" + buyer_id + "/" + "searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["buyer_id","format"]);
}

/** /api/web/v1/admin/user/buyers/:buyer_id/searches/:id(.:format) */
export function apiWebV1AdminUserBuyerSearchPath(buyer_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "buyers" + "/" + buyer_id + "/" + "searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["buyer_id","id","format"]);
}

/** /api/web/v1/admin/user/buyers/:buyer_id/budget/approve(.:format) */
export function approveApiWebV1AdminUserBuyerBudgetPath(buyer_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "buyers" + "/" + buyer_id + "/" + "budget" + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["buyer_id","format"]);
}

/** /api/web/v1/admin/user/buyers/:buyer_id/budget/decline(.:format) */
export function declineApiWebV1AdminUserBuyerBudgetPath(buyer_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "buyers" + "/" + buyer_id + "/" + "budget" + "/" + "decline" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["buyer_id","format"]);
}

/** /api/web/v1/admin/user/buyers/:id(.:format) */
export function apiWebV1AdminUserBuyerPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "buyers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/connections_dashboard(.:format) */
export function apiWebV1AdminUserAgentConnectionsDashboardPath(agent_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "connections_dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/clients(.:format) */
export function apiWebV1AdminUserAgentClientsPath(agent_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "clients" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/clients/:id(.:format) */
export function apiWebV1AdminUserAgentClientPath(agent_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "clients" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/connections(.:format) */
export function apiWebV1AdminUserAgentConnectionsPath(agent_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/properties(.:format) */
export function apiWebV1AdminUserAgentPropertiesPath(agent_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/properties/:id(.:format) */
export function apiWebV1AdminUserAgentPropertyPath(agent_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "properties" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/searches(.:format) */
export function apiWebV1AdminUserAgentSearchesPath(agent_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/searches/:id(.:format) */
export function apiWebV1AdminUserAgentSearchPath(agent_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/licenses/:id/approve(.:format) */
export function approveApiWebV1AdminUserAgentLicensePath(agent_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "licenses" + "/" + id + "/" + "approve" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","id","format"]);
}

/** /api/web/v1/admin/user/agents/:agent_id/licenses/:id/decline(.:format) */
export function declineApiWebV1AdminUserAgentLicensePath(agent_id: ScalarType, id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + agent_id + "/" + "licenses" + "/" + id + "/" + "decline" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["agent_id","id","format"]);
}

/** /api/web/v1/admin/user/agents(.:format) */
export function apiWebV1AdminUserAgentsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/admin/user/agents/:id(.:format) */
export function apiWebV1AdminUserAgentPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "agents" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/admin/user/sellers/:seller_id/connections(.:format) */
export function apiWebV1AdminUserSellerConnectionsPath(seller_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "sellers" + "/" + seller_id + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["seller_id","format"]);
}

/** /api/web/v1/admin/user/sellers/:seller_id/properties(.:format) */
export function apiWebV1AdminUserSellerPropertiesPath(seller_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "sellers" + "/" + seller_id + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["seller_id","format"]);
}

/** /api/web/v1/admin/user/sellers/:id(.:format) */
export function apiWebV1AdminUserSellerPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "admin" + "/" + "user" + "/" + "sellers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/searches/:search_id/properties(.:format) */
export function apiWebV1UserSearchPropertiesPath(search_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "searches" + "/" + search_id + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["search_id","format"]);
}

/** /api/web/v1/user/search_filters(.:format) */
export function apiWebV1UserSearchFiltersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "search_filters" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/search_filters/:id(.:format) */
export function apiWebV1UserSearchFilterPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "search_filters" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/reviews(.:format) */
export function apiWebV1UserReviewsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "reviews" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/profile(.:format) */
export function apiWebV1UserAgentProfilePath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/dashboard(.:format) */
export function apiWebV1UserAgentDashboardPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/connections_dashboard(.:format) */
export function apiWebV1UserAgentConnectionsDashboardPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "connections_dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/matches/:id(.:format) */
export function apiWebV1UserAgentMatchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "matches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/connections/:id/change_phase(.:format) */
export function changePhaseApiWebV1UserAgentConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "connections" + "/" + id + "/" + "change_phase" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/connections/:id/close(.:format) */
export function closeApiWebV1UserAgentConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "connections" + "/" + id + "/" + "close" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/connections/:connection_id/property(.:format) */
export function apiWebV1UserAgentConnectionPropertyPath(connection_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "connections" + "/" + connection_id + "/" + "property" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["connection_id","format"]);
}

/** /api/web/v1/user/agent/connections/:connection_id/search(.:format) */
export function apiWebV1UserAgentConnectionSearchPath(connection_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "connections" + "/" + connection_id + "/" + "search" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["connection_id","format"]);
}

/** /api/web/v1/user/agent/connections(.:format) */
export function apiWebV1UserAgentConnectionsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/connections/:id(.:format) */
export function apiWebV1UserAgentConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "connections" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/licenses/:id/remove_file(.:format) */
export function removeFileApiWebV1UserAgentLicensePath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "licenses" + "/" + id + "/" + "remove_file" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/properties/:id/change_phase(.:format) */
export function changePhaseApiWebV1UserAgentPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "properties" + "/" + id + "/" + "change_phase" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/properties/:id/remove_image(.:format) */
export function removeImageApiWebV1UserAgentPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "properties" + "/" + id + "/" + "remove_image" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/properties/:id/remove_file(.:format) */
export function removeFileApiWebV1UserAgentPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "properties" + "/" + id + "/" + "remove_file" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/properties/:id/show_matches_status(.:format) */
export function showMatchesStatusApiWebV1UserAgentPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "properties" + "/" + id + "/" + "show_matches_status" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/properties/:property_id/matches(.:format) */
export function apiWebV1UserAgentPropertyMatchesPath(property_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "properties" + "/" + property_id + "/" + "matches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["property_id","format"]);
}

/** /api/web/v1/user/agent/properties(.:format) */
export function apiWebV1UserAgentPropertiesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/properties/:id(.:format) */
export function apiWebV1UserAgentPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "properties" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/ratings(.:format) */
export function apiWebV1UserAgentRatingsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "ratings" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/clients/import(.:format) */
export function importApiWebV1UserAgentClientsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "clients" + "/" + "import" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/clients(.:format) */
export function apiWebV1UserAgentClientsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "clients" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/clients/:id(.:format) */
export function apiWebV1UserAgentClientPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "clients" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/favorite_searches(.:format) */
export function apiWebV1UserAgentFavoriteSearchesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "favorite_searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/favorite_searches/:id(.:format) */
export function apiWebV1UserAgentFavoriteSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "favorite_searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/searches/:id/change_phase(.:format) */
export function changePhaseApiWebV1UserAgentSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "searches" + "/" + id + "/" + "change_phase" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/searches/:id/show_matches_status(.:format) */
export function showMatchesStatusApiWebV1UserAgentSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "searches" + "/" + id + "/" + "show_matches_status" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/searches/:search_id/matches(.:format) */
export function apiWebV1UserAgentSearchMatchesPath(search_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "searches" + "/" + search_id + "/" + "matches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["search_id","format"]);
}

/** /api/web/v1/user/agent/searches(.:format) */
export function apiWebV1UserAgentSearchesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/searches/:id(.:format) */
export function apiWebV1UserAgentSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/sellers/:seller_id/properties/import(.:format) */
export function importApiWebV1UserAgentSellerPropertiesPath(seller_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "sellers" + "/" + seller_id + "/" + "properties" + "/" + "import" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["seller_id","format"]);
}

/** /api/web/v1/user/agent/sellers/:seller_id/properties(.:format) */
export function apiWebV1UserAgentSellerPropertiesPath(seller_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "sellers" + "/" + seller_id + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["seller_id","format"]);
}

/** /api/web/v1/user/agent/sellers(.:format) */
export function apiWebV1UserAgentSellersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "sellers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/sellers/:id(.:format) */
export function apiWebV1UserAgentSellerPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "sellers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/agent/buyers/:buyer_id/searches(.:format) */
export function apiWebV1UserAgentBuyerSearchesPath(buyer_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "buyers" + "/" + buyer_id + "/" + "searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["buyer_id","format"]);
}

/** /api/web/v1/user/agent/buyers/:buyer_id/budget/remove_file(.:format) */
export function removeFileApiWebV1UserAgentBuyerBudgetPath(buyer_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "buyers" + "/" + buyer_id + "/" + "budget" + "/" + "remove_file" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["buyer_id","format"]);
}

/** /api/web/v1/user/agent/buyers(.:format) */
export function apiWebV1UserAgentBuyersPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "buyers" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/agent/buyers/:id(.:format) */
export function apiWebV1UserAgentBuyerPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "agent" + "/" + "buyers" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/properties/:id/change_phase(.:format) */
export function changePhaseApiWebV1UserSellerPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "properties" + "/" + id + "/" + "change_phase" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/properties/:id/remove_image(.:format) */
export function removeImageApiWebV1UserSellerPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "properties" + "/" + id + "/" + "remove_image" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/properties/:id/remove_file(.:format) */
export function removeFileApiWebV1UserSellerPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "properties" + "/" + id + "/" + "remove_file" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/properties/:id/show_matches_status(.:format) */
export function showMatchesStatusApiWebV1UserSellerPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "properties" + "/" + id + "/" + "show_matches_status" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/properties/:property_id/matches(.:format) */
export function apiWebV1UserSellerPropertyMatchesPath(property_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "properties" + "/" + property_id + "/" + "matches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["property_id","format"]);
}

/** /api/web/v1/user/seller/properties(.:format) */
export function apiWebV1UserSellerPropertiesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "properties" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/seller/properties/:id(.:format) */
export function apiWebV1UserSellerPropertyPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "properties" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/matches/:id(.:format) */
export function apiWebV1UserSellerMatchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "matches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/connections(.:format) */
export function apiWebV1UserSellerConnectionsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/seller/connections/:id(.:format) */
export function apiWebV1UserSellerConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "connections" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/dashboard(.:format) */
export function apiWebV1UserSellerDashboardPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "dashboard" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/seller/profile(.:format) */
export function apiWebV1UserSellerProfilePath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/seller/searches/:id/show_matches_status(.:format) */
export function showMatchesStatusApiWebV1UserSellerSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "searches" + "/" + id + "/" + "show_matches_status" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/seller/searches/:search_id/matches(.:format) */
export function apiWebV1UserSellerSearchMatchesPath(search_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "searches" + "/" + search_id + "/" + "matches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["search_id","format"]);
}

/** /api/web/v1/user/seller/searches(.:format) */
export function apiWebV1UserSellerSearchesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/seller/favorite_searches(.:format) */
export function apiWebV1UserSellerFavoriteSearchesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "favorite_searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/seller/favorite_searches/:id(.:format) */
export function apiWebV1UserSellerFavoriteSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "seller" + "/" + "favorite_searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/buyer/profile(.:format) */
export function apiWebV1UserBuyerProfilePath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "profile" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/buyer/budgets/:id/remove_file(.:format) */
export function removeFileApiWebV1UserBuyerBudgetPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "budgets" + "/" + id + "/" + "remove_file" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/buyer/budgets(.:format) */
export function apiWebV1UserBuyerBudgetsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "budgets" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/buyer/connections/:id/accept_contact(.:format) */
export function acceptContactApiWebV1UserBuyerConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "connections" + "/" + id + "/" + "accept_contact" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/buyer/connections/:id/reject_contact(.:format) */
export function rejectContactApiWebV1UserBuyerConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "connections" + "/" + id + "/" + "reject_contact" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/buyer/connections/:connection_id/property(.:format) */
export function apiWebV1UserBuyerConnectionPropertyPath(connection_id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "connections" + "/" + connection_id + "/" + "property" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["connection_id","format"]);
}

/** /api/web/v1/user/buyer/connections(.:format) */
export function apiWebV1UserBuyerConnectionsPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "connections" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/buyer/connections/:id(.:format) */
export function apiWebV1UserBuyerConnectionPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "connections" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/buyer/searches/:id/change_phase(.:format) */
export function changePhaseApiWebV1UserBuyerSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "searches" + "/" + id + "/" + "change_phase" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /api/web/v1/user/buyer/searches(.:format) */
export function apiWebV1UserBuyerSearchesPath(options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "searches" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /api/web/v1/user/buyer/searches/:id(.:format) */
export function apiWebV1UserBuyerSearchPath(id: ScalarType, options?: object): string {
  return "/" + "api" + "/" + "web" + "/" + "v1" + "/" + "user" + "/" + "buyer" + "/" + "searches" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** / */
export function rootPath(options?: object): string {
  return "/" + $buildOptions(options, []);
}

/** /properties-template(.:format) */
export function propertiesTemplatePath(options?: object): string {
  return "/" + "properties-template" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /clients-template(.:format) */
export function clientsTemplatePath(options?: object): string {
  return "/" + "clients-template" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /terms-of-use-file(.:format) */
export function termsOfUseFilePath(options?: object): string {
  return "/" + "terms-of-use-file" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /privacy-policy-file(.:format) */
export function privacyPolicyFilePath(options?: object): string {
  return "/" + "privacy-policy-file" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/postmark/inbound_emails(.:format) */
export function railsPostmarkInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "postmark" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/relay/inbound_emails(.:format) */
export function railsRelayInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "relay" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/sendgrid/inbound_emails(.:format) */
export function railsSendgridInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "sendgrid" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundHealthCheckPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mandrill/inbound_emails(.:format) */
export function railsMandrillInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mandrill" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/action_mailbox/mailgun/inbound_emails/mime(.:format) */
export function railsMailgunInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "action_mailbox" + "/" + "mailgun" + "/" + "inbound_emails" + "/" + "mime" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails(.:format) */
export function railsConductorInboundEmailsPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/new(.:format) */
export function newRailsConductorInboundEmailPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format) */
export function editRailsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + "/" + "edit" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/:id(.:format) */
export function railsConductorInboundEmailPath(id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + id + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["id","format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format) */
export function newRailsConductorInboundEmailSourcePath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + "/" + "new" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/inbound_emails/sources(.:format) */
export function railsConductorInboundEmailSourcesPath(options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + "inbound_emails" + "/" + "sources" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}

/** /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format) */
export function railsConductorInboundEmailReroutePath(inbound_email_id: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "conductor" + "/" + "action_mailbox" + "/" + inbound_email_id + "/" + "reroute" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["inbound_email_id","format"]);
}

/** /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format) */
export function railsServiceBlobPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "redirect" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format) */
export function railsServiceBlobProxyPath(signed_id: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "blobs" + "/" + "proxy" + "/" + signed_id + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_id","filename","format"]);
}

/** /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "redirect" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format) */
export function railsBlobRepresentationProxyPath(signed_blob_id: ScalarType, variation_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "representations" + "/" + "proxy" + "/" + signed_blob_id + "/" + variation_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["signed_blob_id","variation_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_key/*filename(.:format) */
export function railsDiskServicePath(encoded_key: ScalarType, filename: ScalarType[], options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_key + "/" + filename.map((part) => $encode(part)).join("/") + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_key","filename","format"]);
}

/** /rails/active_storage/disk/:encoded_token(.:format) */
export function updateRailsDiskServicePath(encoded_token: ScalarType, options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "disk" + "/" + encoded_token + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["encoded_token","format"]);
}

/** /rails/active_storage/direct_uploads(.:format) */
export function railsDirectUploadsPath(options?: object): string {
  return "/" + "rails" + "/" + "active_storage" + "/" + "direct_uploads" + ($hasPresentOwnProperty(options, "format") ? "." + (options as any).format : "") + $buildOptions(options, ["format"]);
}
