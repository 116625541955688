import { TypographyVariantsOptions } from '@material-ui/core/styles';

const fontFamily = 'Plus Jakarta Sans, Raleway, Arial';
const letterSpacing = '0.05em';
const fontFamilyRaleway = 'Raleway';

const typography: TypographyVariantsOptions = {
  fontFamily,
  h1: {
    fontFamily,
    fontWeight: 700,
    fontSize: 46,
    lineHeight: '64px',
    letterSpacing,
  },
  h2: {
    fontFamily,
    fontWeight: 700,
    fontSize: 32,
    lineHeight: '40px',
    letterSpacing,
  },
  h3: {
    fontFamily,
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '36px',
  },
  h4: {
    fontFamily,
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '24px',
  },
  h5: {
    fontFamily,
    fontWeight: 400,
    fontSize: 28,
    lineHeight: '16px',
  },
  h6: {
    fontFamily,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '16px',
  },
  subtitle1: {
    fontFamily,
    fontWeight: 400,
    fontSize: 20,
    lineHeight: '24px',
  },
  subtitle2: {
    fontFamily: fontFamilyRaleway,
    fontWeight: 500,
    fontSize: 16,
    lineHeight: '24px',
  },
  body1: {
    fontFamily,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
  },
  body2: {
    fontFamily: fontFamilyRaleway,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '16px',
  },
};

export default typography;
