import numeral from 'numeral';
import { isNil } from 'ramda';

export const PLACEHOLDER_WITH_CURRENCY = '$ 0,00';

export const withCurrency = (value: number | string): string => {
  return numeral(value).format('$ 0,0[.]00');
};

export const formattedWithCurrency = (value: number | string): string => {
  return numeral(value).format('($0a)');
};

export const withPercent = (value: number | string): string => {
  return `${value}%`;
};

export const withComma = (value: number | string): string => {
  return numeral(value).format('0,0[.]00');
};

export const isNumber = <T>(value: T): boolean => {
  if (isNil(value)) return false;

  return !Number.isNaN(Number(value));
};

export const pricesRangeLabel = (priceMin: number, priceMax: number): string => {
  const formattedPriceMin = withCurrency(priceMin);

  return isNil(priceMax) ? `more than ${formattedPriceMin}` : `${formattedPriceMin} - ${withCurrency(priceMax)}`;
};
