import { ReactElement } from 'react';
import { Container, Typography, Grid, Button, Link as ExternalLink } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Box from 'components/Box';
import Facebook from 'components/Icons/Facebook';
import Twitter from 'components/Icons/Twitter';
import GoWebsite from 'components/Icons/GoWebsite';
import Instagram from 'components/Icons/Instagram';
import { appRoutes, externalRoutes } from 'routes';
import { DateTimePresenter } from 'presenters';
import Go from 'components/Icons/Go';

import styles from './styles';

const Footer = (): ReactElement => {
  return (
    <Box sx={styles.footer}>
      <Container>
        <Grid container justifyContent="space-around">
          <Grid item xs={5} textAlign="center">
            <Box sx={styles.title}>
              <Typography sx={styles.link} variant="subtitle2">
                About ZigZago
              </Typography>
              <Button sx={styles.linkButton} component={Link} to={appRoutes.privacyPolicyPath()} variant="link">
                <Typography sx={styles.link} variant="subtitle2">
                  Privacy Policy
                </Typography>
              </Button>
              <Button sx={styles.linkButton} component={Link} to={appRoutes.termsAndConditionsPath()} variant="link">
                <Typography sx={styles.link} variant="subtitle2">
                  Terms &amp; Conditions
                </Typography>
              </Button>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box sx={styles.socialInfo}>
              <Box sx={styles.iconGoWrapper}>
                <Go sx={styles.iconGo} />
              </Box>
              <Grid container justifyContent="center" spacing={1.5}>
                <Grid item>
                  <ExternalLink href={externalRoutes.facebookPath()} target="_blank">
                    <Facebook sx={styles.icon} />
                  </ExternalLink>
                </Grid>

                <Grid item>
                  <ExternalLink href={externalRoutes.twitterPath()} target="_blank">
                    <Twitter sx={styles.icon} />
                  </ExternalLink>
                </Grid>

                <Grid item>
                  <ExternalLink href={externalRoutes.instagramPath()} target="_blank">
                    <Instagram sx={styles.icon} />
                  </ExternalLink>
                </Grid>

                <Grid item>
                  <ExternalLink href={externalRoutes.helpEmailPath()} target="_blank">
                    <GoWebsite sx={styles.icon} />
                  </ExternalLink>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={5} textAlign="center">
            <Box sx={styles.title}>
              <Typography sx={styles.link} variant="subtitle2">
                Email
              </Typography>
              <Button sx={styles.linkButton} component={Link} to={appRoutes.faqPath()} variant="link">
                <Typography sx={styles.link} variant="subtitle2">
                  FAQ
                </Typography>
              </Button>
              <Typography sx={styles.link} variant="subtitle2">
                Contact us
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <Typography sx={styles.info} variant="body2" textAlign="center">
              &copy; 2021 - {DateTimePresenter.currentYear()} HHFLY, INC
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.info} variant="body2" textAlign="center">
              |
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.info} variant="body2" textAlign="center">
              All rights reserved
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.info} variant="body2" textAlign="center">
              |
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.info} variant="body2" textAlign="center">
              Proudly made in NC, USA
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
