import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@material-ui/core';

declare module '@material-ui/core/Button/Button' {
  interface ButtonPropsVariantOverrides {
    social: true;
    link: true;
  }
}

type MuiButtonType = {
  defaultProps: ComponentsProps['MuiButton'];
  styleOverrides: ComponentsOverrides['MuiButton'];
  variants: ComponentsVariants['MuiButton'];
};

const MuiButton: MuiButtonType = {
  styleOverrides: {
    root: {
      textTransform: 'none',
      height: 48,
      fontSize: 16,
      fontWeight: 600,
      padding: '0 25px',
      whiteSpace: 'nowrap',
      letterSpacing: '-0.01em',
      wordSpacing: 2,
    },
    sizeSmall: {
      height: 40,
    },
    sizeLarge: {
      height: 56,
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '24px',
      letterSpacing: '-0.05em',
    },
    outlined: {
      fontFamily: 'Raleway',
      border: '2px solid',
      fontSize: 16,
      fontWeight: 700,
      textTransform: 'uppercase',
      '&:hover, &:focus': {
        borderWidth: '2px',
        backgroundColor: '#D1E5E9',
      },
    },
  },
  variants: [
    {
      props: { variant: 'contained' },
      style: {
        textTransform: 'uppercase',
        color: 'white',
      },
    },
    {
      props: { variant: 'outlined' },
      style: {
        backgroundColor: '#FFF',
        textTransform: 'uppercase',
      },
    },
    {
      props: { variant: 'social' },
      style: {
        border: '1px solid #D7D6D5',
        color: '#111111',
      },
    },
    {
      props: { variant: 'link' },
      style: {
        padding: 0,
        color: '#4C9F39',
        '&:hover, &:focus': {
          backgroundColor: 'transparent',
        },
        fontSize: 16,
        fontWeight: 400,
        height: 'auto',
        minWidth: 0,
      },
    },
  ],
  defaultProps: {},
};

export default MuiButton;
