import City from 'types/resources/city';
import State from 'types/resources/state';
import { isEmpty } from 'ramda';
import ZipCode from 'types/resources/zipCode';

export const formattedAddress = (state?: State, city?: City, street?: string, zipCode?: ZipCode): string => {
  const streetName = street || '';
  const zipCodeString = zipCode?.code || '';
  const cityName = city?.name || '';
  const stateName = state?.name || '';

  const address: string[] = [streetName, cityName, stateName, zipCodeString];

  return address.filter((item: string) => !isEmpty(item)).join(', ');
};

export const formattedAddressWithoutStreet = (state?: State, city?: City, zipCode?: ZipCode): string => {
  const zipCodeString = zipCode?.code || '';
  const cityName = city?.name || '';
  const stateName = state?.name || '';

  const address: string[] = [cityName, stateName, zipCodeString];

  return address.filter((item: string) => !isEmpty(item)).join(', ');
};
