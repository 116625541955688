import SearchesRepository from 'repositories/SearchesRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import Search from 'types/resources/search';
import { Meta } from 'types/meta';
import { createSlice } from '@reduxjs/toolkit';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';

export type SearchesSliceStateType = {
  searches: { data: Search[]; meta: Meta | null; preQualifiedBuyersCount: number; fetchStatus: FetchStatus };
  search: { data: Search; fetchStatus: FetchStatus };
};

export type SearchesSliceActionsType = {
  loadSearches: (params: SearchFiltersFormDataParams) => void;
  resetSearches: () => void;
  loadSearch: (id: ID) => void;
};

const initialState: SearchesSliceStateType = {
  searches: {
    data: [],
    meta: null,
    preQualifiedBuyersCount: 0,
    fetchStatus: FetchStatus.idle,
  },
  search: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
};

const SearchesSlice = createSlice({
  name: 'searches',
  initialState,
  reducers: {
    loadSearchesStart: (state): void => {
      state.searches.fetchStatus = FetchStatus.pending;
    },
    loadSearchesFinish: (state, { payload }): void => {
      const { searches, meta, preQualifiedBuyersCount } = payload;
      state.searches.fetchStatus = FetchStatus.fulfilled;
      state.searches.data = searches;
      state.searches.meta = meta;
      state.searches.preQualifiedBuyersCount = preQualifiedBuyersCount;
    },
    loadSearchesFail: (state): void => {
      state.searches.fetchStatus = FetchStatus.failed;
    },
    resetSearches: (): SearchesSliceStateType => {
      return initialState;
    },
    loadSearchStart: (state): void => {
      state.search.fetchStatus = FetchStatus.pending;
    },
    loadSearchFinish: (state, { payload }): void => {
      const { search } = payload;

      state.search.fetchStatus = FetchStatus.fulfilled;
      state.search.data = search;
    },
    loadSearchFail: (state): void => {
      state.search.fetchStatus = FetchStatus.failed;
    },
  },
});

const { actions } = SearchesSlice;

export const useSearchesActions = (): SearchesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadSearches = async (params: SearchFiltersFormDataParams = { q: {} }) => {
    dispatch(actions.loadSearchesStart());
    try {
      const searches = await SearchesRepository.index(params);
      dispatch(actions.loadSearchesFinish(searches));
    } catch (error: unknown) {
      dispatch(actions.loadSearchesFail());
    }
  };

  const resetSearches = () => dispatch(actions.resetSearches());

  const loadSearch = async (id: ID) => {
    dispatch(actions.loadSearchStart());
    try {
      const search = await SearchesRepository.show(id);
      dispatch(actions.loadSearchFinish(search));
    } catch (error: unknown) {
      dispatch(actions.loadSearchFail());
    }
  };

  return {
    loadSearches,
    resetSearches,
    loadSearch,
  };
};

export default SearchesSlice.reducer;
