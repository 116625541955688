import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { Meta } from 'types/meta';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';
import Connection from 'types/resources/connection';
import ConnectionsRepository from 'repositories/Admin/User/Buyer/ConnectionsRepository';

export type AdminBuyerConnectionsSliceStateType = {
  connections: {
    data: Connection[];
    meta: Meta;
    fetchStatus: FetchStatus;
  };
};

export type AdminBuyerConnectionsSliceActionsType = {
  loadConnections: (id: ID, params: ConnectionFiltersParams) => void;
  resetConnections: () => void;
};

const initialState: AdminBuyerConnectionsSliceStateType = {
  connections: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
};

const AdminBuyerConnectionsSlice = createSlice({
  name: 'adminBuyerConnections',
  initialState,
  reducers: {
    loadConnectionsStart: (state): void => {
      state.connections.fetchStatus = FetchStatus.pending;
    },
    loadConnectionsFinish: (state, { payload }): void => {
      const { connections, meta } = payload;
      state.connections.fetchStatus = FetchStatus.fulfilled;
      state.connections.data = connections;
      state.connections.meta = meta;
    },
    loadConnectionsFail: (state): void => {
      state.connections.fetchStatus = FetchStatus.failed;
    },

    resetConnections: (): AdminBuyerConnectionsSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminBuyerConnectionsSlice;

export const useAdminBuyerConnectionsActions = (): AdminBuyerConnectionsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadConnections = async (id: ID, params: SearchFiltersFormDataParams) => {
    dispatch(actions.loadConnectionsStart());
    try {
      const users = await ConnectionsRepository.index(id, params);
      dispatch(actions.loadConnectionsFinish(users));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionsFail());
    }
  };

  const resetConnections = () => dispatch(actions.resetConnections());

  return {
    loadConnections,
    resetConnections,
  };
};

export default AdminBuyerConnectionsSlice.reducer;
