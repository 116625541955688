import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    width: '100%',
  },
  title: {
    marginBottom: 4.75,
  },
  alert: {
    marginBottom: 2,
  },
  input: {
    marginBottom: 1.75,
  },
  passwordLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    marginY: 2.5,
  },
  signUp: {
    marginBottom: 3,
  },
  socialButton: {
    marginBottom: 3,
  },
  orDivider: {
    marginBottom: 3,
  },
  link: {
    marginLeft: 1,
  },
};

export default styles;
