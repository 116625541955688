import { createSlice } from '@reduxjs/toolkit';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import Property from 'types/resources/property';
import { Meta } from 'types/meta';
import PropertiesRepository from 'repositories/Search/PropertiesRepository';

export type AvailablePropertiesSliceStateType = {
  availableProperties: {
    data: Property[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
    isAllConnected: boolean;
  };
};

export type AvailablePropertiesSliceActionsType = {
  loadAvailableProperties: (searchId: ID, params: PropertyFiltersParams) => void;
  resetAvailableProperties: () => void;
};

const initialState: AvailablePropertiesSliceStateType = {
  availableProperties: {
    fetchStatus: FetchStatus.idle,
    data: [],
    meta: null,
    isAllConnected: false,
  },
};

const AvailablePropertiesSlice = createSlice({
  name: 'availableProperties',
  initialState,
  reducers: {
    loadPropertiesStart: (state): void => {
      state.availableProperties.fetchStatus = FetchStatus.pending;
    },
    loadPropertiesFinish: (state, { payload }): void => {
      const { properties, meta, isAllConnected } = payload;

      state.availableProperties.fetchStatus = FetchStatus.fulfilled;
      state.availableProperties.data = properties;
      state.availableProperties.meta = meta;
      state.availableProperties.isAllConnected = isAllConnected;
    },
    loadPropertiesFail: (state): void => {
      state.availableProperties.fetchStatus = FetchStatus.failed;
    },
    resetProperties: (): AvailablePropertiesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AvailablePropertiesSlice;

export const useAvailablePropertiesActions = (): AvailablePropertiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadAvailableProperties = async (searchId: ID, params: PropertyFiltersParams = {}) => {
    dispatch(actions.loadPropertiesStart());
    try {
      const properties = await PropertiesRepository.index(searchId, params);
      dispatch(actions.loadPropertiesFinish(properties));
    } catch (error: unknown) {
      dispatch(actions.loadPropertiesFail());
    }
  };

  const resetAvailableProperties = () => dispatch(actions.resetProperties());

  return {
    loadAvailableProperties,
    resetAvailableProperties,
  };
};

export default AvailablePropertiesSlice.reducer;
