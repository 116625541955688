import { useParams, useLocation, useHistory, useRouteMatch, matchPath } from 'react-router-dom';
import { useMemo } from 'react';
import qs from 'qs';
import { pathOr } from 'ramda';

type UseRouterType = {
  push: (path: string, state?: unknown) => void;
  replace: (path: string, state?: unknown) => void;
  pathname: string;
  match: typeof useRouteMatch;
  matchPath: typeof matchPath;
  location: ReturnType<typeof useLocation>;
  history: ReturnType<typeof useHistory>;
  getUrlParam: (param: string) => string;
};

const useRouter = (): UseRouterType => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch;

  const getUrlParam = (param: string) => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    return pathOr(null, [param], search);
  };

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      match,
      matchPath,
      location,
      history,
      getUrlParam,
    };
  }, [params, match, location, history]);
};

export default useRouter;
