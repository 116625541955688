import { SxStyles } from 'types/theme';

const styles: SxStyles = {
  root: {
    width: '100%',
  },
  title: {
    marginBottom: 3,
  },
  alert: {
    marginBottom: 2,
  },
  description: {
    marginBottom: 2.5,
    color: 'simpleGrey',
  },
  input: {
    marginBottom: 2,
  },
  button: {
    marginTop: 2.5,
  },
};

export default styles;
