import { lazy } from 'react';

const BuyerConnections = lazy(() => import('./BuyerConnections'));
const ConnectionDetails = lazy(() => import('./ConnectionDetails'));
const BuyerConnectionPropertyDetails = lazy(() => import('./BuyerConnectionPropertyDetails'));
const Page404 = lazy(() => import('./Page404'));
const BuyerSearches = lazy(() => import('./BuyerSearches'));
const SignUp = lazy(() => import('./SignUp'));
const BuyerProfile = lazy(() => import('./BuyerProfile'));
const BuyerEditProfile = lazy(() => import('./BuyerEditProfile'));
const SellerProfile = lazy(() => import('./SellerProfile'));
const SellerEditProfile = lazy(() => import('./SellerEditProfile'));
const NewSearch = lazy(() => import('./NewSearch'));
const EditSearch = lazy(() => import('./EditSearch'));
const SearchDetails = lazy(() => import('./SearchDetails'));
const BrowseBuyers = lazy(() => import('./BrowseBuyers'));
const BrowseBuyer = lazy(() => import('./BrowseBuyer'));
const NewProperty = lazy(() => import('./NewProperty'));
const EditProperty = lazy(() => import('./EditProperty'));
const PreviewProperty = lazy(() => import('./PreviewProperty'));
const SellerProperties = lazy(() => import('./SellerProperties'));
const PropertyDetails = lazy(() => import('./PropertyDetails'));
const FavoriteSearches = lazy(() => import('./FavoriteSearches'));
const SellerConnections = lazy(() => import('./SellerConnections'));
const SellerConnectionDetails = lazy(() => import('./SellerConnectionDetails'));
const Searches = lazy(() => import('./Searches'));
const AgentDashboard = lazy(() => import('./AgentDashboard'));
const AgentConnections = lazy(() => import('./AgentConnections'));
const AgentConnectionDetails = lazy(() => import('./AgentConnectionDetails'));
const AgentSearches = lazy(() => import('./AgentSearches'));
const AgentClientsMatches = lazy(() => import('./AgentClientsMatches'));
const AgentBuyers = lazy(() => import('./AgentBuyers'));
const AgentSellers = lazy(() => import('./AgentSellers'));

const AgentSearchDetails = lazy(() => import('./AgentSearchDetails'));
const AgentNewSearch = lazy(() => import('./AgentNewSearch'));
const AgentEditSearch = lazy(() => import('./AgentEditSearch'));
const AgentPropertyDetails = lazy(() => import('./AgentPropertyDetails'));
const AgentNewProperty = lazy(() => import('./AgentNewProperty'));
const AgentEditProperty = lazy(() => import('./AgentEditProperty'));
const AgentPreviewProperty = lazy(() => import('./AgentPreviewProperty'));
const AgentClientsSellerProfile = lazy(() => import('./AgentClientsSellerProfile'));
const AgentClientsBuyerProfile = lazy(() => import('./AgentClientsBuyerProfile'));
const ClientsImport = lazy(() => import('./ClientsImport'));
const AgentProfile = lazy(() => import('./AgentProfile'));
const AgentEditProfile = lazy(() => import('./AgentEditProfile'));
const AgentEditBuyerProfile = lazy(() => import('./AgentEditBuyerProfile'));
const AgentEditSellerProfile = lazy(() => import('./AgentEditSellerProfile'));
const PropertiesImport = lazy(() => import('./PropertiesImport'));
const AgentSearchMatches = lazy(() => import('./AgentSearchMatches'));
const AgentPropertyMatches = lazy(() => import('./AgentPropertyMatches'));
const AgentMatchDetails = lazy(() => import('./AgentMatchDetails'));
const SellerSearchMatches = lazy(() => import('./SellerSearchMatches'));
const SellerPropertyMatches = lazy(() => import('./SellerPropertyMatches'));
const SellerMatchDetails = lazy(() => import('./SellerMatchDetails'));
const AgentConnectionPropertyDetails = lazy(() => import('./AgentConnectionPropertyDetails'));
const AgentConnectionSearchDetails = lazy(() => import('./AgentConnectionSearchDetails'));

const Home = lazy(() => import('./Home'));
const InactiveUserPage = lazy(() => import('./InactiveUserPage'));
const UnconfirmedUserPage = lazy(() => import('./UnconfirmedUserPage'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const Faq = lazy(() => import('./Faq'));
const HowItWorksAgent = lazy(() => import('./HowItWorksAgent'));
const HowItWorksSeller = lazy(() => import('./HowItWorksSeller'));

export {
  Home,
  BuyerConnections,
  ConnectionDetails,
  BuyerConnectionPropertyDetails,
  Page404,
  BuyerSearches,
  SignUp,
  NewSearch,
  EditSearch,
  SearchDetails,
  BrowseBuyers,
  BrowseBuyer,
  NewProperty,
  EditProperty,
  PreviewProperty,
  BuyerProfile,
  BuyerEditProfile,
  SellerProfile,
  SellerEditProfile,
  SellerProperties,
  PropertyDetails,
  FavoriteSearches,
  SellerConnections,
  SellerConnectionDetails,
  SellerSearchMatches,
  SellerPropertyMatches,
  SellerMatchDetails,
  Searches,
  AgentDashboard,
  AgentConnections,
  AgentConnectionDetails,
  AgentSearches,
  AgentClientsMatches,
  AgentBuyers,
  AgentSellers,
  AgentSearchDetails,
  AgentNewSearch,
  AgentEditSearch,
  AgentPropertyDetails,
  AgentNewProperty,
  AgentEditProperty,
  AgentPreviewProperty,
  AgentClientsSellerProfile,
  AgentClientsBuyerProfile,
  ClientsImport,
  AgentProfile,
  AgentEditProfile,
  AgentEditBuyerProfile,
  AgentEditSellerProfile,
  PropertiesImport,
  InactiveUserPage,
  UnconfirmedUserPage,
  AgentSearchMatches,
  AgentPropertyMatches,
  AgentMatchDetails,
  AgentConnectionPropertyDetails,
  AgentConnectionSearchDetails,
  TermsAndConditions,
  PrivacyPolicy,
  Faq,
  HowItWorksAgent,
  HowItWorksSeller,
};
