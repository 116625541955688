import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiTableContainerType = {
  defaultProps: ComponentsProps['MuiTableContainer'];
  styleOverrides: ComponentsOverrides['MuiTableContainer'];
};

const MuiTableContainer: MuiTableContainerType = {
  styleOverrides: {},
  defaultProps: {
    sx: {
      border: '1px solid',
      borderColor: 'paleGrey',
      '& tr': {
        cursor: 'pointer',
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      },
    },
  },
};

export default MuiTableContainer;
