import { pathOr, find, keys, isNil, findIndex, includes, without } from 'ramda';
import { isBlank } from 'utils';
import { plural } from 'utils/string';
import { PROPERTY_TYPES, FINANCING_TYPES, PROPERTY_CONDITIONS, TIME_TO_CLOSE } from 'constants/common';
import { SearchPhase } from 'enums/SearchPhase';
import { Status } from 'enums/Status';
import { YesNo } from 'enums/YesNo';
import Search from 'types/resources/search';
import { withCurrency } from 'presenters/NumberPresenter';
import { FinancingType } from 'enums/FinancingType';

export const NO_APPROVED_BUDGET_MESSAGE = 'You cannot Publish/Reject this Search until the budget is approved.';

export const SEARCH_PHASES = {
  [SearchPhase.draft]: {
    label: 'Draft',
    color: 'paleGrey',
  },
  [SearchPhase.published]: {
    label: 'Published',
    color: 'brightGreen',
  },
  [SearchPhase.closed]: {
    label: 'Closed',
    color: 'paleGrey',
  },
  [SearchPhase.rejected]: {
    label: 'Rejected',
    color: 'red',
  },
  [SearchPhase.awaitingForPublish]: {
    label: 'Awaiting For Publish',
    color: 'orange',
  },
};

export const SEARCH_PHASES_OPTIONS = keys(SEARCH_PHASES).map((phase) => {
  return {
    id: phase,
    name: SEARCH_PHASES[phase].label,
  };
});

const INTERVALS = [
  75000, 150000, 250000, 350000, 450000, 550000, 650000, 750000, 850000, 950000, 1000000, 1300000, 1600000, 1900000,
  2000000,
];

export const PRICES = INTERVALS.map((item, key) => {
  const formattedPriceMin = withCurrency(item);

  const priceMax = INTERVALS[key + 1];
  const formattedPriceMax = withCurrency(priceMax);

  const name = isNil(priceMax) ? `more than ${formattedPriceMin}` : `${formattedPriceMin} - ${formattedPriceMax}`;

  return {
    id: item,
    name,
  };
});

export const getSearchMaxPrice = (priceMin: number): number | null => {
  const priceMinIndex = findIndex((item) => item.id.toString() === priceMin.toString(), PRICES);
  const priceMaxIndex = priceMinIndex === -1 ? null : priceMinIndex + 1;
  const priceMaxValue = PRICES[priceMaxIndex];
  const priceMax = priceMaxValue ? priceMaxValue.id : null;

  return priceMax;
};

export const BUDGET_SORTING = 'price_max';

export const CREATED_AT_SORTING = 'created_at';

export const SORTING_OPTIONS = [
  { id: 1, name: 'Most recent', value: CREATED_AT_SORTING },
  { id: 2, name: 'Budget', value: BUDGET_SORTING },
];

export const isDraft = (search: Search): boolean => {
  return search.phase === SearchPhase.draft;
};

export const isPublished = (search: Search): boolean => {
  return search.phase === SearchPhase.published;
};

export const isClosed = (search: Search): boolean => {
  return search.phase === SearchPhase.closed;
};

export const isRejected = (search: Search): boolean => {
  return search.phase === SearchPhase.rejected;
};

export const isAwaitingForPublish = (search: Search): boolean => {
  return search.phase === SearchPhase.awaitingForPublish;
};

export const isArchiveShowing = (search: Search): boolean => {
  return isDraft(search) || isClosed(search);
};

export const propertyToBeSoldText = (search: Search): string => {
  const { isPropertyToBeSold } = search;

  return isPropertyToBeSold ? YesNo.yes : YesNo.no;
};

export const propertyUnderContractText = (search: Search): string => {
  const { isPropertyUnderContract } = search;

  return isPropertyUnderContract ? YesNo.yes : YesNo.no;
};

export const propertyTypesLabels = (search: Search): string[] => {
  const { propertyTypes } = search;

  const labels = propertyTypes.map((propertyType: string) => {
    const currentType = find((item) => item.id === propertyType, PROPERTY_TYPES);

    return pathOr('–', ['name'], currentType);
  });

  return labels;
};

export const financingTypeLabels = (search: Search): string[] => {
  const { financingTypes } = search;

  const labels = financingTypes.map((financingType: string) => {
    const currentType = find((item) => item.id === financingType, FINANCING_TYPES);

    return pathOr('–', ['name'], currentType);
  });

  return labels;
};

export const propertyConditionsLabels = (search: Search): string[] => {
  const { propertyConditions } = search;

  const labels = propertyConditions.map((propertyType: string) => {
    const currentType = find((item) => item.id === propertyType, PROPERTY_CONDITIONS);

    return pathOr('–', ['name'], currentType);
  });

  return labels;
};

export const isArchived = (search: Search): boolean => {
  return search.status === Status.archived;
};

export const isInactive = (search: Search): boolean => {
  return !isPublished(search) || isArchived(search);
};

export const isUnpublishShowing = (search: Search): boolean => {
  return isPublished(search) || isAwaitingForPublish(search);
};

export const requestsCountMessage = (search: Search): string => {
  const { todayConnectionsCount } = search;
  const article = todayConnectionsCount > 1 ? '' : 'a';

  if (todayConnectionsCount > 0) {
    return `${todayConnectionsCount} ${plural('Homeowner', todayConnectionsCount)} ${plural(
      'have',
      todayConnectionsCount,
    )} sent ${article} ${plural('request', todayConnectionsCount)} to Establish ${article} ${plural(
      'Connection',
      todayConnectionsCount,
    )} with this Buyer`;
  }

  return null;
};

export const hasConnections = (search: Search): boolean => {
  const { connectionsCount } = search;
  return !isBlank(connectionsCount);
};

export const priceLabel = (search: Search): string => {
  const { priceMin, priceMax } = search;
  const formattedPriceMin = withCurrency(priceMin);

  return isNil(priceMax) ? `more than ${formattedPriceMin}` : `${formattedPriceMin} - ${withCurrency(priceMax)}`;
};

export const cashItem = (search: Search): string | null => {
  const { financingTypes } = search;

  const cashTypeObject = find((item) => item.id === FinancingType.cash, FINANCING_TYPES);
  const cashIncluded = includes(FinancingType.cash, financingTypes);

  return cashIncluded ? pathOr('–', ['name'], cashTypeObject) : null;
};

export const loanItems = (search: Search): string[] | null => {
  const { financingTypes } = search;

  const financingTypesWithoutCash = without([FinancingType.cash], financingTypes);

  const loanLabels = financingTypesWithoutCash.map((financingType: string) => {
    const loanTypes = find((item) => item.id === financingType, FINANCING_TYPES);

    return pathOr('-', ['name'], loanTypes);
  });

  return loanLabels;
};

export const AGENT_PRICE_LABEL = 'Budget';
export const DEFAULT_PRICE_LABEL = 'Price';

export const timeToCloseText = (search: Search): string => {
  const { timeToClose } = search;

  const currentType = find((item) => item.id === timeToClose, TIME_TO_CLOSE);
  const label = pathOr('–', ['name'], currentType);

  return label;
};
