import { FC, ReactElement, ReactNode } from 'react';
import Box from 'components/Box';

import Header from './components/Header';
import Footer from './components/Footer';
import styles from './styles';

type CommonLayoutProps = {
  children: ReactNode;
};

const CommonLayout: FC<CommonLayoutProps> = (props): ReactElement => {
  const { children } = props;

  return (
    <Box sx={styles.root}>
      <Header />
      <Box sx={styles.content}>{children}</Box>
      <Footer />
    </Box>
  );
};

export default CommonLayout;
