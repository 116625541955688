import { AxiosResponse } from 'axios';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

export default {
  removeFile(buyerId: ID, fileId: ID): Promise<AxiosResponse> {
    const url = apiRoutes.removeFileApiWebV1UserAgentBuyerBudgetPath(buyerId, { buyer_id: buyerId, id: fileId });
    return Fetcher.delete(url);
  },
};
