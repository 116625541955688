import { snakeCase } from 'snake-case';
import { camelCase } from 'camel-case';
import pluralize from 'pluralize';

pluralize.addPluralRule(/a(n)?/i, '');
pluralize.addPluralRule(/search$/i, 'searches');

export const toSnakeCase = (string: string): string => snakeCase(string);
export const toCamelCase = (string: string): string => camelCase(string);

export const plural = (string: string, count: number): string => pluralize(string, count);

export const truncate = (string: string, threshold: number): string => {
  if (string.length > threshold) {
    const trimmable =
      '\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF';
    const reg = new RegExp(`(?=[${trimmable}])`);
    const words = string.split(reg);
    let count = 0;

    const result = words
      .filter((word) => {
        count += word.length;
        return count <= threshold;
      })
      .join('');

    return `${result}…`;
  }

  return string;
};
