import ConnectionsRepository from 'repositories/Admin/ConnectionsRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import Connection from 'types/resources/connection';
import { Meta } from 'types/meta';
import { createSlice } from '@reduxjs/toolkit';
import { ConnectionFiltersParams } from 'forms/connectionFiltersForm';
import { CloseConnectionFormData } from 'forms/closeConnectionForm';

export type AdminConnectionsSliceStateType = {
  connections: {
    data: Connection[];
    meta: Meta | null;
    fetchStatus: FetchStatus;
  };
  connection: {
    data: Connection;
    fetchStatus: FetchStatus;
  };
  assignedAgent: { fetchStatus: FetchStatus };
  unassignedAgent: { fetchStatus: FetchStatus };
};

export type AdminConnectionsSliceActionsType = {
  loadConnections: (params?: ConnectionFiltersParams) => void;
  loadConnection: (id: ID) => void;
  assignAgent: (connectionId: ID, agentId: ID) => void;
  unassignAgent: (connectionId: ID) => void;
  resetConnections: () => void;
  closeConnection: (connectionId: ID, params: CloseConnectionFormData) => void;
};

const initialState: AdminConnectionsSliceStateType = {
  connections: {
    data: [],
    meta: null,
    fetchStatus: FetchStatus.idle,
  },
  connection: {
    data: null,
    fetchStatus: FetchStatus.idle,
  },
  assignedAgent: {
    fetchStatus: FetchStatus.idle,
  },
  unassignedAgent: {
    fetchStatus: FetchStatus.idle,
  },
};

const AdminConnectionsSlice = createSlice({
  name: 'adminConnections',
  initialState,
  reducers: {
    loadConnectionsStart: (state): void => {
      state.connections.fetchStatus = FetchStatus.pending;
    },
    loadConnectionsFinish: (state, { payload }): void => {
      const { connections, meta } = payload;

      state.connections.fetchStatus = FetchStatus.fulfilled;
      state.connections.data = connections;
      state.connections.meta = meta;
    },
    loadConnectionsFail: (state): void => {
      state.connections.fetchStatus = FetchStatus.failed;
    },
    loadConnectionStart: (state): void => {
      state.connection.fetchStatus = FetchStatus.pending;
    },
    loadConnectionFinish: (state, { payload }): void => {
      const { connection } = payload;

      state.connection.fetchStatus = FetchStatus.fulfilled;
      state.connection.data = connection;
    },
    loadConnectionFail: (state): void => {
      state.connection.fetchStatus = FetchStatus.failed;
    },
    assignAgentStart: (state): void => {
      state.assignedAgent.fetchStatus = FetchStatus.pending;
    },
    assignAgentFinish: (state): void => {
      state.assignedAgent.fetchStatus = FetchStatus.fulfilled;
    },
    assignAgentFail: (state): void => {
      state.assignedAgent.fetchStatus = FetchStatus.failed;
    },
    unassignAgentStart: (state): void => {
      state.unassignedAgent.fetchStatus = FetchStatus.pending;
    },
    unassignAgentFinish: (state): void => {
      state.unassignedAgent.fetchStatus = FetchStatus.fulfilled;
    },
    unassignAgentFail: (state): void => {
      state.unassignedAgent.fetchStatus = FetchStatus.failed;
    },
    resetConnections: (): AdminConnectionsSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = AdminConnectionsSlice;

export const useAdminConnectionsActions = (): AdminConnectionsSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadConnections = async (params?: ConnectionFiltersParams) => {
    dispatch(actions.loadConnectionsStart());

    try {
      const connections = await ConnectionsRepository.index(params);
      dispatch(actions.loadConnectionsFinish(connections));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionsFail());
    }
  };

  const loadConnection = async (id: ID) => {
    dispatch(actions.loadConnectionStart());

    try {
      const connection = await ConnectionsRepository.show(id);
      dispatch(actions.loadConnectionFinish(connection));
    } catch (error: unknown) {
      dispatch(actions.loadConnectionFail());
    }
  };

  const assignAgent = async (connectionId: ID, agentId: ID) => {
    dispatch(actions.assignAgentStart());

    try {
      const params = { connection: { agentId } };
      await ConnectionsRepository.assignAgent(connectionId, params);
      dispatch(actions.assignAgentFinish());
    } catch (error: unknown) {
      dispatch(actions.assignAgentFail());
    }
  };

  const unassignAgent = async (connectionId: ID) => {
    dispatch(actions.assignAgentStart());

    try {
      await ConnectionsRepository.unassignAgent(connectionId);
      dispatch(actions.assignAgentFinish());
    } catch (error: unknown) {
      dispatch(actions.assignAgentFail());
    }
  };

  const resetConnections = () => dispatch(actions.resetConnections());

  const closeConnection = async (id: ID, params: CloseConnectionFormData) => ConnectionsRepository.close(id, params);

  return {
    loadConnections,
    loadConnection,
    assignAgent,
    unassignAgent,
    resetConnections,
    closeConnection,
  };
};

export default AdminConnectionsSlice.reducer;
