import { FC } from 'react';
import { SvgIcon, Theme } from '@material-ui/core';
import { SxProps } from '@material-ui/system';

type GoWebsiteProps = {
  sx?: SxProps<Theme>;
};

const GoWebsite: FC<GoWebsiteProps> = (props): JSX.Element => {
  return (
    <SvgIcon {...props} width="351" height="351" viewBox="0 0 351 351" fill="none">
      <path
        d="M350.67 70.7597C350.47 29.9197 320.88 0.24002 280.04 0.14002C210.61 -0.0199805 141.18 -0.0199805 71.75 0.14002C30.91 0.24002 1.21999 29.9199 1.11999 70.7499C0.949994 140.18 0.959994 209.61 1.11999 279.04C1.20999 319.88 30.46 350.12 71.24 350.42C140.99 350.92 210.75 350.92 280.5 350.42C321.24 350.13 350.58 319.7 350.69 279.06C350.78 244.35 350.71 209.63 350.71 174.92C350.71 140.19 350.84 105.48 350.67 70.7597ZM181.58 325.8C97.7 325.8 29.7 257.8 29.7 173.92C29.7 90.0398 97.7 22.0399 181.58 22.0399C265.46 22.0399 333.46 90.0398 333.46 173.92C333.46 257.8 265.46 325.8 181.58 325.8Z"
        fill="currentColor"
      />
      <path
        d="M211.79 168.23C211.79 189.21 229.25 206.66 250.22 206.66C271.2 206.66 288.3 189.2 288.3 168.23C288.3 147.25 271.2 129.62 250.22 129.62C229.25 129.62 211.79 147.25 211.79 168.23Z"
        fill="white"
      />
      <path
        d="M181.58 22.04C97.7 22.04 29.7 90.0399 29.7 173.92C29.7 257.8 97.7 325.8 181.58 325.8C265.46 325.8 333.46 257.8 333.46 173.92C333.46 90.0399 265.46 22.04 181.58 22.04ZM51.39 169.55C50.63 133.61 80.19 103.34 116.14 103.3C138.82 103.27 158.89 115.07 170.62 132.53C171.96 134.52 171.22 137.24 169.08 138.33C162.32 141.77 159.94 143.03 151.97 147.02C150.21 147.9 148.05 147.4 146.91 145.79C139.15 134.8 125.76 128.53 110.55 130.57C94.96 132.67 82.23 144.42 79.11 159.83C74.04 184.89 92.45 206.11 116.22 206.11C130.28 206.11 144.34 199.08 149.44 187.13H146.63C141.01 187.13 128.18 187.3 121.15 187.3C117.81 187.3 116.23 187.3 116.23 187.13V167.96C116.23 165.74 118.03 163.94 120.25 163.94C136.42 163.96 157.27 164.09 176.24 164.11C178.54 164.11 180.36 166.03 180.25 168.32C179.87 176.39 178.39 192.67 173.17 199.44C162.45 219.65 140.66 233.18 116.23 233.18C81.02 233.17 52.14 204.76 51.39 169.55ZM315.45 168.23C315.45 204.19 286.01 233.28 250.22 233.28C214.26 233.28 185.17 204.19 185.17 168.23C185.17 132.09 214.26 103 250.22 103C286.01 103 315.45 132.09 315.45 168.23Z"
        fill="white"
      />
      <path
        d="M173.16 199.43C178.38 192.66 179.86 176.38 180.24 168.31C180.35 166.02 178.52 164.1 176.23 164.1C157.26 164.08 136.41 163.95 120.24 163.93C118.02 163.93 116.22 165.73 116.22 167.95V187.12C116.22 187.29 117.8 187.29 121.14 187.29C128.17 187.29 141 187.12 146.62 187.12H149.43C144.33 199.07 130.27 206.1 116.21 206.1C92.44 206.1 74.03 184.88 79.1 159.82C82.22 144.41 94.95 132.66 110.54 130.56C125.75 128.52 139.14 134.79 146.9 145.78C148.04 147.39 150.2 147.89 151.96 147.01C159.92 143.02 162.31 141.76 169.07 138.32C171.21 137.23 171.94 134.51 170.61 132.52C158.88 115.06 138.81 103.26 116.13 103.29C80.18 103.34 50.62 133.6 51.38 169.54C52.13 204.75 81 233.16 116.21 233.16C140.65 233.17 162.44 219.64 173.16 199.43Z"
        fill="currentColor"
      />
      <path
        opacity="0.95"
        d="M173.16 199.43C178.38 192.66 179.86 176.38 180.24 168.31C180.35 166.02 178.52 164.1 176.23 164.1C157.26 164.08 136.41 163.95 120.24 163.93C118.02 163.93 116.22 165.73 116.22 167.95V187.12C116.22 187.29 117.8 187.29 121.14 187.29C128.17 187.29 141 187.12 146.62 187.12H149.43C144.33 199.07 130.27 206.1 116.21 206.1C92.44 206.1 74.03 184.88 79.1 159.82C82.22 144.41 94.95 132.66 110.54 130.56C125.75 128.52 139.14 134.79 146.9 145.78C148.04 147.39 150.2 147.89 151.96 147.01C159.92 143.02 162.31 141.76 169.07 138.32C171.21 137.23 171.94 134.51 170.61 132.52C158.88 115.06 138.81 103.26 116.13 103.29C80.18 103.34 50.62 133.6 51.38 169.54C52.13 204.75 81 233.16 116.21 233.16C140.65 233.17 162.44 219.64 173.16 199.43Z"
        fill="currentColor"
      />
      <path
        d="M185.17 168.23C185.17 204.19 214.26 233.28 250.22 233.28C286.01 233.28 315.45 204.19 315.45 168.23C315.45 132.09 286.01 103 250.22 103C214.26 103 185.17 132.09 185.17 168.23ZM288.3 168.23C288.3 189.21 271.2 206.66 250.22 206.66C229.24 206.66 211.79 189.2 211.79 168.23C211.79 147.25 229.25 129.62 250.22 129.62C271.2 129.62 288.3 147.25 288.3 168.23Z"
        fill="currentColor"
      />
      <path
        d="M182.71 59.6499C210.43 59.6499 236.6 69.97 256.79 88.79C258.24 90.14 260.49 90.0899 261.88 88.6899C263.35 87.2099 263.29 84.8099 261.77 83.3999C240.21 63.3199 212.29 52.3101 182.71 52.3101C153.13 52.3101 125.21 63.3199 103.65 83.3999C102.13 84.8199 102.07 87.2199 103.54 88.6899C104.93 90.0899 107.19 90.14 108.63 88.79C128.83 69.97 154.99 59.6499 182.71 59.6499Z"
        fill="currentColor"
      />
      <path
        d="M182.71 277.17C154.86 277.17 128.6 266.77 108.38 247.8C106.94 246.45 104.68 246.49 103.29 247.89C101.82 249.36 101.87 251.76 103.38 253.18C124.97 273.41 152.99 284.51 182.71 284.51C212.44 284.51 240.46 273.41 262.04 253.18C263.56 251.76 263.6 249.36 262.13 247.89C260.73 246.49 258.48 246.45 257.04 247.8C236.82 266.76 210.57 277.17 182.71 277.17Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default GoWebsite;
