import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import User from 'types/resources/user';
import { AdminUsersFiltersParams } from 'forms/adminUsersFiltersForm';

export default {
  index(params: AdminUsersFiltersParams): Promise<{ users: User[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1AdminUsersPath();

    return Fetcher.get(url, params);
  },
};
