import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiContainerType = {
  defaultProps: ComponentsProps['MuiContainer'];
  styleOverrides: ComponentsOverrides['MuiContainer'];
};

const MuiContainer: MuiContainerType = {
  styleOverrides: {
    root: {
      maxWidth: 1324,
    },
  },
  defaultProps: {
    maxWidth: false,
  },
};

export default MuiContainer;
