import { withComma, withCurrency } from 'presenters/NumberPresenter';
import { ConnectionsTileType } from 'enums/ConnectionsTileType';

export type AgentConnectionsDashboardType = {
  key: string;
  title: string;
  value?: (value: number) => string;
  countNowadays?: {
    key: string;
    value: (value: number) => string;
  };
  countPast?: {
    key: string;
    value: (value: number) => string;
  };
  priceMin?: {
    key: string;
    value: (value: number) => string;
  };
  priceMax?: {
    key: string;
    value: (value: number) => string;
  };
};

export const DASHBOARD_KEYS: AgentConnectionsDashboardType[] = [
  {
    key: ConnectionsTileType.totalSalesByMonth,
    title: 'Total Sales',
    countNowadays: {
      key: 'thisMonth',
      value: (value: number): string => withComma(value),
    },
    countPast: {
      key: 'lastMonth',
      value: (value: number): string => withComma(value),
    },
  },
  {
    key: ConnectionsTileType.salesPriceRange,
    title: 'Price Range',
    priceMin: {
      key: 'priceMin',
      value: (value: number): string => withComma(value),
    },
    priceMax: {
      key: 'priceMax',
      value: (value: number): string => withComma(value),
    },
  },
  {
    key: ConnectionsTileType.salesPriceRange,
    title: 'Most expensive Deal',
    priceMax: {
      key: 'priceMax',
      value: (value: number): string => withCurrency(value),
    },
  },
  {
    key: ConnectionsTileType.connectionsAverageTimerange,
    title: 'Average Timerange',
    value: (value: number): string => withComma(value),
  },
  {
    key: ConnectionsTileType.connectionRatio,
    title: 'Connection Created / Connection closed',
    countNowadays: {
      key: 'allConnectionsCount',
      value: (value: number): string => withComma(value),
    },
    countPast: {
      key: 'closedConnectionsCount',
      value: (value: number): string => withComma(value),
    },
  },
];
