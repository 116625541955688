import { FetchStatus } from 'types/utils';
import { FetchStatus as FetchStatusEnum } from 'enums/FetchStatus';

const getFetchStatus = (status: FetchStatusEnum): FetchStatus => {
  const isIdle = status === FetchStatusEnum.idle;
  const isPending = status === FetchStatusEnum.pending;
  const isFulfilled = status === FetchStatusEnum.fulfilled;
  const isFailed = status === FetchStatusEnum.failed;

  return {
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isFinished: isFulfilled || isFailed,
  };
};

export default getFetchStatus;
