import Fetcher from 'utils/fetcher';
import { apiRoutes } from 'routes';
import User from 'types/resources/user';

const ProfileRepository = {
  show(id: ID): Promise<{ user: User }> {
    const url = apiRoutes.apiWebV1AdminUserAgentPath(id);

    return Fetcher.get(url);
  },
};

export default ProfileRepository;
