import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { Meta } from 'types/meta';
import State from 'types/resources/state';

export default {
  index(params: QueryParams): Promise<{ states: State[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1StatesPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<State> {
    const url = apiRoutes.apiWebV1StatePath(id);

    return Fetcher.get(url);
  },
};
