import { FC, FormEvent, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import { TextField, InputLabel, Typography, Link, Alert } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import appRoutes from 'routes/appRoutes';
import { extractResponseErrors, isAxiosError, isUnprocessedEntityError } from 'utils/responseErrors';
import {
  RecoveryPasswordFormData,
  initialValues,
  validationSchema,
  attributesToSubmit,
} from 'forms/recoveryPasswordForm';
import AccountsRepository from 'repositories/AccountsRepository';
import Box from 'components/Box';

import styles from './styles';

const RecoveryPassword: FC = (): JSX.Element => {
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);

  const handleSubmit = async (
    formData: RecoveryPasswordFormData,
    { setSubmitting }: FormikHelpers<RecoveryPasswordFormData>,
  ) => {
    const params = attributesToSubmit(formData);
    setFormError(null);
    try {
      await AccountsRepository.passwordReset(params);
      setFormSuccess(true);
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(errors);
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { values, errors, submitForm, touched, handleChange, isSubmitting } = useFormik<RecoveryPasswordFormData>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitForm();
  };

  return (
    <Box sx={styles.root}>
      <Typography sx={styles.title} variant="h3" align="center">
        Password Recovery
      </Typography>
      <Typography sx={styles.description} variant="body1" align="center">
        We&apos;ll send a recovery link to your email.
      </Typography>
      {formError && (
        <Alert sx={styles.alert} severity="error">
          <Typography variant="subtitle2" component="p">
            {formError}
          </Typography>
        </Alert>
      )}
      {formSuccess && (
        <Alert sx={styles.alert} severity="success">
          <Typography variant="subtitle2" component="p">
            Please, check your email and follow the link to reset your password
          </Typography>
        </Alert>
      )}
      <form onSubmit={handleFormSubmit}>
        <InputLabel>Email Address</InputLabel>
        <TextField
          sx={styles.input}
          name="email"
          fullWidth
          value={values.email}
          onChange={handleChange}
          error={touched.email && !!errors.email}
          helperText={touched.email && errors.email}
          autoComplete="email"
        />
        <LoadingButton
          sx={styles.button}
          type="submit"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
          loading={isSubmitting}
        >
          Send Recovery Link
        </LoadingButton>
      </form>
      <Typography align="center" variant="subtitle2" component="p">
        Return to
        <Link sx={styles.link} color="primary" href={appRoutes.signInPath()}>
          Sign In
        </Link>
      </Typography>
    </Box>
  );
};

export default RecoveryPassword;
