import Fetcher from 'utils/fetcher';
import { apiRoutes } from 'routes';
import { LicenseRejectParams } from 'forms/licenseRejectForm';

const LicenseRepository = {
  approveLicense(agentId: ID, licenseId: ID): Promise<void> {
    const url = apiRoutes.approveApiWebV1AdminUserAgentLicensePath(agentId, licenseId);

    return Fetcher.put(url, {});
  },
  declineLicense(agentId: ID, licenseId: ID, params: LicenseRejectParams): Promise<void> {
    const url = apiRoutes.declineApiWebV1AdminUserAgentLicensePath(agentId, licenseId);

    return Fetcher.put(url, params);
  },
};

export default LicenseRepository;
