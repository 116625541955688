import { createSlice } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/useRedux';
import { FetchStatus } from 'enums/FetchStatus';
import { ClientsImportRecordType, ImportRecordType } from 'types/importRecord';
import { ClientFormDataParams } from 'forms/agentClientForm';
import ClientsRepository from 'repositories/Agent/ClientsRepository';
import { ImportResponseType } from 'types/importResponse';

export type AgentClientsSliceStateType = {
  importRecords: {
    data: ClientsImportRecordType[];
    responseData: ImportResponseType[];
    fetchStatus: FetchStatus;
  };
};

export type AgentClientsSliceActionsType = {
  createClient: (data: ClientFormDataParams) => void;
  importClients: (data: ClientsImportRecordType[]) => Promise<ImportResponseType[]>;
  setImportRecords: (data: ImportRecordType[]) => void;
  resetImportRecords: () => void;
};

const initialState: AgentClientsSliceStateType = {
  importRecords: {
    data: [],
    responseData: [],
    fetchStatus: FetchStatus.idle,
  },
};

const AgentClientsSlice = createSlice({
  name: 'agentClients',
  initialState,
  reducers: {
    setImportData: (state, { payload }): void => {
      state.importRecords.data = payload;
    },
    importClientsStart: (state): void => {
      state.importRecords.fetchStatus = FetchStatus.pending;
    },
    importClientsFinish: (state, { payload }): void => {
      state.importRecords.fetchStatus = FetchStatus.fulfilled;
      state.importRecords.responseData = payload;
    },
    importClientsFail: (state): void => {
      state.importRecords.fetchStatus = FetchStatus.failed;
    },
    resetImportRecords: (state): void => {
      state.importRecords = initialState.importRecords;
    },
  },
});

const { actions } = AgentClientsSlice;

export const useAgentClientsActions = (): AgentClientsSliceActionsType => {
  const dispatch = useAppDispatch();

  const resetImportRecords = () => dispatch(actions.resetImportRecords());

  const setImportRecords = (data: ImportRecordType[]) => dispatch(actions.setImportData(data));

  const createClient = async (data: ClientFormDataParams) => {
    await ClientsRepository.create(data);
  };

  const importClients = async (data: ClientsImportRecordType[]) => {
    dispatch(actions.importClientsStart());

    try {
      const response = await ClientsRepository.import({ clients: { data } });
      dispatch(actions.importClientsFinish(response));

      return response;
    } catch (error: unknown) {
      dispatch(actions.importClientsFail());

      return null;
    }
  };

  return {
    createClient,
    importClients,
    setImportRecords,
    resetImportRecords,
  };
};

export default AgentClientsSlice.reducer;
