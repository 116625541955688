import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiOutlinedInputType = {
  defaultProps: ComponentsProps['MuiOutlinedInput'];
  styleOverrides: ComponentsOverrides['MuiOutlinedInput'];
};

const MuiOutlinedInput: MuiOutlinedInputType = {
  styleOverrides: {
    root: {
      minHeight: 44,
      backgroundColor: '#FFF',
      '&.MuiSelect-root': {
        minHeight: 44,
        '& > .MuiSelect-select': {
          padding: '11px 14px',
        },
      },
      '& input': {
        padding: '11px 14px',
      },
    },
  },
  defaultProps: {},
};

export default MuiOutlinedInput;
