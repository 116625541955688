import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { ClientsImportRecordType } from 'types/importRecord';
import { AgentClientsFiltersParams } from 'forms/agentClientsFiltersForm';
import { ClientFormDataParams } from 'forms/agentClientForm';
import User from 'types/resources/user';
import { Meta } from 'types/meta';
import { ImportResponseType } from 'types/importResponse';

export default {
  index(params: AgentClientsFiltersParams): Promise<{ users: User[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1UserAgentClientsPath();

    return Fetcher.get(url, params);
  },
  create(data: ClientFormDataParams): Promise<void> {
    const url = apiRoutes.apiWebV1UserAgentClientsPath();

    return Fetcher.post(url, data);
  },
  import(data: { clients: { data: ClientsImportRecordType[] } }): Promise<ImportResponseType[]> {
    const url = apiRoutes.importApiWebV1UserAgentClientsPath();

    return Fetcher.post(url, data);
  },
};
