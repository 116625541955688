import CitiesRepository from 'repositories/CitiesRepository';
import { FetchStatus } from 'enums/FetchStatus';
import { useAppDispatch } from 'hooks/useRedux';
import City from 'types/resources/city';
import { createSlice } from '@reduxjs/toolkit';

export type CitiesSliceStateType = {
  city: City | null;
  showCity: { fetchStatus: FetchStatus };
};

export type CitiesSliceActionsType = {
  loadCity: (id: ID) => void;
  resetCity: () => void;
};

const initialState: CitiesSliceStateType = {
  city: null,
  showCity: { fetchStatus: FetchStatus.idle },
};

const CitiesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    loadCityStart: (state): void => {
      state.showCity.fetchStatus = FetchStatus.pending;
    },
    loadCityFinish: (state, { payload }): void => {
      const { city } = payload;

      state.showCity.fetchStatus = FetchStatus.fulfilled;
      state.city = city;
    },
    loadCityFail: (state): void => {
      state.showCity.fetchStatus = FetchStatus.failed;
    },
    resetCity: (): CitiesSliceStateType => {
      return initialState;
    },
  },
});

const { actions } = CitiesSlice;

export const useCitiesActions = (): CitiesSliceActionsType => {
  const dispatch = useAppDispatch();

  const loadCity = async (id: ID) => {
    dispatch(actions.loadCityStart());
    try {
      const city = await CitiesRepository.show(id);
      dispatch(actions.loadCityFinish(city));
    } catch (error: unknown) {
      dispatch(actions.loadCityFail());
    }
  };

  const resetCity = () => dispatch(actions.resetCity());

  return {
    loadCity,
    resetCity,
  };
};

export default CitiesSlice.reducer;
