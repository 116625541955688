import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import { PropertyFormDataToSubmit } from 'forms/propertyForm';
import Property from 'types/resources/property';
import { Meta } from 'types/meta';
import { PropertyFiltersParams } from 'forms/propertyFiltersForm';
import { PropertyEvent } from 'enums/PropertyEvent';
import { ImportResponseType } from 'types/importResponse';
import { PropertiesImportRecordType } from 'types/importRecord';

export default {
  index(params: PropertyFiltersParams): Promise<{ properties: Property[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1UserAgentPropertiesPath();

    return Fetcher.get(url, params);
  },
  show(id: ID): Promise<Property> {
    const url = apiRoutes.apiWebV1UserAgentPropertyPath(id);

    return Fetcher.get(url);
  },
  showMatchesStatus(id: ID): Promise<{ property: Property }> {
    const url = apiRoutes.showMatchesStatusApiWebV1UserAgentPropertyPath(id);

    return Fetcher.get(url);
  },
  update(id: ID, params: PropertyFormDataToSubmit): Promise<{ property: Property }> {
    const url = apiRoutes.apiWebV1UserAgentPropertyPath(id);

    return Fetcher.putFormData(url, params);
  },
  removeFile(id: ID, fileId: ID): Promise<void> {
    const url = apiRoutes.removeFileApiWebV1UserAgentPropertyPath(id, { id, file_id: fileId });
    return Fetcher.delete(url);
  },
  removeImage(id: ID, imageId: ID): Promise<void> {
    const url = apiRoutes.removeImageApiWebV1UserAgentPropertyPath(id, { id, image_id: imageId });
    return Fetcher.delete(url);
  },
  changePhase(id: ID, event: PropertyEvent): Promise<Property> {
    const params = {
      property: {
        event,
      },
    };
    const url = apiRoutes.changePhaseApiWebV1UserAgentPropertyPath(id);

    return Fetcher.put(url, params);
  },
  archive(id: ID): Promise<void> {
    const url = apiRoutes.apiWebV1UserAgentPropertyPath(id);

    return Fetcher.delete(url);
  },
  import(userId: ID, data: { properties: { data: PropertiesImportRecordType[] } }): Promise<ImportResponseType> {
    const url = apiRoutes.importApiWebV1UserAgentSellerPropertiesPath(userId);

    return Fetcher.postFormData(url, data);
  },
};
