import { ComponentsOverrides, ComponentsProps } from '@material-ui/core';

type MuiSvgIconType = {
  defaultProps: ComponentsProps['MuiSvgIcon'];
  styleOverrides: ComponentsOverrides['MuiSvgIcon'];
};

const MuiSvgIcon: MuiSvgIconType = {
  variants: [
    {
      props: { variant: 'image' },
      style: {
        fontSize: 'unset',
        width: 'unset',
        height: 'unset',
      },
    },
  ],
  defaultProps: {},
};

export default MuiSvgIcon;
