import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';
import Search from 'types/resources/search';
import { SearchFiltersFormDataParams } from 'forms/searchFiltersForm';
import { Meta } from 'types/meta';

export default {
  index(params: SearchFiltersFormDataParams): Promise<{ searches: Search[]; meta: Meta }> {
    const url = apiRoutes.apiWebV1UserSellerSearchesPath();

    return Fetcher.get(url, params);
  },
  showMatchesStatus(id: ID): Promise<{ search: Search }> {
    const url = apiRoutes.showMatchesStatusApiWebV1UserSellerSearchPath(id);

    return Fetcher.get(url);
  },
};
