import { FC, useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { appRoutes } from 'routes';
import CommonLayout from 'layouts/CommonLayout';
import useLocalStorage from 'hooks/useLocalStorage';
import {
  Home,
  SignUp,
  Searches,
  BrowseBuyer,
  TermsAndConditions,
  PrivacyPolicy,
  Faq,
  HowItWorksAgent,
  HowItWorksSeller,
} from 'pages/client';

const NonUserSwitch: FC = () => {
  const history = useHistory();
  const [, setPath] = useLocalStorage<string>('pathToRedirect', '');

  useEffect(() => {
    return history.listen((location) => {
      setPath(`${location.pathname}${location.search}`);
    });
  }, [history]);

  return (
    <Switch>
      <Route exact path={appRoutes.rootPath()} component={Home} />
      <Route path={appRoutes.allSearchesPath()}>
        <CommonLayout>
          <Searches />
        </CommonLayout>
      </Route>
      <Route
        exact
        path={appRoutes.browseBuyerPath(':id')}
        render={({ match }) => (
          <CommonLayout>
            <BrowseBuyer key={match.params.id} id={match.params.id} />
          </CommonLayout>
        )}
      />
      <Route
        path={[
          appRoutes.signInPath(),
          appRoutes.recoveryPasswordPath(),
          appRoutes.resetPasswordPath(),
          appRoutes.confirmNewPasswordPath(),
          appRoutes.confirmPath(),
        ]}
        component={Home}
      />

      <Route path={appRoutes.signUpPath()}>
        <CommonLayout>
          <SignUp />
        </CommonLayout>
      </Route>

      <Route path={appRoutes.termsAndConditionsPath()} component={TermsAndConditions} />
      <Route path={appRoutes.privacyPolicyPath()} component={PrivacyPolicy} />
      <Route path={appRoutes.faqPath()} component={Faq} />
      <Route path={appRoutes.howItWorksAgent()}>
        <CommonLayout>
          <HowItWorksAgent />
        </CommonLayout>
      </Route>
      <Route path={appRoutes.howItWorksSeller()}>
        <CommonLayout>
          <HowItWorksSeller />
        </CommonLayout>
      </Route>

      <Redirect to={appRoutes.rootPath()} />
    </Switch>
  );
};

export default NonUserSwitch;
