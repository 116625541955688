import { AxiosResponse } from 'axios';
import { SignInFormDataToSubmit } from 'forms/signInForm';
import { apiRoutes } from 'routes';
import Fetcher from 'utils/fetcher';

export default {
  create(params: SignInFormDataToSubmit): Promise<AxiosResponse> {
    const url = apiRoutes.apiWebV1SessionPath();
    return Fetcher.post(url, params);
  },
  delete(): Promise<AxiosResponse> {
    const url = apiRoutes.apiWebV1SessionPath();
    return Fetcher.delete(url);
  },
};
